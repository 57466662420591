import { RematchDispatch } from '@rematch/core';
import _ from 'lodash';

import {
  Course,
  CourseDetail,
  CourseParam,
  courseSection,
  courseSectionItem,
  DeleteCourseAssessmentQuestionParams,
} from '../@types/course';
import { QueryFilter } from '../@types/global';
import { TEMP_CONTENT_ID } from '../common/constants/application';
import { platformViewTypes } from '../common/constants/entities';
import stateStorage from '../helpers/stateStorage';
import { mapSectionContentType, move, mustBeArray } from '../helpers/util';
import CourseService from '../services/CourseService';

type State = {
  courses: ReadonlyArray<Course>;
  course: CourseDetail;
  sectionItem: courseSectionItem;
  sections: Array<courseSection>;
  total: number;
};

const courseDetailKeyMapper = (course: CourseDetail) => {
  return {
    ...course,
    additionalAttempts: course.additionalAttempts ? course.additionalAttempts : 0,
  };
};

const model = {
  state: {} as State,
  reducers: {
    loadList: (
      state: State,
      payload: { rows: ReadonlyArray<Course>; total: number; page: number },
    ) => {
      const pageView =
        (stateStorage.getValue('CoursePageView') || {}).view || platformViewTypes['Grid'];
      return pageView === platformViewTypes['Table'] || payload.page === 1
        ? {
            ...state,
            courses: payload.rows,
            total: payload.total,
          }
        : {
            ...state,
            courses: [...mustBeArray(state.courses), ...payload.rows],
            total: payload.total,
          };
    },
    delete: (state: State, payload: number) => {
      return { ...state, courses: _.filter(state.courses, (course) => course.id !== payload) };
    },
    detail: (state: State, payload: any) => {
      return { ...state, course: courseDetailKeyMapper(payload) };
    },
    loadSections: (
      state: State,
      payload: { rows: ReadonlyArray<courseSection>; total: number },
    ) => {
      return { ...state, sections: payload.rows };
    },
    removeSection: (state: State, sectionID: number) => {
      return {
        ...state,
        sections: _.filter(state.sections, (section) => section.id !== sectionID),
      };
    },
    setSectionItem: (state: State, payload: any) => {
      return {
        ...state,
        sectionItem: {
          ...state.sectionItem,
          ...payload,
        },
      };
    },
    setEmptySectionItem: (state: State) => {
      return { ...state, sectionItem: { title: '', id: TEMP_CONTENT_ID } };
    },
    resortSectionItems: (
      state: State,
      payload: { sectionID: number; list: Array<courseSectionItem> },
    ) => {
      const newSection = _.cloneDeep(state.sections);
      _.each(newSection, (section) => {
        if (section.id === payload.sectionID) {
          section.sectionItems = payload.list;
        }
      });
      return { ...state, sections: newSection };
    },
    removeSectionItem: (state: State, payload: { sectionID: number; sectionItemID: number }) => {
      const newSection = _.cloneDeep(state.sections);
      _.each(newSection, (section) => {
        if (section.id === payload.sectionID) {
          section.sectionItems = _.filter(
            section.sectionItems,
            (item) => item.id !== payload.sectionItemID,
          );
        }
      });
      return { ...state, sections: newSection };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getCourses(params: QueryFilter) {
      dispatch.ui.start();
      const response = await CourseService.listCourse(params);
      const { result } = response.data;
      dispatch.ui.stop();
      dispatch.course.loadList({ ...result, page: params.page });
    },
    async getCourse({ options }: { options: CourseParam }) {
      dispatch.ui.start();
      const response: any = await CourseService.courseActionHandler({
        action: 'get',
        id: options.id,
      });
      dispatch.ui.stop();
      dispatch.course.detail(response?.data?.result);
      return response?.data?.result;
    },
    async createCourse({ payLoad }: any) {
      return CourseService.courseActionHandler({ action: 'create', payLoad });
    },
    async deleteCourse({ options }: { options: CourseParam }) {
      await CourseService.courseActionHandler({ action: 'delete', id: options.id });
      dispatch.course.delete(options.id);
    },
    async updateCourse({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.courseActionHandler({ action: 'update', id: options.id, payLoad });
    },
    async duplicateCourse({ options }: { options: CourseParam }) {
      return CourseService.courseActionHandler({ action: 'duplicate', id: options.id });
    },
    async archiveCourse({
      options,
      payLoad,
    }: {
      options: CourseParam;
      payLoad: { statusId: number };
    }) {
      return CourseService.courseActionHandler({ action: 'archive', id: options.id, payLoad });
    },
    async uploadCourseFile({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.courseActionHandler({ action: 'upload', options, payLoad });
    },
    async uploadScormCourseFile({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.courseActionHandler({ action: 'uploadScorm', options, payLoad });
    },
    async uploadScormCourseFileNoId({ payLoad }: { payLoad: any }) {
      return CourseService.courseActionHandler({ action: 'uploadScormNoId', payLoad });
    },
    async getScormPreviewLink({ options }: { options: CourseParam }) {
      dispatch.ui.start();
      const response: any = await CourseService.courseActionHandler({
        action: 'getScormPreviewLink',
        id: options.id,
      });
      const result = response?.data;
      dispatch.ui.stop();
      return result;
    },
    async listSections({ options }: { options: CourseParam }) {
      const response: any = await CourseService.sectionActionHandler({ action: 'list', options });
      dispatch.course.loadSections(response.data.result);
    },
    async deleteSection({ options }: { options: CourseParam }) {
      await CourseService.sectionActionHandler({
        id: options?.sectionID,
        action: 'delete',
        options,
      });
      dispatch.course.removeSection(options.sectionID);
    },
    async getLecture({ options }: { options: CourseParam }) {
      const response: any = await CourseService.lectureActionHandler({
        action: 'get',
        options,
        id: options.id,
      });
      dispatch.course.setSectionItem(response.data);
      return response;
    },
    async uploadArticleImage({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.lectureActionHandler({ action: 'upload', options, payLoad });
    },
    async getAssessment({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      const response: any = await CourseService.assessmentActionHandler({
        action: 'get',
        options,
        id: options.id,
      });
      const parsedData = {
        ...response.data,
        passmark: response.data.pass_mark,
      };
      dispatch.course.setSectionItem({ ...payLoad, ...parsedData });
      return response;
    },
    async getAssignmentQuestions({
      options,
      queryOptions,
    }: {
      options: CourseParam;
      queryOptions: QueryFilter;
    }) {
      return CourseService.questionActionHandler({ action: 'get', options, queryOptions });
    },
    async createAssignmentQuestion({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.questionActionHandler({ action: 'post', options, payLoad });
    },
    async updateAssignmentQuestion({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.questionActionHandler({
        action: 'patch',
        options,
        payLoad,
        id: options.questionID,
      });
    },
    async deleteAssessmentQuestion({
      options,
      payLoad,
    }: {
      options: DeleteCourseAssessmentQuestionParams;
      payLoad: any;
    }) {
      return CourseService.questionActionHandler({
        action: 'delete',
        options,
        payLoad,
        id: options.questionID,
      });
    },
    async uploadAssignmentFiles({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.questionActionHandler({ action: 'upload', options, payLoad });
    },
    async saveAssessment({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.assessmentActionHandler({ action: 'post', payLoad, options });
    },
    async updateAssessment({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.assessmentActionHandler({
        action: 'patch',
        payLoad,
        options,
        id: options.sectionItemID,
      }).then(() => {
        dispatch.course.setSectionItem(payLoad);
      });
    },
    async saveLecture({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.lectureActionHandler({ action: 'post', payLoad, options });
    },
    async updateLecture({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.lectureActionHandler({
        action: 'patch',
        payLoad,
        options,
        id: options.sectionItemID,
      });
    },
    async deleteSectionItem({ options }: { options: CourseParam }) {
      if (options.contentType === mapSectionContentType('lecture')) {
        await CourseService.lectureActionHandler({
          id: options.sectionItemID,
          action: 'delete',
          options,
        });
      } else {
        await CourseService.assessmentActionHandler({
          id: options.sectionItemID,
          action: 'delete',
          options,
        });
      }
      dispatch.course.removeSectionItem({
        sectionID: options.sectionID,
        sectionItemID: options.sectionItemID,
      });
    },
    async createSection({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.sectionActionHandler({ action: 'create', payLoad, options });
    },
    async updateSection({ options, payLoad }: { options: CourseParam; payLoad: any }) {
      return CourseService.sectionActionHandler({
        action: 'update',
        payLoad,
        options,
        id: options.sectionID,
      });
    },
    async dragSections({
      options,
      payLoad,
      source,
      destination,
      list,
    }: {
      source: any;
      payLoad: any;
      destination: any;
      options: CourseParam;
      list: any;
    }) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      dispatch.course.loadSections({ rows: cloneList, total: cloneList.length });
      payLoad = {
        ...payLoad,
        sections: _.map(cloneList, (l) => {
          return {
            id: l.id,
            sectionItems: _.map(l.sectionItems, (sl) => sl.id),
          };
        }),
      };
      return CourseService.sectionItemsDrag({ courseID: options.id, payLoad });
    },
    async dragSectionItems({
      options,
      payLoad,
      source,
      destination,
      list,
    }: {
      source: any;
      payLoad: any;
      destination: any;
      options: CourseParam;
      list: any;
    }) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      payLoad = {
        ...payLoad,
        sections: [
          {
            id: options.sectionID,
            sectionItems: _.map(cloneList, (sl) => sl.id),
          },
        ],
      };
      dispatch.course.resortSectionItems({ sectionID: options.sectionID, list: cloneList });
      await CourseService.sectionItemsDrag({ courseID: options.id, payLoad });
    },
    async deleteLectureResource({ options }: { options: CourseParam }) {
      return CourseService.lectureResourcesHandler({
        action: 'delete',
        id: options.resourceID,
        options,
      });
    },
  }),
};

export default model;
