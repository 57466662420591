import { useEffect, useMemo, useState } from 'react';

import { Modal } from 'antd';
import PubSub from 'pubsub-js';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  COURSE_TYPE,
  CourseStatus,
  ENTITY_MODE,
  PUBSUB_COURSE_ACTION,
} from '../../../@types/course';
import HeaderActions from '../../../common/headerActions';
import CourseForm from '../../../components/Course/CorseForm/CourseForm';
import history from '../../../routes/history';
import { RootState } from '../../../store';

const CourseEditAction = () => {
  const { id } = useParams<any>();
  const currentCourse = useSelector((state: RootState) => state.course.course);
  const [publishSettingVisible, setPublishSettingVisible] = useState<boolean>(false);

  const togglePublishSettingVisible = () => setPublishSettingVisible(!publishSettingVisible);

  const subscribeEndUpdateCourseEvent = PubSub.subscribe(PUBSUB_COURSE_ACTION.END_UPDATE, () =>
    setTimeout(togglePublishSettingVisible, 2000),
  );

  useEffect(() => {
    subscribeEndUpdateCourseEvent;
    return () => {
      PubSub.unsubscribe(subscribeEndUpdateCourseEvent);
    };
  }, [subscribeEndUpdateCourseEvent]);

  const isPublishBtnShow = useMemo(
    () => currentCourse?.statusID === CourseStatus.Draft,
    [currentCourse?.statusID],
  );

  const validActions = [
    { name: 'Preview', style: 'primary', visible: true },
    { name: 'Publish', style: 'primary', visible: isPublishBtnShow },
    { name: 'Close', style: 'default', visible: true },
  ];

  const checkIsCourseEditMode = () => {
    const result = document.querySelector('div[data-test-id="course-settings-form--update"]');
    return !!result;
  };

  const handleActionClick = (action: string) => {
    const isCourseEditMode = checkIsCourseEditMode();
    switch (action) {
      case 'Preview':
        history.push(`/course/preview/${id}?origin=detail`);
        break;
      case 'Publish':
        isCourseEditMode
          ? PubSub.publish(PUBSUB_COURSE_ACTION.INIT_UPDATE)
          : togglePublishSettingVisible();
        break;
      case 'Close':
        history.push('/courses');
        break;
      default:
        history.push('/courses');
    }
  };

  if (!currentCourse) return null;

  return (
    <>
      <HeaderActions actions={validActions} handleActionClick={handleActionClick} />
      <Modal
        visible={publishSettingVisible}
        centered
        width={'60vw'}
        footer={null}
        onCancel={togglePublishSettingVisible}
      >
        <CourseForm
          mode={ENTITY_MODE.REVIEW}
          courseType={COURSE_TYPE.BYO}
          title={'Publish the Course'}
          subTitle='Review the details of your course before publishing it'
          onModalCancel={togglePublishSettingVisible}
        />
      </Modal>
    </>
  );
};

export default CourseEditAction;
