import { FC, useEffect, useState } from 'react';

import { Button, Checkbox, Form, Input, Switch } from 'antd';
import _ from 'lodash';

import AccessKeyInput from './accessKeyInput';
import { PartnerClient } from '../../@types/partners';
import { User } from '../../@types/user';
import { defaultPageSize, TEMP_CONTENT_ID } from '../../common/constants/application';
import { getPartnerCompanyId, mustBeArray } from '../../helpers/util';

interface IClientForm {
  getPartnerClient: Function;
  client: PartnerClient;
  current: User;
  createPartnerClient: Function;
  updatePartnerClient: Function;
  listPartnerClients: Function;
  onCancel: Function;
}

const ClientForm: FC<IClientForm> = ({
  client,
  current,
  getPartnerClient,
  onCancel,
  updatePartnerClient,
  createPartnerClient,
  listPartnerClients,
}) => {
  const [activeClient, setActiveClient] = useState<any>({});
  const [features, setFeature] = useState<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (client.id === TEMP_CONTENT_ID) {
      setActiveClient(client);
    } else {
      getPartnerClient({ options: { clientID: client.id } }).then((response: any) => {
        setActiveClient(response.data);
        const featureList: Array<number> = [];
        _.each(response.data.features, (feature) => {
          if (feature.code === 'CRS' && feature.value === 'Y') {
            featureList.push(1);
          } else if (feature.code === 'WRK' && feature.value === 'Y') {
            featureList.push(2);
          }
        });
        form.setFieldsValue({
          features: featureList,
          name: response?.data?.name,
          accessKey: response?.data?.accessKey,
          isActive: response?.data?.isActive,
        });
        setFeature(featureList);
      });
    }
  }, [client.id]);

  const onFinish = (values: any) => {
    const { accessKey, isActive, name, features } = values;
    const payLoad = {
      name: name,
      isActive: isActive ? isActive : false,
      accessKey: accessKey,
      features: [
        {
          code: 'CRS',
          value: mustBeArray(features).includes(1) ? 'Y' : 'N',
        },
        {
          code: 'WRK',
          value: mustBeArray(features).includes(2) ? 'Y' : 'N',
        },
      ],
    };
    setLoading(true);
    if (activeClient.id === TEMP_CONTENT_ID) {
      createPartnerClient({ payLoad }).then(() => {
        setLoading(false);
        listPartnerClients({
          page: 1,
          per_page: defaultPageSize,
          partnerCompanyId: getPartnerCompanyId(current),
        });
        form.resetFields();
        onCancel();
      });
    } else {
      updatePartnerClient({ options: { clientID: activeClient.id }, payLoad }).then(() => {
        setLoading(false);
        form.resetFields();
        onCancel();
      });
    }
  };

  return (
    <div className='flex'>
      <Form
        name='clientForm'
        layout='vertical'
        form={form}
        initialValues={{
          isActive: activeClient.isActive,
          features: features,
          name: activeClient.name,
          accessKey: activeClient.accessKey,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label='Active'
          name='isActive'
          valuePropName='checked'
          rules={[{ required: false }]}
        >
          <Switch />
        </Form.Item>

        <Form.Item label='This client can create' name='features' rules={[{ required: false }]}>
          <Checkbox.Group style={{ width: '100%' }}>
            <Checkbox value={1}>Courses</Checkbox>
            <Checkbox value={2}>Workflow</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label='Company Name'
          name='name'
          rules={[{ required: true, message: 'Company name is required!' }]}
        >
          <Input placeholder='Company name' value={activeClient.name} />
        </Form.Item>

        <Form.Item
          label='Advisory Access Key'
          name='accessKey'
          rules={[{ required: true, message: 'Generate access key' }]}
        >
          <AccessKeyInput placeholder='Access key' value={activeClient.accessKey} />
        </Form.Item>

        <Form.Item>
          <div className='flex justify-between'>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button type='primary' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ClientForm;
