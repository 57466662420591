import React, { useEffect, useState } from 'react';

import { message, Modal } from 'antd';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Error, JSONQuestionModel } from '../../../@types/global';
import { LetterDetail } from '../../../@types/letter';
import { User } from '../../../@types/user';
import ConfirmBox from '../../../common/ConfirmBox';
import Loading from '../../../common/Loading';
import DocumentHeader from '../../../components/Document/Header';
import DocumentHelper from '../../../helpers/DocumentHelper';
import stateStorage from '../../../helpers/stateStorage';
import { getPartnerCompanyId, isNullUndefined, move } from '../../../helpers/util';
import history from '../../../routes/history';
import { RootDispatch, RootState } from '../../../store';

interface IEditPageActions {
  letter: LetterDetail;
  getLetterVersion: Function;
  getLetterVersionPreview: Function;
  uploadLetterDocument: Function;
  updateLetter: Function;
  publishLetter: Function;
  deleteLetter: Function;
  previewWithMergeFields: Function;
  retireLetter: Function;
  draftLetter: Function;
  current: User;
  questions: Array<JSONQuestionModel>;
}

interface ILetterDocumentSettings {
  categoryID?: number;
  countryId?: number;
  description?: string;
  id?: string;
  imageLoading?: boolean;
  referenceNumber?: string | null | undefined;
  releaseNotes?: string | null | undefined;
  title?: string;
  videoLoading?: boolean;
  requireApproval?: boolean;
  requireEmailNotification?: boolean;
  requireEmployeeAcknowledgement?: boolean;
  requireEmployeeSignature?: boolean;
  requireSignature?: boolean;
  requireSignedCopy?: boolean;
  supportWatchers?: boolean;
  canBeDownloaded?: boolean;
}

const EditAction = ({
  letter,
  getLetterVersion,
  getLetterVersionPreview,
  updateLetter,
  uploadLetterDocument,
  publishLetter,
  deleteLetter,
  previewWithMergeFields,
  retireLetter,
  draftLetter,
  current,
}: IEditPageActions) => {
  const [isVersionLetterOpen, setLetterVersionOpen] = useState(false);
  const [documentSettingVisible, setDocumentSettingVisible] = useState(false);
  const [publishSettingVisible, updatePublishSettingVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [retireVisible, setRetireVisible] = useState(false);
  const [saving, updateSaving] = useState(false);
  const [settings, updateSettings] = useState<ILetterDocumentSettings>({});
  const [previewData, updatePreviewData] = useState({});
  const [publishConfig, updatePublishConfig] = useState<Array<any>>([]);

  useEffect(() => {
    if (letter) {
      updateSettings({
        categoryID: letter.categoryID,
        countryId: letter.countryId,
        description: letter.description,
        referenceNumber: letter.referenceNumber,
        requireEmployeeAcknowledgement: letter.requireEmployeeAcknowledgement,
        requireEmployeeSignature: letter.requireEmployeeSignature,
        releaseNotes: letter.releaseNotes,
        requireApproval: letter.requireApproval,
        requireEmailNotification: letter.requireEmailNotification,
        requireSignature: letter.requireSignature,
        requireSignedCopy: letter.requireSignedCopy,
        supportWatchers: letter.supportWatchers,
        canBeDownloaded: letter.canBeDownloaded,
        title: letter.title,
      });
      updatePublishConfig([
        {
          label: 'Letter title',
          type: 'textbox',
          placeholder: 'Letter title',
          confirmEdit: letter.statusId === 4,
          key: 'title',
        },
        {
          label: 'Letter category',
          type: 'letterCategoryID',
          data: 'contentTypes',
          disabled: letter.statusId !== 1,
          placeholder: 'Select category',
          key: 'categoryID',
        },
        {
          label: 'Country',
          placeholder: 'Select country',
          disabled: letter.statusId !== 1,
          type: 'countryDD',
          key: 'countryId',
        },
        {
          label: 'Letter reference',
          type: 'textbox',
          placeholder: 'Reference No',
          key: 'referenceNumber',
        },
        {
          label: 'Write a description or purpose',
          type: 'textarea',
          placeholder: 'description',
          display: true,
          key: 'description',
        },
        {
          label: 'Require approval',
          type: 'switch',
          placeholder: '',
          display: true,
          key: 'requireApproval',
        },
        {
          label: 'Can be downloaded',
          type: 'switch',
          placeholder: '',
          display: true,
          key: 'canBeDownloaded',
        },
        {
          label: 'Require signature',
          type: 'switch',
          placeholder: '',
          display: false,
          key: 'requireSignature',
        },
        {
          label: 'Require employee acknowledgement',
          type: 'switch',
          placeholder: '',
          display: true,
          key: 'requireEmployeeAcknowledgement',
        },
        {
          label: 'Require signed copy',
          type: 'switch',
          placeholder: '',
          display: true,
          key: 'requireSignedCopy',
        },
        {
          label: 'Require employee signature',
          type: 'switch',
          placeholder: '',
          display: false,
          key: 'requireEmployeeSignature',
        },
        {
          label: 'Require email notification',
          type: 'switch',
          placeholder: '',
          display: true,
          key: 'requireEmailNotification',
        },
        {
          label: 'Support watchers',
          type: 'switch',
          placeholder: '',
          display: true,
          key: 'supportWatchers',
        },
        {
          label: 'Version Release Notes',
          type: 'textarea',
          placeholder: 'release Note',
          display: false,
          key: 'releaseNotes',
        },
      ]);
    }
  }, [letter]);

  const isFieldValid = () => {
    const { title, countryId } = settings;

    if (_.isUndefined(title) || _.isEmpty(title.trim())) {
      return message.error('letter name is required');
    }
    if (title.length > 200) {
      return message.error('letter name exceed character limit');
    }
    if (!countryId) {
      return message.error('You need to select country');
    }
  };
  const saveDocumentSettings = () => {
    const {
      title,
      description,
      categoryID,
      countryId,
      referenceNumber,
      requireApproval,
      requireEmployeeAcknowledgement,
      requireEmailNotification,
      requireEmployeeSignature,
      canBeDownloaded,
      requireSignedCopy,
      supportWatchers,
      requireSignature,
    } = settings;
    const questions = stateStorage.getValue('letterQuestion', []);
    const payload = {
      title,
      description,
      categoryID,
      countryId,
      referenceNumber,
      requireApproval,
      requireEmployeeAcknowledgement,
      requireEmailNotification,
      requireEmployeeSignature,
      canBeDownloaded,
      requireSignedCopy,
      supportWatchers,
      requireSignature,
      data: JSON.stringify(questions),
    };
    isFieldValid();

    updateSaving(true);
    updateLetter({ options: { assetID: letter.assetID }, payload }).then(
      () => {
        updateSaving(false);
        setDocumentSettingVisible(false);
      },
      (err: any) => {
        console.log(err);
        updateSaving(false);
      },
    );
  };
  const saveLetter = () => {
    const {
      title,
      description,
      categoryID,
      countryId,
      referenceNumber,
      requireApproval,
      requireEmployeeAcknowledgement,
      requireEmailNotification,
      requireEmployeeSignature,
      canBeDownloaded,
      requireSignedCopy,
      supportWatchers,
      requireSignature,
    } = settings;

    isFieldValid();
    const questions = stateStorage.getValue('letterQuestion', []);
    const payload = {
      title,
      description,
      categoryID,
      countryId,
      referenceNumber,
      requireApproval,
      requireEmployeeAcknowledgement,
      requireEmailNotification,
      requireEmployeeSignature,
      canBeDownloaded,
      requireSignedCopy,
      supportWatchers,
      requireSignature,
      data: JSON.stringify(questions),
    };

    return updateLetter({ options: { assetID: letter.assetID }, payload });
  };
  const saveDocument = (data: any) => {
    const questions = stateStorage.getValue('letterQuestion', []);
    return uploadLetterDocument({
      options: { id: letter.fileID },
      payload: {
        file: data.file,
        questions,
        name: `${letter.title}.${data.type}`,
      },
    });
  };
  const onLetterPublish = () => {
    const { releaseNotes, description } = settings;
    const { assetID, title } = letter;
    isFieldValid();
    if (_.isUndefined(description) || _.isEmpty(description.trim())) {
      return message.error('Letter description is required');
    }
    updateSaving(true);
    saveLetter().then(() => {
      const documentEditor = DocumentHelper.get();
      documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
        saveDocument({ file: doc, type: 'Docx', title }).then(() => {
          publishLetter({
            options: { assetID: letter.assetID },
            payload: {
              assetID,
              releaseNotes,
              modifiedBy: (current || {}).id,
              partnerCompanyId: getPartnerCompanyId(current),
            },
          }).then(
            () => {
              updateSaving(false);
              message.success('Letter published successfully');
              close();
            },
            (errors: Array<Error>) => message.error(errors),
          );
        });
      });
    });
  };
  const saveAsDraft = () => {
    const { title } = letter;
    isFieldValid();
    updateSaving(true);
    saveLetter().then(() => {
      const documentEditor = DocumentHelper.get();
      documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
        saveDocument({ file: doc, type: 'Docx', title }).then(() => {
          updateSaving(false);
          message.success('Letter saved successfully');
          close();
        });
      });
    });
  };
  const onDeleteLetter = () => {
    ConfirmBox({
      title:
        'You are about to discard this draft of contract. This action cannot be undone and all changes will be lost permanently. Are you sure you want to proceed with',
      contentName: letter.title,
      onOk: () => {
        deleteLetter({ options: { assetID: letter.assetID } }).then(
          () => {
            message.success('Letter deleted successfully');
            close();
          },
          (errors: Array<Error>) => message.error(errors),
        );
      },
    });
  };
  const printLetter = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.print();
  };
  const downloadLetter = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
      saveAs(doc, `${letter.title}.docx`);
    });
  };
  const close = () => {
    history.push('/letters');
  };
  const previewLetter = () => {
    const answers: any = {};
    const questions = stateStorage.getValue('letterQuestion', []);
    _.each(questions, (s) => {
      _.each(s.questions, (q) => {
        if (q.type === 5 && _.isUndefined(q.value)) {
          q.value = false;
        }
        if (!isNullUndefined(q.value)) {
          answers[q.name] = q.value;
        }
      });
    });
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
      const payload = { file: doc, questions: answers };
      previewWithMergeFields({
        options: {
          assetID: letter.assetID,
          versionNumber: isNullUndefined(letter.versionNumber) ? '' : letter.versionNumber,
        },
        payload,
      }).then(
        (response: any) => {
          updatePreviewData({ data: response.response });
          setPreviewVisible(true);
          updateSaving(false);
        },
        (errors: Array<Error>) => message.error(errors),
      );
    });
  };
  const onRetireLetter = () => {
    const { releaseNotes } = settings;
    const { assetID, versionNumber } = letter;
    retireLetter({
      options: { assetID: letter.assetID, versionNumber },
      payload: {
        assetID,
        retirementNotes: releaseNotes,
        modifiedBy: (current || {}).id,
        partnerCompanyId: getPartnerCompanyId(current),
      },
    }).then(
      () => {
        updateSaving(false);
        message.success('Letter retired successfully');
        close();
      },
      (errors: Array<Error>) => message.error(errors),
    );
  };
  const onNewDraftRelease = () => {
    Modal.confirm({
      title: 'New Draft',
      content: `You are about to create a new draft for this contract based on the current version: ${letter.versionNumber}`,
      okText: 'Create Draft',
      cancelText: 'Cancel',
      onOk: () => {
        const { assetID, versionNumber } = letter;
        draftLetter({ options: { assetID, versionNumber } }).then(
          ({ data }: any) => {
            history.push(`/letter/${data?.result?.assetID}/version/${data?.result?.versionNumber}`);
            window.location.reload();
          },
          (errors: Array<Error>) => message.error(errors),
        );
      },
    });
  };

  if (isNullUndefined(letter)) return <Loading />;

  const handleActionClick = (action: string) => {
    switch (action) {
      case 'Version History':
        setLetterVersionOpen(true);
        break;
      case 'Document Setting':
        setDocumentSettingVisible(true);
        break;
      case 'Publish':
        updatePublishSettingVisible(true);
        break;
      case 'Save As Draft':
        saveAsDraft();
        break;
      case 'Delete':
        onDeleteLetter();
        break;
      case 'Print':
        printLetter();
        break;
      case 'Download':
        downloadLetter();
        break;
      case 'Preview':
        previewLetter();
        break;
      case 'Retire':
        setRetireVisible(true);
        break;
      case 'New Draft Version':
        onNewDraftRelease();
        break;
      default:
        close();
    }
  };

  const updateContractSetting = (changes: any, elem: any) => {
    _.each(publishConfig, (config) => {
      if (config.key === 'requireSignature') {
        config.display = changes.requireApproval || false;
      }
      if (config.key === 'requireEmployeeSignature') {
        config.display = changes.requireEmployeeAcknowledgement || false;
      }
    });
    // swap label to make it consistent with expedo HR
    let newPublishConfig = _.cloneDeep(publishConfig);
    if (changes.requireApproval && (elem?.key || '') === 'requireApproval') {
      newPublishConfig = move(publishConfig, 8, 9);
      updatePublishConfig(newPublishConfig);
    } else if (!changes.requireApproval && (elem?.key || '') === 'requireApproval') {
      newPublishConfig = move(publishConfig, 9, 8);
      changes.requireSignature = false;
      updatePublishConfig(newPublishConfig);
    }
    updateSettings({ ...settings, ...changes });
  };

  return (
    <>
      <DocumentHeader
        content={letter}
        publishConfig={publishConfig}
        handleActionClick={handleActionClick}
        isVersionOpen={isVersionLetterOpen}
        current={current}
        setVersionOpen={setLetterVersionOpen}
        getContractVersion={getLetterVersion}
        getContractVersionPreview={getLetterVersionPreview}
        documentSettingVisible={documentSettingVisible}
        setDocumentSettingVisible={setDocumentSettingVisible}
        saving={saving}
        saveDocumentSettings={saveDocumentSettings}
        settings={settings}
        updateContractSetting={updateContractSetting}
        publishSettingVisible={publishSettingVisible}
        updatePublishSettingVisible={updatePublishSettingVisible}
        onContractPublish={onLetterPublish}
        retireVisible={retireVisible}
        setRetireVisible={setRetireVisible}
        onRetireContract={onRetireLetter}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        previewData={previewData}
      />
    </>
  );
};

const mapProps = (state: RootState) => ({
  letter: state.letter.letter,
  current: state.users.current,
  questions: state.letter.questions,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getLetterVersion: dispatch.letter.getLetterVersion,
  getLetterVersionPreview: dispatch.letter.getLetterVersionPreview,
  updateLetter: dispatch.letter.updateLetter,
  publishLetter: dispatch.letter.publishLetter,
  retireLetter: dispatch.letter.retireLetter,
  uploadLetterDocument: dispatch.letter.uploadLetterDocument,
  deleteLetter: dispatch.letter.deleteLetter,
  draftLetter: dispatch.letter.draftLetter,
  previewWithMergeFields: dispatch.letter.previewWithMergeFields,
});

export default connect(mapProps, mapDispatch)(EditAction);
