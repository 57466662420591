import React from 'react';

import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { Collapse, Input, message, Modal } from 'antd';
import cx from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import SimpleBarReact from 'simplebar-react';

import { JSONQuestionModel } from '../../@types/global';
import { DocumentEditorServiceUrl } from '../../common/constants/config';
import DocumentHelper from '../../helpers/DocumentHelper';
import { mustBeArray } from '../../helpers/util';

DocumentEditorContainerComponent.Inject(Toolbar);

const Panel = Collapse.Panel;

interface DocumentEditorProps {
  content?: any;
  getSfdt?: any;
  supportReference?: boolean;
  mergeFields?: any;
  toolbarItems: any;
  isReadOnly: boolean;
  onToolbarClick?: any;
  questions?: Array<JSONQuestionModel>;
  clauseCount?: number;
}

interface IDocumentEditorState {
  activePane: any;
  refVisible?: boolean;
  refValue?: string;
}

export class DocumentEditor extends React.Component<DocumentEditorProps, IDocumentEditorState> {
  state: IDocumentEditorState = {
    activePane: '0',
    refVisible: false,
    refValue: '',
  };
  private container: any;

  componentDidMount() {
    this.updateContainerSize();
    const { content } = this.props;
    this.props.getSfdt({ id: content.fileID }).then((response: any) => {
      DocumentHelper.set(this.container);
      if (response && response.data && this.container) {
        this.container.documentEditor.open(response.data || '{}');
      }
    });
  }

  onPaneChange = (activePane: any) => {
    this.setState({ activePane });
  };

  selectField = (field: string) => {
    const { statusId } = this.props.content || 1;
    if ([2, 3].includes(statusId)) {
      return false;
    }
    if (this.container) {
      this.container.documentEditor.editor.insertTextInternal(`<<${field}>>`, false);
    }
  };

  selectReferenceField = () => {
    this.setState({ refVisible: true });
  };
  handleReferenceChange = (value: string) => {
    this.setState({ refValue: value });
  };
  handleReferenceOk = () => {
    const { refValue } = this.state;

    if (_.isEmpty((refValue || '').trim())) {
      return message.error('Reference cannot be empty');
    }
    if ((refValue || '').trim().length > 6) {
      return message.error('Reference cannot be more than 6 digits');
    }
    if (this.container) {
      this.container.documentEditor.editor.insertTextInternal(`<<REF:${refValue}>>`, false);
    }
    this.setState({
      refVisible: false,
      refValue: '',
    });
  };
  handleCancel = () => {
    this.setState({ refVisible: false });
  };

  updateContainerSize() {
    (document.getElementById('container') as HTMLElement).style.height =
      window.innerHeight - 60 + 'px';
  }

  render() {
    const { mergeFields, toolbarItems, supportReference, isReadOnly, questions, clauseCount } =
      this.props;
    const { activePane } = this.state;

    return (
      <>
        <div className='grid grid-cols-12 gap-4 bg-gray-200'>
          {mergeFields && (
            <SimpleBarReact className={'col-span-3'} style={{ maxHeight: 'calc(-70px + 100vh)' }}>
              <div className=' p-4'>
                <div className='flex flex-col mb-4'>
                  <div className='flex pl-4 font-semibold text-lg'>Merge Field</div>
                </div>
                <div className='divide-y divide-gray-500' />
                <div id='mergeFieldList' className={'pl-8'}>
                  <Collapse
                    accordion
                    bordered={false}
                    onChange={(newIndex) => this.onPaneChange(newIndex)}
                    className='border border-transparent bg-transparent'
                  >
                    {mustBeArray(mergeFields).map((fields, j) => {
                      return (
                        <Panel
                          showArrow={false}
                          key={j}
                          className='bg-white rounded-md mb-2 border border-gray-500'
                          header={
                            <div data-tut={fields.fieldId} className='p-0'>
                              <span className='font-semibold border-0 leading-tight'>
                                {fields.name}
                              </span>
                              <div className='float-right'>
                                {' '}
                                {Number(activePane) === j ? '-' : '+'}{' '}
                              </div>
                              <div className='clearfix' />
                            </div>
                          }
                        >
                          {fields.value.map((data: any) => {
                            return (
                              <div
                                className='f-s-16 leading-6'
                                style={{ cursor: 'pointer' }}
                                key={nanoid()}
                                onClick={() => this.selectField(data.name)}
                              >
                                {data.text}
                              </div>
                            );
                          })}
                        </Panel>
                      );
                    })}
                    {!_.isEmpty(questions) && (
                      <Panel
                        showArrow={false}
                        key={45646465}
                        className='bg-white rounded-md mb-2 border border-transparent'
                        header={
                          <div data-tut='reactour-questionnaire'>
                            <span className='font-semibold'>Questions</span>
                            <div className='float-right'>
                              {' '}
                              {Number(activePane) === 45646465 ? '-' : '+'}{' '}
                            </div>
                            <div className='clearfix' />
                          </div>
                        }
                      >
                        {questions &&
                          mustBeArray(questions).map((data) => {
                            return !data.hidden
                              ? mustBeArray(data.questions).map((eachQuestion, idx) => {
                                  switch (eachQuestion.name) {
                                    case 'ProbationaryPeriod':
                                      return [
                                        {
                                          text: 'Contract Has Probation',
                                          name: 'ContractHasProbation',
                                        },
                                        {
                                          text: 'Contract Probation Length',
                                          name: 'ContractProbationLength',
                                        },
                                      ].map((item, j) => {
                                        return (
                                          <div
                                            className='f-s-16'
                                            style={{
                                              color: '#0b78ff',
                                              cursor: 'pointer',
                                            }}
                                            key={`${idx}-${j}`}
                                            onClick={() => this.selectField(item.name)}
                                          >
                                            {item.text}
                                          </div>
                                        );
                                      });
                                    case 'NoticePeriod':
                                      return [
                                        {
                                          text: 'Employer Notice Period',
                                          name: 'ContractEmployerNoticePeriod',
                                        },
                                        {
                                          text: 'Employer Notice Period2',
                                          name: 'ContractEmployerNoticePeriod2',
                                        },
                                        {
                                          text: 'Employer Notice Period3',
                                          name: 'ContractEmployerNoticePeriod3',
                                        },
                                        {
                                          text: 'Employer Notice Period4',
                                          name: 'ContractEmployerNoticePeriod4',
                                        },
                                      ].map((item, j) => {
                                        return (
                                          <div
                                            className='f-s-16'
                                            style={{
                                              color: '#0b78ff',
                                              cursor: 'pointer',
                                            }}
                                            key={`${idx}-${j}`}
                                            onClick={() => this.selectField(item.name)}
                                          >
                                            {item.text}
                                          </div>
                                        );
                                      });
                                    default:
                                      return (
                                        <div
                                          className='f-s-16'
                                          style={{
                                            color: '#0b78ff',
                                            cursor: 'pointer',
                                          }}
                                          key={idx}
                                          onClick={() => this.selectField(eachQuestion.name)}
                                        >
                                          {eachQuestion.label}
                                        </div>
                                      );
                                  }
                                })
                              : null;
                          })}
                      </Panel>
                    )}
                    {clauseCount && (
                      <Panel
                        showArrow={false}
                        key={45996465}
                        className='bg-white rounded-md mb-2 border border-transparent'
                        header={
                          <React.Fragment>
                            <span className='font-semibold'>Clause</span>
                            <div className='float-right'>
                              {' '}
                              {Number(activePane) === 45996465 ? '-' : '+'}{' '}
                            </div>
                            <div className='clearfix' />
                          </React.Fragment>
                        }
                      >
                        <div
                          className='f-s-16'
                          style={{ color: '#0b78ff', cursor: 'pointer' }}
                          key={11}
                          onClick={() => this.selectField('Clauses')}
                        >
                          Clauses
                        </div>
                      </Panel>
                    )}
                    {supportReference && (
                      <Panel
                        showArrow={false}
                        key={459964988}
                        className='bg-white rounded-md mb-2 border border-transparent'
                        header={
                          <React.Fragment>
                            <span className='font-semibold'>References</span>
                            <div className='float-right'>
                              {' '}
                              {Number(this.state.activePane) === 459964988 ? '-' : '+'}{' '}
                            </div>
                            <div className='clearfix' />
                          </React.Fragment>
                        }
                      >
                        <div
                          className='f-s-16'
                          style={{ color: '#0b78ff', cursor: 'pointer' }}
                          key={11}
                          onClick={() => this.selectReferenceField()}
                        >
                          Add Reference
                        </div>
                      </Panel>
                    )}
                  </Collapse>
                </div>
              </div>
            </SimpleBarReact>
          )}
          <div className={cx({ 'col-span-9': mergeFields, 'col-span-12': !mergeFields })}>
            <main className='sm:w-full overflow-y-auto sm:ml-6 lg:ml-0'>
              <DocumentEditorContainerComponent
                id='container'
                serviceUrl={DocumentEditorServiceUrl}
                toolbarClick={this.props.onToolbarClick}
                ref={(scope) => {
                  this.container = scope;
                }}
                style={{ height: '900px' }}
                enableToolbar={true}
                restrictEditing={isReadOnly}
                toolbarItems={toolbarItems}
              />
            </main>
          </div>
        </div>
        <Modal
          title={'Add Reference '}
          visible={this.state.refVisible}
          onOk={this.handleReferenceOk}
          onCancel={this.handleCancel}
        >
          <Input
            size='large'
            className='m-b-20'
            placeholder='Reference'
            value={this.state.refValue}
            onChange={(event) => this.handleReferenceChange(event.currentTarget.value)}
          />
        </Modal>
        <input type='hidden' value={'<<comparison>>'} id='find_text' />
        <input type='hidden' value={'<<endcomparison>>'} id='find_text2' />
      </>
    );
  }
}

export default DocumentEditor;
