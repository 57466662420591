import { RematchDispatch } from '@rematch/core';

import { CourseCategory, CourseLevel } from '../@types/course';
import { Country, CountryState, QueryFilter } from '../@types/global';
import LookupService from '../services/LookupService';

type State = {
  countries: ReadonlyArray<Country>;
  courseCategories: ReadonlyArray<CourseCategory>;
  courseLevel: ReadonlyArray<CourseLevel>;
  states: ReadonlyArray<CountryState>;
};

const model = {
  state: {} as State,
  reducers: {
    loadCountryLookup: (state: State, payload: { total: number; rows: ReadonlyArray<Country> }) => {
      return { ...state, countries: payload.rows };
    },
    loadCourseCategory: (
      state: State,
      payload: { total: number; rows: ReadonlyArray<CourseCategory> },
    ) => {
      return { ...state, courseCategories: payload.rows };
    },
    loadCourseLevel: (
      state: State,
      payload: { total: number; rows: ReadonlyArray<CourseLevel> },
    ) => {
      return { ...state, courseLevel: payload.rows };
    },
    states: (state: State, payload: { total: number; rows: ReadonlyArray<CountryState> }) => {
      return { ...state, states: payload.rows };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async lookupCountry(params: QueryFilter) {
      const response = await LookupService.country(params);
      dispatch.lookup.loadCountryLookup(response.data.result);
    },
    async lookupCourseCategory(params: QueryFilter) {
      const response = await LookupService.courseCategory(params);
      dispatch.lookup.loadCourseCategory(response.data.result);
    },
    async lookupCourseLevel(params: QueryFilter) {
      const response = await LookupService.courseLevel(params);
      dispatch.lookup.loadCourseLevel(response.data.result);
    },
    async lookupState(params: QueryFilter) {
      const response = await LookupService.states(params);
      dispatch.lookup.states(response.data.result);
    },
  }),
};

export default model;
