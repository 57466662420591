import React from 'react';

import { useParams } from 'react-router-dom';

import HeaderActions from '../../../common/headerActions';
import history from '../../../routes/history';

const EditHandbookAction = () => {
  const { id } = useParams<any>();
  const validActions = [
    { name: 'Preview', style: 'primary', visible: true },
    { name: 'Close', style: 'default', visible: true },
  ];
  const handleActionClick = (action: string) => {
    if (action === 'Preview') {
      history.push(`/handbook/preview/${id}?origin=detail`);
    } else {
      history.push('/handbooks');
    }
  };

  return <HeaderActions actions={validActions} handleActionClick={handleActionClick} />;
};

export default EditHandbookAction;
