import { useEffect, useState } from 'react';

import { Form, UploadFile } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UploadChangeParam } from 'antd/lib/upload';
import PubSub from 'pubsub-js';
import { useDispatch, useSelector } from 'react-redux';

import {
  COURSE_TYPE,
  CourseStatus,
  ENTITY_MODE,
  IGetScormPreviewLinkResDto,
  PUBSUB_COURSE_ACTION,
} from '../../../../@types/course';
import message from '../../../../common/message';
import IframeHelper from '../../../../helpers/IframeHelper';
import { getBase64, getPartnerCompanyId } from '../../../../helpers/util';
import history from '../../../../routes/history';
import { RootDispatch, RootState } from '../../../../store';
import { CourseFormProps, CreateExternalCourseParams, FormData } from '../CourseForm.types';

const useCourseFormState = (props: CourseFormProps) => {
  const {
    title,
    subTitle,
    mode,
    courseType,
    onStepChange: handleStepChange,
    onModalCancel: handleModalCancel,
    onCancel: handleCancel,
  } = props;

  const { steps = [], currentStep = 0 } = props?.flowControls || {};

  const [replaceScormModalOpen, setReplaceScormModalOpen] = useState<boolean>(false);
  const [fileUploadModalError, setFileUploadModalError] = useState<string>('');
  const [fileUploadErrorModalOpen, setFileUploadErrorModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm<FormData>();

  const courseImageValue = Form.useWatch('courseImage', form);
  const courseImageUrlValue = Form.useWatch('courseImageUrl', form) || '';

  const scormCourseValue = Form.useWatch('scormCourse', form);

  const dispatch = useDispatch<RootDispatch>();
  const currentUser = useSelector((state: RootState) => state.users.current);
  const currentCourse = useSelector((state: RootState) => state.course.course);

  const {
    course: {
      getCourse,
      createCourse,
      updateCourse,
      uploadCourseFile,
      uploadScormCourseFile,
      uploadScormCourseFileNoId,
      getScormPreviewLink,
    },
  } = dispatch;

  const initUpdateCourse = () => {
    const formValues = form.getFieldsValue(true);
    handleUpdateCourse(formValues).then(() => {
      PubSub.publish(PUBSUB_COURSE_ACTION.END_UPDATE);
    });
  };

  const subscribeInitUpdateCourseEvent =
    mode === ENTITY_MODE.UPDATE
      ? PubSub.subscribe(PUBSUB_COURSE_ACTION.INIT_UPDATE, initUpdateCourse)
      : '';

  useEffect(() => {
    subscribeInitUpdateCourseEvent;
    return () => {
      PubSub.unsubscribe(subscribeInitUpdateCourseEvent);
    };
  }, [subscribeInitUpdateCourseEvent]);

  const handleChange = (key: string, value: unknown) => {
    form.setFieldValue(key, value);
  };

  const handleCourseImageChange = (info: UploadChangeParam<UploadFile<unknown>>) => {
    getBase64(info.file, (imageUrl: string) => {
      handleChange('courseImageUrl', imageUrl);
      handleChange('courseImage', info.file);
    });
  };

  const handleScormCourseChange = (info: UploadChangeParam<UploadFile<unknown>>) => {
    getBase64(info.file, () => {
      handleChange('scormCourse', info.file);
    });
  };

  const getPartialLable = (
    label: string,
    inputPosition: number,
    location: 'BeforeInput' | 'AfterInput',
  ): string => {
    const wordArr = label.split(' ');
    const filteredWordArr =
      location === 'BeforeInput'
        ? wordArr.filter((word, index) => index < inputPosition - 1)
        : wordArr.filter((word, index) => index >= inputPosition - 1);
    const resultStr = filteredWordArr.join(' ');
    return resultStr;
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent): void => {
    switch (e.target.name) {
      case 'isAdditionalAttemptsEnabled':
        if (!e.target.checked) {
          setIsAdditionalAttemptsEnabled(false);
          handleChange('additionalAttempts', 0);
        } else {
          setIsAdditionalAttemptsEnabled(true);
          handleChange('additionalAttempts', 1);
        }
        break;

      case 'isCompletionDaysEnabled':
        if (!e.target.checked) {
          setIsCompletionDaysEnabled(false);
          handleChange('completionDays', null);
        } else {
          setIsCompletionDaysEnabled(true);
          handleChange('completionDays', 30);
        }
        break;

      default:
        break;
    }
  };

  const getFormItemLabel = (text: string) => {
    const customLabelStyles = { fontSize: '14px', fontWeight: 'bold' };
    return <p style={customLabelStyles}>{text}</p>;
  };

  const checkIsCourseImageReplaced = (values: FormData) => {
    const {
      courseImage: { fileName },
    } = currentCourse;
    return values.courseImage?.name !== undefined && fileName !== values.courseImage?.name;
  };

  const checkIsScormCourseReplaced = (values: FormData) => {
    const {
      externalCourseFile: { fileName },
    } = currentCourse;
    return fileName !== values.scormCourse?.fileName;
  };

  const handleSubmit = async (values: FormData) => {
    switch (mode) {
      case ENTITY_MODE.CREATE:
        await handleCreateCourse(values);
        break;
      case ENTITY_MODE.UPDATE:
        await handleUpdateCourse(values, ENTITY_MODE.UPDATE);
        break;
      case ENTITY_MODE.REVIEW:
        await handleUpdateCourse(values, ENTITY_MODE.REVIEW);
        break;
    }
  };

  const createCourseRecord = async (
    values: FormData,
    createExternalCourseParams?: CreateExternalCourseParams,
  ) => {
    const {
      name,
      categoryID,
      levelID,
      description,
      additionalAttempts,
      completionDays,
      courseImage,
      canBeUsedForOnboarding,
    } = values;

    const createCourseRes = await createCourse({
      payLoad: {
        name,
        statusId: 1,
        categoryId: categoryID,
        levelId: levelID,
        description,
        additionalAttempts,
        completionDays,
        canBeUsedForOnboarding,
        partnerCompanyId: getPartnerCompanyId(currentUser),
        createdBy: (currentUser || {}).id,
        externalCourseFileId: createExternalCourseParams?.externalCourseFileId,
        externalCourseId: createExternalCourseParams?.externalCourseId,
      },
    });
    const newCourseId = (createCourseRes as any).data.id;

    if (newCourseId) message.success({ content: `Course created successfully!` });
    const getCourseRes = await getCourse({ options: { id: newCourseId } });

    const courseImageFileId = getCourseRes.courseImage.fileId;
    if (newCourseId && courseImageFileId && courseImage) {
      await uploadCourseFile({
        payLoad: { file: courseImageValue, name: courseImageValue?.name },
        options: { id: newCourseId, type: 'image' },
      });
      message.success({ content: `Image successfully uploaded!` });
    }

    return newCourseId;
  };

  const updateCourseRecord = async (values: FormData, entityMode?: ENTITY_MODE) => {
    const { id, statusID } = currentCourse;
    const {
      name,
      categoryID,
      levelID,
      description,
      additionalAttempts,
      completionDays,
      countryId,
      canBeUsedForOnboarding,
    } = values;

    await updateCourse({
      options: { id },
      payLoad: {
        name,
        statusID: entityMode === ENTITY_MODE.REVIEW ? CourseStatus.Published : statusID,
        categoryId: categoryID,
        countryId,
        levelId: levelID,
        description,
        additionalAttempts,
        completionDays,
        canBeUsedForOnboarding,
        modifiedBy: (currentUser || {}).id,
      },
    })
      .then(async () => {
        getCourse({ options: { id } });
        message.success('Course updated successfully');
        if (IframeHelper.isIframeScreen()) {
          const iframeConfig = IframeHelper.getConfig();
          window.parent.postMessage(
            {
              event: 'course:publish:success',
            },
            iframeConfig.origin,
          );
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleCreateFlowRedirection = (id: string) => {
    if (IframeHelper.isIframeScreen()) {
      const iframeConfig = IframeHelper.getConfig();
      window.parent.postMessage(
        {
          event: 'course:create:success',
          payload: { id, courseType: courseType },
        },
        iframeConfig.origin,
      );
    } else {
      if (courseType === COURSE_TYPE.BYO) history.push(`/editor/courses/${id}`);
      if (courseType === COURSE_TYPE.UPLOAD) history.push(`/courses`);
    }
  };

  const handleUpdateFlowRedirection = (entityMode?: ENTITY_MODE) => {
    if (entityMode === ENTITY_MODE.UPDATE || entityMode === ENTITY_MODE.REVIEW) {
      history.push(`/courses`);
    }
  };

  const handleCreateCourse = async (values: FormData) => {
    const { scormCourse } = values;

    if (courseType === COURSE_TYPE.UPLOAD && scormCourse) {
      setIsLoading(true);
      const uploadScormCourseFileNoIdRes: any = await uploadScormCourseFileNoId({
        payLoad: { file: scormCourseValue, name: scormCourseValue?.name },
      });

      if (uploadScormCourseFileNoIdRes.status === 400) {
        setIsLoading(false);
        const resJson = JSON.parse(uploadScormCourseFileNoIdRes.response);
        const errorMessage = resJson[0].errorMessage?.message;
        message.error({ content: errorMessage });
        setFileUploadModalError(errorMessage);
        setFileUploadErrorModalOpen(true);
      } else {
        const successResJson = JSON.parse(uploadScormCourseFileNoIdRes.response);
        message.success({ content: `Scorm Course zip file successfully uploaded!` });
        const newCourseId = await createCourseRecord(
          values,
          successResJson as CreateExternalCourseParams,
        );
        setIsLoading(false);
        handleCreateFlowRedirection(newCourseId);
      }
    }

    if (courseType === COURSE_TYPE.BYO) {
      setIsLoading(true);
      const newCourseId = await createCourseRecord(values);
      setIsLoading(false);
      handleCreateFlowRedirection(newCourseId);
    }
  };

  const handleUpdateCourse = async (values: FormData, entityMode?: ENTITY_MODE) => {
    const { id } = currentCourse;
    const { courseImage, scormCourse } = values;

    const isCourseImageReplaced = checkIsCourseImageReplaced(values);
    if (isCourseImageReplaced && courseImage) {
      setIsLoading(true);
      message.success({ content: `Uploading image...` });
      await uploadCourseFile({
        payLoad: { file: courseImageValue, name: courseImageValue?.name },
        options: { id, type: 'image' },
      })
        .then(() => {
          setIsLoading(false);
          message.success({ content: `Image successfully uploaded!` });
        })
        .catch(() => {
          setIsLoading(false);
          message.success({ content: `Image upload failed!` });
        });
    }

    const isScormCourseReplaced = checkIsScormCourseReplaced(values);
    if (courseType === COURSE_TYPE.UPLOAD && isScormCourseReplaced && scormCourse) {
      setIsLoading(true);
      message.success({ content: `Uploading Scorm Course zip file...` });
      const uploadScormCourseFileRes: any = await uploadScormCourseFile({
        payLoad: { file: scormCourseValue, name: scormCourseValue?.name },
        options: { id },
      });
      if (uploadScormCourseFileRes.status === 400) {
        setIsLoading(false);
        const resJson = JSON.parse(uploadScormCourseFileRes.response);
        const errorMessage = resJson[0].errorMessage?.message;
        message.error({ content: errorMessage });
        setFileUploadModalError(errorMessage);
        setFileUploadErrorModalOpen(true);
      } else {
        message.success({ content: `Scorm Course zip file successfully uploaded!` });
        await updateCourseRecord(values, entityMode);
        setIsLoading(false);
        handleUpdateFlowRedirection(entityMode);
      }
    }

    if (
      courseType === COURSE_TYPE.BYO ||
      (courseType === COURSE_TYPE.UPLOAD && !isScormCourseReplaced)
    ) {
      setIsLoading(true);
      await updateCourseRecord(values, entityMode);
      setIsLoading(false);
      handleUpdateFlowRedirection(entityMode);
    }
  };

  const handleUpdateBtnClick = () => {
    const {
      externalCourseFile: { fileName },
    } = currentCourse;

    if (fileName !== scormCourseValue?.fileName) {
      setReplaceScormModalOpen(true);
    } else {
      form.submit();
    }
  };

  const handlePreview = async () => {
    const { id, isExternalCourse } = currentCourse;
    if (isExternalCourse) {
      try {
        const getScormPreviewLinkRes: IGetScormPreviewLinkResDto = await getScormPreviewLink({
          options: { id },
        });
        window.open(getScormPreviewLinkRes.previewLinkURL, '_blank');
      } catch (err) {
        message.error({ content: err });
      }
    } else {
      history.push(`/course/preview/${id}?origin=list`);
    }
  };

  const getInitialValues = () => {
    const emptyInitialValues: FormData = {
      name: '',
      categoryID: null,
      levelID: null,
      description: '',
      courseImageUrl: '',
      courseImage: null,
      scormCourse: null,
      additionalAttempts: null,
      isAdditionalAttemptsEnabled: false,
      completionDays: null,
      isCompletionDaysEnabled: false,
      canBeUsedForOnboarding: false,
      countryId: [],
    };
    return mode === ENTITY_MODE.CREATE
      ? emptyInitialValues
      : {
          ...currentCourse,
          scormCourse: currentCourse?.externalCourseFile,
          courseImageUrl: currentCourse?.courseImage?.filePath,
          isAdditionalAttemptsEnabled: !!currentCourse?.additionalAttempts,
          isCompletionDaysEnabled: !!currentCourse?.completionDays,
          canBeUsedForOnboarding: currentCourse?.canBeUsedForOnboarding || false,
        };
  };

  const initialValues = getInitialValues();
  const [isAdditionalAttemptsEnabled, setIsAdditionalAttemptsEnabled] = useState<boolean>(
    !!initialValues.additionalAttempts,
  );
  const [isCompletionDaysEnabled, setIsCompletionDaysEnabled] = useState<boolean>(
    !!initialValues.completionDays,
  );

  return {
    title,
    subTitle,
    handleStepChange,
    handleModalCancel,
    handleCancel,
    steps,
    currentStep,
    replaceScormModalOpen,
    setReplaceScormModalOpen,
    courseImageUrlValue,
    mode,
    form,
    isLoading,
    initialValues,
    handleSubmit,
    getFormItemLabel,
    handleChange,
    handleCourseImageChange,
    courseType,
    currentCourse,
    handleScormCourseChange,
    isAdditionalAttemptsEnabled,
    handleCheckboxChange,
    getPartialLable,
    isCompletionDaysEnabled,
    handleUpdateBtnClick,
    fileUploadErrorModalOpen,
    setFileUploadErrorModalOpen,
    fileUploadModalError,
    setFileUploadModalError,
    handlePreview,
  };
};

export default useCourseFormState;
