import { ActionHandler, QueryFilter } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';
import { delay, mapWorkflowStepType } from '../helpers/util';

class WorkflowService {
  public listWorkflow(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_URL' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public documentPreview = ({ id, stepId }: { id: number; stepId: number }) => {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'WORKFLOW_PREVIEW_DOWNLOAD',
      options: { id, stepId },
      isMocked: false,
    });
    return http.getPreviewFIle(url, {});
  };

  public uploadStepAttachment = ({ options, payLoad }: any) => {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_DOC_ATTACHMENT', options });
    return http.postFile(url, payLoad);
  };

  public dragWorkflowStep = ({ payLoad, options }: any) => {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_URL' });
    return http.patch(`${url}/${options.id}`, payLoad);
  };

  public dragWorkflowStepContent = ({ payLoad, options }: any) => {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_URL', options });
    return http.patch(`${url}/${options.stepID}`, payLoad);
  };

  public workflowActionHandler = ({
    id,
    action,
    payLoad = {},
    options = {},
    queryOptions = {},
  }: ActionHandler) => {
    let url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_URL', isMocked: false });
    switch (action) {
      case 'list':
        return http.get(url, queryOptions);
      case 'get':
        return http.get(`${url}/${id}`, queryOptions);
      case 'create':
        return http.post(url, payLoad);
      case 'update':
        return http.patch(`${url}/${id}`, payLoad);
      case 'upload': {
        const endpoint =
          options.content === 'flowchartImage' ? 'WORKFLOW_CHART_URL' : 'WORKFLOW_FILE_URL';
        url = ApiUrlHelper.constructEndpoint({ endpoint, options });
        return http.postFile(url, payLoad);
      }
      case 'delete':
        return http.del(`${url}/${id}`);
      default:
        return delay(100);
    }
  };

  public workflowStepActionHandler = ({
    id,
    action,
    payLoad = {},
    options = {},
    queryOptions = {},
  }: ActionHandler) => {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'WORKFLOW_STEP_URL',
      options,
      isMocked: false,
    });
    switch (action) {
      case 'list':
        return http.get(url, queryOptions);
      case 'get':
        return http.get(`${url}/${id}`, queryOptions);
      case 'create':
        return http.post(url, payLoad);
      case 'update':
        return http.patch(`${url}/${id}`, payLoad);
      case 'delete':
        return http.del(`${url}/${id}`);
      default:
        return delay(100);
    }
  };

  public workflowContentHandler = ({ id, action, payLoad = {}, options = {} }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'list':
        switch (Number(payLoad.stepContentTypeID)) {
          case mapWorkflowStepType('question'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_QUESTION', options });
            return http.get(url);
          case mapWorkflowStepType('document'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DOCUMENT', options });
            return http.get(url);
          case mapWorkflowStepType('task'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_TASK', options });
            return http.get(url);
          case mapWorkflowStepType('evaluation'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_EVALUATION', options });
            return http.get(url);
          default:
            return delay(100);
        }
      case 'get':
        switch (Number(payLoad.stepContentTypeID)) {
          case mapWorkflowStepType('dialog'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DIALOG', options });
            return http.get(`${url}/${id}`);
          case mapWorkflowStepType('question'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_QUESTION', options });
            return http.get(`${url}/${id}`);
          case mapWorkflowStepType('contract'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_CONTRACT', options });
            return http.get(`${url}/${id}`);
          case mapWorkflowStepType('letter'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_LETTER', options });
            return http.get(`${url}/${id}`);
          case mapWorkflowStepType('document'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DOCUMENT', options });
            return http.get(`${url}/${id}`);
          case mapWorkflowStepType('task'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_TASK', options });
            return http.get(`${url}/${id}`);
          case mapWorkflowStepType('evaluation'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_EVALUATION', options });
            return http.get(`${url}/${id}`);
          default:
            return delay(100);
        }
      case 'create':
        switch (Number(payLoad.stepContentTypeID)) {
          case mapWorkflowStepType('dialog'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DIALOG', options });
            return http.post(`${url}`, payLoad);
          case mapWorkflowStepType('question'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_QUESTION', options });
            return http.post(`${url}`, payLoad);
          case mapWorkflowStepType('contract'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_CONTRACT', options });
            return http.post(`${url}`, payLoad);
          case mapWorkflowStepType('letter'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_LETTER', options });
            return http.post(`${url}`, payLoad);
          case mapWorkflowStepType('document'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DOCUMENT', options });
            return http.post(`${url}`, payLoad);
          case mapWorkflowStepType('task'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_TASK', options });
            return http.post(`${url}`, payLoad);
          case mapWorkflowStepType('evaluation'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_EVALUATION', options });
            return http.post(`${url}`, payLoad);
          default:
            return delay(100);
        }
      case 'update':
        switch (Number(payLoad.stepContentTypeID)) {
          case mapWorkflowStepType('dialog'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DIALOG', options });
            return http.patch(`${url}/${id}`, payLoad);
          case mapWorkflowStepType('question'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_QUESTION', options });
            return http.patch(`${url}/${id}`, payLoad);
          case mapWorkflowStepType('contract'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_CONTRACT', options });
            return http.patch(`${url}/${id}`, payLoad);
          case mapWorkflowStepType('letter'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_LETTER', options });
            return http.patch(`${url}/${id}`, payLoad);
          case mapWorkflowStepType('document'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DOCUMENT', options });
            return http.patch(`${url}/${id}`, payLoad);
          case mapWorkflowStepType('task'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_TASK', options });
            return http.patch(`${url}/${id}`, payLoad);
          case mapWorkflowStepType('evaluation'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_EVALUATION', options });
            return http.patch(`${url}/${id}`, payLoad);
          default:
            return delay(100);
        }
      case 'delete':
        switch (Number(payLoad.stepContentTypeID)) {
          case mapWorkflowStepType('dialog'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DIALOG', options });
            return http.del(`${url}/${id}`);
          case mapWorkflowStepType('question'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_QUESTION', options });
            return http.del(`${url}/${id}`);
          case mapWorkflowStepType('contract'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_CONTRACT', options });
            return http.del(`${url}/${id}`);
          case mapWorkflowStepType('letter'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_LETTER', options });
            return http.del(`${url}/${id}`);
          case mapWorkflowStepType('document'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_DOCUMENT', options });
            return http.del(`${url}/${id}`);
          case mapWorkflowStepType('task'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_TASK', options });
            return http.del(`${url}/${id}`);
          case mapWorkflowStepType('evaluation'):
            url = ApiUrlHelper.constructEndpoint({ endpoint: 'WORKFLOW_STEP_EVALUATION', options });
            return http.del(`${url}/${id}`);
          default:
            return delay(100);
        }
      default:
        return delay(100);
    }
  };
}

const instance = new WorkflowService();
Object.freeze(instance);

export default instance;
