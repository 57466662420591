import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import routes from '../../routes/routes';
import ClauseEditAction from '../Clause/Actions/Edit';
import ClauseVariantAction from '../Clause/Actions/VarientEdit';
import ContractEditAction from '../Contract/Actions/Edit';
import CourseEditAction from '../Course/Actions/Edit';
import CoursePreviewAction from '../Course/Actions/Preview';
import HandbookEditAction from '../Handbook/Actions/Edit';
import HandbookPreviewAction from '../Handbook/Actions/Preview';
import LetterEditAction from '../Letter/Actions/Edit';
import EditWorkflowDocumentAction from '../Workflow/Actions/Document';
import WorkflowEditAction from '../Workflow/Actions/Edit';
import PreviewWorkflowAction from '../Workflow/Actions/Preview';

const ActionHeader = withRouter(({ match }: RouteComponentProps) => {
  const currentRoute = _.find(routes, (route) => route.path === match.path) || { name: '' };
  switch (currentRoute.name) {
    case 'contract.view':
      return <ContractEditAction />;
    case 'letter.view':
      return <LetterEditAction />;
    case 'clause.view':
      return <ClauseEditAction />;
    case 'clause.variant.view':
      return <ClauseVariantAction />;
    case 'course.view':
      return <CourseEditAction />;
    case 'course.preview':
      return <CoursePreviewAction />;
    case 'handbook.view':
      return <HandbookEditAction />;
    case 'handbook.preview':
      return <HandbookPreviewAction />;
    case 'workflow.view':
      return <WorkflowEditAction />;
    case 'workflow.document.view':
      return <EditWorkflowDocumentAction />;
    case 'workflow.preview':
      return <PreviewWorkflowAction />;
    default:
      return <></>;
  }
});

export default ActionHeader;
