import { FC, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal } from 'antd';

import { User } from '../../@types/user';
import message from '../../common/message';
import { getPartnerCompanyId } from '../../helpers/util';
import history from '../../routes/history';

interface ICreate {
  visible: boolean;
  onCancel: Function;
  current: User;
  createWorkflow: Function;
}

const Create: FC<ICreate> = ({ visible, onCancel, createWorkflow, current }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onFinish = (values: any) => {
    setLoading(true);
    createWorkflow({
      payLoad: {
        name: values.name,
        partnerCompanyId: getPartnerCompanyId(current),
        createdBy: (current || {}).id,
      },
    }).then(
      (response: any) => {
        setLoading(false);
        onClose();
        const { id } = response.data.result;
        history.push(`/workflow/${id}`);
      },
      (errors: Array<any>) => {
        message.error(errors);
        setLoading(false);
      },
    );
  };

  return (
    <Modal visible={visible} title={null} footer={null} width={1000} onCancel={() => onCancel()}>
      <div className='grid grid-cols-2 gap-4 p-4'>
        <div className={'relative'}>
          <div className='px-10'>
            <p className={'font-bold font-lg'}>Workflows</p>
            <p className='my-4'>
              This tool will provide you with the ability to create an Expedo Workflow. To start,
              please provide the details of the new Template using the fields provided.
            </p>
          </div>
          <Divider className={'absolute -right-8 top-8'} type='vertical' style={{ height: 270 }} />
        </div>
        <div className={'px-12'}>
          <div className={'flex flex-start mb-8 items-center'}>
            <span className={'ml-1'}>Create a workflow</span>
          </div>
          <div className='flex'>
            <Form
              name='clauseForm'
              className={'w-full'}
              layout='vertical'
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label='Workflow title'
                name='name'
                rules={[{ required: true, message: 'Workflow title is required' }]}
              >
                <Input
                  className='form__input form__input--block'
                  placeholder='eg. Workflow title'
                />
              </Form.Item>

              <Form.Item>
                <div className='flex justify-between gap-4'>
                  <Button
                    className='btn btn--default'
                    block
                    size={'large'}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='btn btn--primary'
                    type='primary'
                    htmlType='submit'
                    block
                    size={'large'}
                    loading={loading}
                  >
                    Next
                    <ArrowRightOutlined className={'float-right mt-1'} />
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Create;
