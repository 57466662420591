import React, { useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import cx from 'classnames';
import _ from 'lodash';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { IRole, User } from '../../@types/user';
import {
  ClientIcon,
  ContractIcon,
  CourseIcon,
  HandbookIcon,
  HomeIcon,
  LetterIcon,
  PartnerCompanyIcon,
  SectionIcon,
  WorkflowIcon,
} from '../../common/assets';
import Loading from '../../common/Loading';
import { isNullUndefined, mustBeArray } from '../../helpers/util';

type SidebarProps = RouteComponentProps & { current: User };

const Sidebar = withRouter((props: SidebarProps) => {
  const { match, current } = props;
  const [navShow, setNavShow] = useState(false);
  if (isNullUndefined(current)) return <Loading />;

  const currentRole: IRole = _.first(current.roles) || { roleName: '', roleCode: '' };
  const IndexMenu = [
    {
      icon: <HomeIcon selected={['/'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Dashboard',
      link: '/',
      claim: 'Dashboard',
      visible: true,
    },
    {
      icon: <PartnerCompanyIcon selected={['/partners'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Partners',
      link: '/partners',
      claim: 'Partners',
      visible: currentRole.roleCode !== 'PPSU',
    },
    {
      icon: <ClientIcon selected={['/clients'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Clients',
      link: '/clients',
      claim: 'Clients',
      visible: currentRole.roleCode !== 'PPSU',
    },
    {
      icon: <PartnerCompanyIcon selected={['/mycompany'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'My Company',
      link: '/mycompany',
      claim: 'MyCompany',
      visible: currentRole.roleCode === 'PPSU',
    },
    {
      icon: <ContractIcon selected={['/contracts'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Contracts',
      link: '/contracts',
      claim: 'Contracts',
      visible: currentRole.roleCode === 'PPSU',
    },
    {
      icon: <LetterIcon selected={['/letters'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Letters',
      link: '/letters',
      claim: 'Letters',
      visible: currentRole.roleCode === 'PPSU',
    },
    {
      icon: <SectionIcon selected={['/clause'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Clauses',
      link: '/clause',
      claim: 'Sections',
      visible: currentRole.roleCode === 'PPSU',
    },
    {
      icon: <WorkflowIcon selected={['/workflows'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Workflows',
      link: '/workflows',
      claim: 'Workflows',
      visible: currentRole.roleCode === 'PPSU',
    },
    {
      icon: <CourseIcon selected={['/courses'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Courses',
      link: '/courses',
      claim: 'Courses',
      visible: currentRole.roleCode === 'PPSU',
    },
    {
      icon: <HandbookIcon selected={['/handbooks'].includes(match.url) ? true : false} />,
      theme: '',
      title: 'Handbooks',
      link: '/handbooks',
      claim: 'Handbooks',
      visible: currentRole.roleCode === 'PPSU',
    },
  ];
  return (
    <div className='block lg:flex lg:flex-shrink-0 transition-all ease-in-out z-50 dark:bg-darkSecondary'>
      <div className='absolute top-6 left-0 block md:hidden transition-all'>
        <Button onClick={() => setNavShow(!navShow)} type={'primary'} className='block md:hidden'>
          <MenuOutlined
            aria-hidden='true'
            className={`transition-all duration-500 dark:text-lightPrimary ${
              navShow ? 'block' : 'hidden'
            }`}
          />
        </Button>
      </div>
      <div
        className={`flex flex-col md:ml-0 ${
          navShow ? 'ml-0 w-screen md:w-20 duration-500' : '-ml-52 md:ml-0 w-20 duration-300'
        } md:hover:w-64 transition-all ease-in-out`}
      >
        <div className='flex flex-col flex-1 overflow-y-auto'>
          <div className='flex-1 flex flex-col'>
            <Menu mode='inline' className={'border-0 nav'} selectedKeys={[`${match.url}`]}>
              {mustBeArray(IndexMenu).map(
                (item) =>
                  item.visible && (
                    <Menu.Item
                      key={`/${item && item.link}`}
                      className={cx('nav-list', item.title.replace(/\s+/g, '-').toLowerCase())}
                    >
                      {item && item.link ? (
                        <Link to={item.link} className='nav-item'>
                          <div className='flex flex-start items-center'>
                            <div className='w-8 flex justify-center mr-4'>{item && item.icon}</div>
                            <div>{item.title}</div>
                          </div>
                        </Link>
                      ) : (
                        <span>{item && item.icon}</span>
                      )}
                    </Menu.Item>
                  ),
              )}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
