import React from 'react';

import { Input, Modal, Typography } from 'antd';

const { Text } = Typography;

interface IConfirmBox {
  title: string;
  onOk: any;
  contentName?: string;
  okText?: string;
  cancelText?: string;
}

const ConfirmBox = ({
  title = 'Are you sure you want to delete?',
  onOk,
  contentName,
  okText = 'Delete',
  cancelText = 'Cancel',
}: IConfirmBox) => {
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = document.getElementById('confirm-delete');
    if (e.target.value && e.target.value.trim() === 'DELETE') {
      element?.classList.remove('btn-disable');
    } else {
      element?.classList.add('btn-disable');
    }
  };
  return Modal.confirm({
    title: 'Confirm',
    content: (
      <div className={''}>
        <p className={'mb-2'}>
          {title} &nbsp;
          {contentName && <b>`{contentName}` ?</b>}
        </p>
        <div>
          <Input placeholder='DELETE' className={'my-2'} onChange={(e) => onTextChange(e)} />
          <Text>
            Type <strong>DELETE</strong> to confirm
          </Text>
        </div>
      </div>
    ),
    okButtonProps: { className: 'ant-btn-danger btn-disable', id: 'confirm-delete' },
    okText: okText,
    cancelText: cancelText,
    autoFocusButton: 'cancel',
    cancelButtonProps: { style: { border: '1px' } },
    onOk: onOk,
  });
};

export default ConfirmBox;
