import React, { useEffect, useState } from 'react';

import { Card, List, Typography } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { Document, Page } from 'react-pdf';

import { ContractVersion } from '../../@types/contract';
import Loading from '../../common/Loading';
import { isNullUndefined, localeDate } from '../../helpers/util';
import { FullWidthWrapper } from '../Content/Styles/FullWidth';

interface IVersionHistory {
  content: any;
  listVersions: any;
  visible: boolean;
  previewVersionDocument: any;
  type?: string;
}

const VersionHistory = ({
  content,
  listVersions,
  visible,
  previewVersionDocument,
  type,
}: IVersionHistory) => {
  const [versions, setVersion] = useState<Array<ContractVersion>>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [filePreview, setFilePreview] = useState({ data: {} });
  const [fileLoading, setFileLoading] = useState(true);
  const [selectedVersion, setSelectedVersion] = useState<ContractVersion>(versions[0]);

  const changeVersion = (item: any) => {
    setSelectedVersion(item);
    setFileLoading(true);
    previewVersionDocument({ options: item, type }).then((pdf: any) => {
      setFileLoading(false);
      setFilePreview({ data: pdf });
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (!isNullUndefined(content)) {
        const result = await listVersions({ options: { assetID: content.assetID } });
        setVersion(result.data);
        changeVersion(_.last(result.data));
      }
    };
    fetchData();
  }, [content.assetID]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPageNumber(numPages);
  };

  return (
    <React.Fragment>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-8'>
          <FullWidthWrapper>
            <div style={{ height: 'calc(-300px + 100vh)' }} className={'overflow-y-auto'}>
              {fileLoading ? (
                <Loading position={'center'} />
              ) : (
                filePreview && (
                  <Document
                    file={filePreview}
                    className={'m-l-8'}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {[...Array(pageNumber)].map((e, i) => (
                      <Page
                        key={i}
                        pageNumber={i + 1}
                        width={1100}
                        className='flex flex-center text-center'
                      />
                    ))}
                  </Document>
                )
              )}
            </div>
          </FullWidthWrapper>
        </div>
        <div className='col-span-4'>
          <Card title={'Versions'} className={'mb-4'}>
            <List
              dataSource={versions}
              renderItem={(item) => (
                <List.Item
                  className={
                    item.versionNumber === (selectedVersion && selectedVersion.versionNumber)
                      ? 'active p-lr-16'
                      : 'p-lr-16'
                  }
                >
                  <button className={'bg-none b-0 p-0'} onClick={() => changeVersion(item)}>
                    <Typography.Text>
                      Version : {item.versionNumber === null ? 'Draft' : item.versionNumber}
                    </Typography.Text>
                  </button>
                  <span className={'float-right'}>
                    {moment(item.createdOn).format('DD/MM/YYYY')}
                  </span>
                </List.Item>
              )}
            />
          </Card>
          {selectedVersion && (
            <Card title={'Version Details'}>
              <div>
                <strong>Document Title</strong>
                <p>{selectedVersion.title}</p>
              </div>
              <div>
                <strong>Version Number</strong>
                <p>{selectedVersion.versionNumber || 'Draft'}</p>
              </div>
              <div>
                <strong>Created</strong>
                <p>{localeDate(selectedVersion.createdOn).format('DD/MM/YYYY hh:mm a')}</p>
              </div>
              {selectedVersion.publishedOn && (
                <div>
                  <strong>Published</strong>
                  <p>{localeDate(selectedVersion.publishedOn).format('DD/MM/YYYY hh:mm a')}</p>
                </div>
              )}
              {selectedVersion.retiredOn && (
                <div>
                  <strong>Retired</strong>
                  <p>{localeDate(selectedVersion.retiredOn).format('DD/MM/YYYY hh:mm a')}</p>
                </div>
              )}
              {selectedVersion.releaseNotes && (
                <div>
                  <strong>Release Note</strong>
                  <div dangerouslySetInnerHTML={{ __html: selectedVersion.releaseNotes }} />
                </div>
              )}
              {selectedVersion.retireNotes && (
                <div>
                  <strong>Release Note</strong>
                  <div dangerouslySetInnerHTML={{ __html: selectedVersion.retireNotes }} />
                </div>
              )}
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VersionHistory;
