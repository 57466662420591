import { ReactNode } from 'react';

import styled from 'styled-components';

import { BadgeV2Icon } from '../assets/icons/course';
import { colors } from '../constants/colors';

export const BadgeV2Styled = styled.div`
  border: 2px #e2e2e2 solid;
  border-radius: 6px;
`;

interface IBadgeV2 {
  statusType: string;
  children: ReactNode;
  className?: string;
}

const BadgeV2 = ({ statusType, children }: IBadgeV2) => {
  let badgeColor = colors.dawnGray;
  switch (statusType) {
    case 'warning':
      badgeColor = colors.dawnOrange;
      break;
    case 'success':
      badgeColor = colors.dawnGreen;
      break;
    case 'danger':
      badgeColor = colors.borderRed;
      break;
    case 'info':
      badgeColor = colors.dawnBlue;
      break;
    default:
      badgeColor = colors.dawnGray;
      break;
  }

  return (
    <BadgeV2Styled className='flex flex-row justify-start items-center ml-2 px-2'>
      <BadgeV2Icon height='8px' width='8px' color={badgeColor} />
      <span className='text-xs ml-1'>{children}</span>
    </BadgeV2Styled>
  );
};

export default BadgeV2;
