import { RematchDispatch } from '@rematch/core';
import _ from 'lodash';

import { Clause, ClauseDetail, ClauseParams, ClauseVariant } from '../@types/clause';
import { JSONQuestionModel, QueryFilter } from '../@types/global';
import { move, mustBeArray } from '../helpers/util';
import ClauseService from '../services/ClauseService';

type State = {
  clauses: ReadonlyArray<Clause>;
  clause: ClauseDetail;
  variants: Array<ClauseVariant>;
  variant: ClauseVariant;
  questions: Array<JSONQuestionModel>;
  total: number;
};

const model = {
  state: {} as State,
  reducers: {
    loadList: (state: State, payload: { rows: ReadonlyArray<Clause>; total: number }) => {
      return { ...state, clauses: payload.rows, total: payload.total };
    },
    detail: (state: State, payload: ClauseDetail) => {
      return { ...state, clause: payload, questions: JSON.parse(payload.questions || '[]') };
    },
    remove: (state: State, payload: number) => {
      return { ...state, clauses: _.filter(state.clauses, (clause) => clause.id !== payload) };
    },
    update: (state: State, payload: any) => {
      return { ...state, clause: { ...state.clause, ...payload } };
    },
    variants: (state: State, payload: { rows: Array<ClauseVariant>; total: number }) => {
      return { ...state, variants: payload.rows };
    },
    variant: (state: State, payload: ClauseVariant) => {
      return { ...state, variant: payload };
    },
    update_variant: (state: State, payload: any) => {
      return {
        ...state,
        variants: mustBeArray(state.variants).map((variant) => {
          if (variant.id === payload.id) {
            return { ...variant, ...payload };
          }
          return variant;
        }),
      };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getClauses(params: QueryFilter) {
      dispatch.ui.start();
      const response = await ClauseService.sectionHandler({ action: 'list', queryOptions: params });
      dispatch.ui.stop();
      dispatch.clause.loadList(response.data.result);
    },
    async getClause({ options }: { options: ClauseParams }) {
      dispatch.ui.start();
      const response = await ClauseService.sectionHandler({ action: 'get', id: options.clauseID });
      dispatch.ui.stop();
      return dispatch.clause.detail(response.data.result);
    },
    async createClause({ payLoad }: any) {
      return ClauseService.sectionHandler({ action: 'create', payLoad });
    },
    async deleteClause({ options }: { options: ClauseParams }) {
      await ClauseService.sectionHandler({ action: 'delete', id: options.clauseID });
      dispatch.clause.remove(options.clauseID);
    },
    async getClauseVariants({ options }: { options: ClauseParams }) {
      const response = await ClauseService.sectionParagraphHandler({ action: 'list', options });
      dispatch.clause.variants(response.data.result);
    },
    async createClauseVariant({ options, payLoad }: any) {
      return ClauseService.sectionParagraphHandler({ action: 'create', options, payLoad });
    },
    async updateClause({ options, payLoad }: { options: ClauseParams; payLoad: any }) {
      const response = await ClauseService.sectionHandler({
        action: 'update',
        id: options.clauseID,
        payLoad,
      });
      if (response.status === 204) {
        dispatch.clause.update(payLoad);
      }
      return response;
    },
    async getParagraph({ options }: { options: ClauseParams }) {
      const response = await ClauseService.sectionParagraphHandler({
        action: 'get',
        options,
        id: options.paragraphID,
      });
      dispatch.clause.variant(response.data.result);
    },
    async createParagraph({ options, payLoad }: any) {
      return ClauseService.sectionParagraphHandler({ action: 'create', options, payLoad });
    },
    async deleteParagraph({ options }: { options: ClauseParams }) {
      return ClauseService.sectionParagraphHandler({
        action: 'delete',
        options,
        id: options.paragraphID,
      });
    },
    async duplicateParagraph({ options }: { options: ClauseParams }) {
      return ClauseService.sectionParagraphHandler({ action: 'duplicate', options });
    },
    async updateClauseParagraph({ options, payLoad }: { options: ClauseParams; payLoad: any }) {
      const response = await ClauseService.sectionParagraphHandler({
        action: 'update',
        options,
        id: options.paragraphID,
        payLoad,
      });
      if (response.status === 204) {
        dispatch.clause.update_variant({ id: options.paragraphID, ...payLoad });
      }
      return response;
    },
    async updateClauseParagraphSort({
      source,
      destination,
      clauseID,
      list,
    }: {
      source: any;
      destination: any;
      clauseID: number;
      list: Array<ClauseVariant>;
    }) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      dispatch.clause.variants({ rows: cloneList, total: cloneList.length });
      return ClauseService.dragSectionParagraph({ clauseID, list: cloneList });
    },
    async checkHasVariantRelatedContracts(clauseID: number) {
      dispatch.ui.start();
      const response = await ClauseService.getVariantRelatedContracts(clauseID);
      dispatch.ui.stop();
      return response.data.hasRelatedContracts;
    },
  }),
};

export default model;
