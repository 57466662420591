import { ActionHandler } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';
import { delay } from '../helpers/util';

class PartnerService {
  public partnerActionHandler = ({
    id,
    action,
    payLoad = {},
    options = {},
    queryOptions = {},
  }: ActionHandler) => {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'PARTNER_URL', options });
    switch (action) {
      case 'list':
        return http.get(url, queryOptions);
      case 'get':
        return http.get(`${url}/${id}`);
      case 'post':
        return http.post(url, payLoad);
      case 'patch':
        return http.patch(`${url}/${id}`, payLoad);
      default:
        return delay(100);
    }
  };

  public partnerPreference = ({ action, payLoad = {}, options = {} }: ActionHandler) => {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'MEMBER_PREFERENCE', options });
    switch (action) {
      case 'get':
        return http.get(url, options);
      case 'post':
        return http.post(url, payLoad);
      default:
        return delay(10);
    }
  };

  public partnerUserActionHandler = ({
    id,
    action,
    payLoad = {},
    options = {},
    queryOptions = {},
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'list':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'PARTNER_USER_URL', options });
        return http.get(url, queryOptions);
      case 'patch':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'PARTNER_USER' });
        return http.patch(`${url}/${id}`, payLoad);
      case 'activate':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'PARTNER_USER_ACTIVATE_URL', options });
        return http.patch(url, payLoad);
      case 'invitation':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'PARTNER_INVITATION_URL', options });
        return http.get(url);
      case 'post':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'PARTNER_USER' });
        return http.post(url, payLoad);
      default:
        return delay(100);
    }
  };

  public partnerClientHandler = ({
    id,
    action,
    payLoad = {},
    options = {},
    queryOptions = {},
  }: ActionHandler) => {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CLIENT_URL', options });
    switch (action) {
      case 'list':
        return http.get(url, queryOptions);
      case 'get':
        return http.get(`${url}/${id}`);
      case 'post':
        return http.post(url, payLoad);
      case 'patch':
        return http.patch(`${url}/${id}`, payLoad);
      default:
        return delay(100);
    }
  };
}

const instance = new PartnerService();
Object.freeze(instance);

export default instance;
