import React, { FC } from 'react';

import { BellIcon, ChatIcon, CogIcon } from '@heroicons/react/outline';
import { Avatar, Popover } from 'antd';
import { nanoid } from 'nanoid';

import UserSettings from './UserSettings';
import { IPartnerCompanyUser, User } from '../../@types/user';
import Loading from '../../common/Loading';
import GlobalCreate from '../../containers/Header/Create';
import stateStorage from '../../helpers/stateStorage';
import { getPartnerCompany, isNullUndefined } from '../../helpers/util';
import { authService } from '../../services/AuthService';

type HeaderProps = {
  current: User;
};

const headerIcons = [
  { name: 'Support', href: '#', icon: ChatIcon },
  { name: 'Notification', href: '#', icon: BellIcon },
  { name: 'Settings', href: '#', icon: CogIcon },
];

const HeaderAvatar: FC<HeaderProps> = ({ current }: HeaderProps) => {
  const handlePersonaSwitch = (persona: IPartnerCompanyUser) => {
    stateStorage.setValue('a-persona', persona);
    window.location.reload();
  };
  const activePartner = getPartnerCompany(current);

  const handleSignOut = () => {
    return authService.logout();
  };

  if (isNullUndefined(current)) return <Loading />;

  return (
    <div className='flex items-center justify-end'>
      <div className='flex'>
        <a
          key={nanoid()}
          className='flex items-center p-2 border-0 hover:border-0 rounded-lg text-black hover:bg-gray-200 hover:text-blue-500'
        >
          <GlobalCreate />
        </a>
        {headerIcons.map((item) => (
          <a
            key={nanoid()}
            href={item.href}
            className='flex items-center p-2 border-0 hover:border-0 rounded-lg text-black hover:bg-gray-200 hover:text-blue-500'
          >
            <item.icon className='h-6 w-6' aria-hidden='true' />
            <span className='sr-only'>{item.name}</span>
          </a>
        ))}
      </div>
      <Popover
        placement='rightBottom'
        content={
          <UserSettings
            useAvatar={`${current.givenName && current.givenName[0]}${
              current.lastName && current.lastName[0]
            }`}
            activePartner={activePartner}
            partnerCompanyUsers={current.partnerCompanyUsers}
            userName={`${current.givenName} ${current.lastName}`}
            handlePersonaSwitch={handlePersonaSwitch}
            handleSignOut={handleSignOut}
          />
        }
        trigger='click'
        overlayClassName='ant-popover-rightBottom'
      >
        <Avatar className='flex align-center cursor-pointer' size={40}>
          <span className={'f-s-14 f-w-600 text-black'} style={{ textTransform: 'uppercase' }}>
            {`${current.givenName && current.givenName[0]}${
              current.lastName && current.lastName[0]
            }`}
          </span>
        </Avatar>
      </Popover>
    </div>
  );
};

export default HeaderAvatar;
