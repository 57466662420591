import React from 'react';

import { Avatar, Divider } from 'antd';
import classNames from 'classnames';

import { IPartnerCompanyUser } from '../../@types/user';
import { mustBeArray } from '../../helpers/util';

interface IUserSettings {
  userName: string;
  useAvatar: string;
  handleSignOut: Function;
  partnerCompanyUsers: Array<IPartnerCompanyUser>;
  activePartner: IPartnerCompanyUser;
  handlePersonaSwitch: Function;
}

const UserSettings = ({
  userName,
  useAvatar,
  handleSignOut,
  partnerCompanyUsers,
  activePartner,
  handlePersonaSwitch,
}: IUserSettings) => {
  return (
    <React.Fragment>
      <div className='p-tb-12 p-lr-16'>
        <div className='hand flex space-between'>
          <div className='flex items-center gap-2 pb-4'>
            <Avatar className='flex align-center hand' size={36}>
              <span
                className={'f-s-13 f-w-400'}
                style={{ textTransform: 'uppercase' }}
              >{`${useAvatar}`}</span>
            </Avatar>
            <div className='p-lr-10' style={{ lineHeight: '1.35' }}>
              <p className='m-0'>
                <b>{userName}</b>
              </p>
              <em>{activePartner.companyName}</em>
            </div>
          </div>
        </div>
      </div>

      <Divider className={'m-0'} />
      <div className='py-2'>
        <ul className='list-inside'>
          {mustBeArray(partnerCompanyUsers).map((partner) => (
            <li
              className={classNames(
                partner.partnerCompanyId && activePartner.partnerCompanyId && 'active',
                'py-2 cursor-pointer',
              )}
              onClick={() => handlePersonaSwitch(partner)}
              key={partner.partnerCompanyId}
            >
              <span>{partner.companyName}</span>
            </li>
          ))}
          <li className='py-2 cursor-pointer'>
            <span>Help Center</span>
          </li>
          <li className='py-2 cursor-pointer'>
            <span>Terms & Policies</span>
          </li>
        </ul>
      </div>
      <Divider className={'m-0'} />
      <div className='py-2 cursor-pointer' onClick={() => handleSignOut()}>
        <span>Logout</span>
      </div>
    </React.Fragment>
  );
};

export default UserSettings;
