class DocumentHelper {
  private static instance: DocumentHelper;
  private _activeDocument?: any;

  constructor() {
    if (!DocumentHelper.instance) {
      DocumentHelper.instance = this;
    }
    this._activeDocument = [];
    return DocumentHelper.instance;
  }

  set(obj: any) {
    this._activeDocument[0] = obj;
  }

  get() {
    return this._activeDocument[0];
  }
}

const instance = new DocumentHelper();
Object.freeze(instance);

export default instance;
