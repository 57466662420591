import { RematchDispatch } from '@rematch/core';
import _ from 'lodash';

import { ClauseVariant } from '../@types/clause';
import {
  Contract,
  ContractClause,
  ContractDetail,
  ContractParam,
  ContractType,
} from '../@types/contract';
import { FileParam, JSONQuestionModel, QueryFilter } from '../@types/global';
import { platformViewTypes } from '../common/constants/entities';
import stateStorage from '../helpers/stateStorage';
import { move, mustBeArray } from '../helpers/util';
import ContractService from '../services/ContractService';
import DocumentService from '../services/DocumentService';

type State = {
  contracts: ReadonlyArray<Contract>;
  contract: ContractDetail;
  contractTypes: ReadonlyArray<ContractType>;
  questions: Array<JSONQuestionModel>;
  clauses: Array<ContractClause>;
  total: number;
};

const model = {
  state: {} as State,
  reducers: {
    loadList: (
      state: State,
      payload: { total: number; rows: ReadonlyArray<Contract>; page: number },
    ) => {
      const pageView =
        (stateStorage.getValue('ContractPageView') || {}).view || platformViewTypes['Grid'];
      return pageView === platformViewTypes['Table'] || payload.page === 1
        ? {
            ...state,
            contracts: payload.rows,
            total: payload.total,
          }
        : {
            ...state,
            contracts: [...mustBeArray(state.contracts), ...payload.rows],
            total: payload.total,
          };
    },
    detail: (state: State, payload: ContractDetail) => {
      return { ...state, contract: payload, questions: JSON.parse(payload.questions || '[]') };
    },
    updateQuestions: (state: State, payload: any) => {
      return { ...state, questions: payload };
    },
    contractTypes: (state: State, payload: { rows: ReadonlyArray<ContractType> }) => {
      return { ...state, contractTypes: payload.rows };
    },
    loadClauses: (state: State, payload: Array<ContractClause>) => {
      return { ...state, clauses: payload };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getContracts(params: QueryFilter) {
      dispatch.ui.start();
      const response = await ContractService.listContract(params);
      dispatch.ui.stop();
      dispatch.contract.loadList({ ...response.data, page: params.page });
    },
    async getContract(params: { options: ContractParam }) {
      dispatch.ui.start();
      const response = await ContractService.getContract({ options: params.options });
      dispatch.ui.stop();
      return dispatch.contract.detail(response.data.result);
    },
    async getContractSfdt(params: FileParam) {
      return DocumentService.getSfdtFile(params);
    },
    async getContractVersion(params: { options: ContractParam }) {
      return await ContractService.getVersionHistory({ options: params.options });
    },
    async getContractVersionPreview(params: { options: ContractParam }) {
      const response = await ContractService.previewVersion({ options: params.options });
      return new Response(response.data).arrayBuffer();
    },
    async listContractType(params: QueryFilter) {
      const response = await ContractService.listContractType(params);
      dispatch.contract.contractTypes(response.data.result);
    },
    async createContract({ payload }: { payload: any }) {
      return ContractService.createContract({ payload });
    },
    async updateContract({ options, payload }: { options: Contract; payload: any }) {
      return ContractService.updateContract({ options, payload });
    },
    async uploadContractDocument({ options, payload }: { options: Contract; payload: any }) {
      return ContractService.uploadContractDocument({ options, payload });
    },
    async publishContract({ options, payload }: { options: Contract; payload: any }) {
      return ContractService.publishContract({ options, payload });
    },
    async retireContract({ options, payload }: { options: Contract; payload: any }) {
      return ContractService.retireContract({ options, payload });
    },
    async draftContract({ options }: { options: Contract }) {
      return ContractService.draftContract({ options });
    },
    async deleteContract({ options }: { options: Contract; payload: any }) {
      return ContractService.deleteContract({ options });
    },
    async previewWithSections({ options, payload }: { options: Contract; payload: any }) {
      dispatch.ui.start();
      const response = await ContractService.previewWithSections({ options, payload });
      dispatch.ui.stop();
      return response;
    },
    async hideQuestions({ item, index }: { item: JSONQuestionModel; index: number }) {
      dispatch.contract.hide_question({ item, index });
    },
    async listContractClause({ options }: { options: Contract }) {
      const response = await ContractService.sectionContractHandler({ action: 'get', options });
      return dispatch.contract.loadClauses(response?.data?.result);
    },
    async addContractClause({ options }: { options: any }) {
      return ContractService.sectionContractHandler({
        action: 'post',
        options,
        id: options.clauseID,
      });
    },
    async removeContractClause({ options }: { options: any }) {
      return ContractService.sectionContractHandler({
        action: 'delete',
        options,
        id: options.clauseID,
      });
    },
    async updateContractClauseSort({
      source,
      destination,
      contractID,
      list,
    }: {
      source: any;
      destination: any;
      contractID: number;
      list: Array<ClauseVariant>;
    }) {
      if (source.index && destination.index) {
        const cloneList = move(_.clone(list), source.index, destination.index);
        dispatch.contract.loadClauses(cloneList);
        return ContractService.contractSectionsItemsDrag({ contractID, list: cloneList });
      }
    },
  }),
};

export default model;
