import { authService } from './AuthService';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';

class UserService {
  private authService: any;

  constructor() {
    this.authService = authService;
  }

  public getCurrentUser(): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CALLBACK' });
    return http.get(url).catch((error) => {
      if (error?.response?.status === 401) {
        return this.authService.login();
      }
      throw error;
    });
  }
}

const instance = new UserService();
Object.freeze(instance);

export default instance;
