import React from 'react';

import { Divider } from 'antd';
import _ from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';

import DocumentSettings from './Setting';
import VersionSettings from './VersionSetting';
import { ErrorFallback } from '../../common/Error/Fallback';

interface IPublishSetting {
  content: any;
  config: Array<any>;
  updateSettings: Function;
  actionHelpers?: Function;
  updateContentType?: Function;
  settings?: any;
  publisher?: string;
  type: 'contract' | 'letter';
  id: any;
  visible: boolean;
}

const PublishSetting = ({
  config,
  id,
  updateSettings,
  content,
  publisher,
  settings,
  visible,
  type,
}: IPublishSetting) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={'mb-2'}>
        <DocumentSettings
          config={config}
          visible={visible}
          id={id}
          updateSettings={updateSettings}
          content={content}
        />
      </div>

      <div className={'py-2'}>
        <Divider orientation='left' className={'my-2'}>
          {_.isNull(content.latestPublishedVersion) && content.statusId === 1 ? '' : 'Version'}
        </Divider>
        <VersionSettings
          mode={'publish'}
          releaseNotes={settings.releaseNotes}
          note={true}
          type={type}
          visible={!_.isNull(content.latestPublishedVersion) && visible}
          updateSettings={updateSettings}
          publisher={publisher}
          versionNumber={content.latestPublishedVersion}
        />
      </div>
    </ErrorBoundary>
  );
};

export default PublishSetting;
