import React, { useEffect } from 'react';

import { Input, Select } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';

import { CountryState } from '../../@types/global';
import { isNullUndefined, mustBeArray } from '../../helpers/util';
import { RootDispatch, RootState } from '../../store';
import { defaultCountry } from '../constants/application';

const Option = Select.Option;

type CountryLookupType = {
  lookupState: any;
  states: ReadonlyArray<CountryState>;
  disabled?: boolean;
  countryId?: number;
  value?: number;
  onChange?: any;
};

const StateLookup = ({
  lookupState,
  states,
  onChange,
  disabled,
  value,
  countryId,
}: CountryLookupType) => {
  useEffect(() => {
    lookupState({ countryId: isNullUndefined(countryId) ? defaultCountry : countryId });
  }, [countryId]); // eslint-disable-line react-hooks/exhaustive-deps

  const triggerChange = (v: any) => {
    onChange?.(v);
  };

  return !_.isEmpty(mustBeArray(states)) ? (
    <Select
      showSearch
      disabled={disabled}
      style={{ width: 200 }}
      placeholder='Select a State'
      value={value}
      onChange={(v) => triggerChange(v)}
    >
      {states.map((state, key) => (
        <Option className={'flow-root'} key={key} value={state.id}>
          {state.value}
        </Option>
      ))}
    </Select>
  ) : (
    <Input placeholder={'State'} value={value} onChange={(val) => triggerChange(val)} />
  );
};

const mapProps = (state: RootState) => ({
  states: state.lookup.states,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  lookupState: dispatch.lookup.lookupState,
});

export default connect(mapProps, mapDispatch)(StateLookup);
