import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber, Modal, Spin, Upload } from 'antd';

import {
  CourseSettingsWrapperStyled,
  DraggerInnerImageWrapperStyled,
  FormItemDescriptionStyled,
  InputStyled,
  TextAreaStyled,
  UploadWrapperStyled,
} from './CourseForm.styles';
import { CourseFormProps } from './CourseForm.types';
import FlowControls from './FlowControls';
import useCourseFormState from './hooks/useCourseFormState';
import { COURSE_TYPE, ENTITY_MODE } from '../../../@types/course';
import { UploadFileIcon } from '../../../common/assets';
import CountryLookUp from '../../../common/Search/CountryLookup';
import CourseCategoryLookup from '../../../common/Search/CourseCategory';
import CourseLevelLookup from '../../../common/Search/CourseLevel';
import { errorMessages } from '../../../helpers/messages';

const { Dragger } = Upload;

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const CourseForm = (props: CourseFormProps) => {
  const {
    title,
    subTitle,
    handleStepChange,
    handleModalCancel,
    handleCancel,
    steps,
    currentStep,
    replaceScormModalOpen,
    setReplaceScormModalOpen,
    courseImageUrlValue,
    mode,
    form,
    isLoading,
    initialValues,
    handleSubmit,
    getFormItemLabel,
    handleChange,
    handleCourseImageChange,
    courseType,
    currentCourse,
    handleScormCourseChange,
    isAdditionalAttemptsEnabled,
    handleCheckboxChange,
    getPartialLable,
    isCompletionDaysEnabled,
    handleUpdateBtnClick,
    fileUploadErrorModalOpen,
    setFileUploadErrorModalOpen,
    fileUploadModalError,
    setFileUploadModalError,
    handlePreview,
  } = useCourseFormState(props);

  return (
    <>
      <Spin indicator={loadingIcon} spinning={isLoading}>
        {mode === ENTITY_MODE.CREATE && (
          <FlowControls
            steps={steps}
            currentStep={currentStep}
            onStepChange={handleStepChange}
            onCancel={handleCancel}
          />
        )}
        <CourseSettingsWrapperStyled data-test-id={`course-settings-form--${mode.toLowerCase()}`}>
          {!!title && <p className='text-xl text-center mt-12 font-bold'>{title}</p>}
          {!!subTitle && <p className='text-center'>{subTitle}</p>}
          <div className='grid grid-cols-12 gap-4 mt-12'>
            <div className='col-start-2 col-span-10 2xl:col-start-3 2xl:col-span-8'>
              <Form
                form={form}
                layout='vertical'
                initialValues={initialValues}
                onFinish={handleSubmit}
                autoComplete='off'
                requiredMark='optional'
              >
                <Form.Item
                  label={getFormItemLabel('Give this Course a name')}
                  name='name'
                  required
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <InputStyled placeholder='e.g. Basic Safety Training' />
                </Form.Item>

                <Form.Item
                  label={getFormItemLabel('What type of Course is this')}
                  name='categoryID'
                  rules={[
                    {
                      required: courseType === COURSE_TYPE.UPLOAD || mode !== ENTITY_MODE.CREATE,
                      message: 'Course category is required',
                    },
                  ]}
                >
                  <CourseCategoryLookup
                    onChange={(value: number) => handleChange('categoryID', value)}
                  />
                </Form.Item>

                <Form.Item
                  label={getFormItemLabel('What level is this Course')}
                  name='levelID'
                  rules={[
                    {
                      required: courseType === COURSE_TYPE.UPLOAD || mode !== ENTITY_MODE.CREATE,
                      message: 'Course category is required',
                    },
                  ]}
                >
                  <CourseLevelLookup onChange={(value: number) => handleChange('levelID', value)} />
                </Form.Item>

                <Form.Item label={getFormItemLabel('Provide a summary for this course')}>
                  <FormItemDescriptionStyled>
                    This summary will be shown in the course library, and to the worker when they
                    start the course.
                  </FormItemDescriptionStyled>
                  <Form.Item noStyle name='description'>
                    <TextAreaStyled
                      rows={6}
                      placeholder='e.g. This course introduces workers to basic safety concepts'
                      onChange={(e) => handleChange('description', e.target.value)}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item hidden name='courseImageUrl'>
                  <Input type='hidden' />
                </Form.Item>
                <Form.Item
                  label={getFormItemLabel('Provide a thumbnail image for this Course')}
                  name='courseImage'
                >
                  <>
                    <FormItemDescriptionStyled>
                      This image will be shown in the course library, and to the worker when the
                      course is assigned.
                    </FormItemDescriptionStyled>
                    <Dragger
                      multiple={false}
                      accept='.jpg,.jpeg,.png'
                      onChange={handleCourseImageChange}
                      beforeUpload={() => false}
                      showUploadList={false}
                    >
                      <DraggerInnerImageWrapperStyled courseImageUrlValue={courseImageUrlValue}>
                        {!courseImageUrlValue && (
                          <>
                            <p className='ant-upload-drag-icon flex justify-center'>
                              <UploadFileIcon height='70px' width='70px' />
                            </p>
                            <p className='ant-upload-text'>
                              Drag and drop files, or{' '}
                              <span>
                                <a href='#'>Browse</a>
                              </span>
                            </p>
                            <p className='ant-upload-hint'>PNG, JPG or PDF (max. 5MB)</p>
                          </>
                        )}
                      </DraggerInnerImageWrapperStyled>
                    </Dragger>
                  </>
                </Form.Item>

                {(mode === ENTITY_MODE.UPDATE || mode === ENTITY_MODE.REVIEW) &&
                  !!currentCourse.isGlobalCourse && (
                    <Form.Item
                      name='countryId'
                      label={getFormItemLabel('Country')}
                      required
                      rules={[{ required: true, message: 'This field is required.' }]}
                    >
                      <CountryLookUp
                        mode='multiple'
                        onChange={(value: number[]) => handleChange('countryId', value)}
                      />
                    </Form.Item>
                  )}

                {courseType === COURSE_TYPE.UPLOAD && (
                  <>
                    <Form.Item
                      label={getFormItemLabel('Upload file')}
                      name='scormCourse'
                      className='ant-form-item-scorm'
                      required
                      rules={[{ required: true, message: 'This field is required.' }]}
                    >
                      <>
                        <FormItemDescriptionStyled>
                          This SCORM Course will be shown in the course library.
                        </FormItemDescriptionStyled>
                        <UploadWrapperStyled>
                          <Dragger
                            showUploadList={{
                              showRemoveIcon: false,
                            }}
                            maxCount={1}
                            multiple={false}
                            accept='.zip'
                            onChange={handleScormCourseChange}
                            beforeUpload={() => false}
                            listType='picture'
                            defaultFileList={
                              mode === ENTITY_MODE.UPDATE && !!initialValues?.scormCourse?.fileName
                                ? [
                                    {
                                      uid: '0',
                                      name: initialValues?.scormCourse?.fileName,
                                      status: 'done',
                                    },
                                  ]
                                : []
                            }
                          >
                            <>
                              <p className='ant-upload-drag-icon flex justify-center'>
                                <UploadFileIcon height='70px' width='70px' />
                              </p>
                              <p className='ant-upload-text'>
                                Drag and drop files, or{' '}
                                <span>
                                  <a href='#'>Browse</a>
                                </span>
                              </p>
                              <p className='ant-upload-hint'>ZIP (max. 100MB)</p>
                            </>
                          </Dragger>
                        </UploadWrapperStyled>
                      </>
                    </Form.Item>
                  </>
                )}
                <Form.Item label={getFormItemLabel('Completion rules')}>
                  <FormItemDescriptionStyled>
                    Specify when this course can be used and restrictions that will apply when
                    workers are enrolled in this course.
                    <br />
                    Courses used for Onboarding will automatically allow workers unlimited attempts
                    to complete.{' '}
                    <a
                      href='https://support.xemplo.com/hc/en-au/articles/900005344243-Building-a-course-using-the-Course-Builder'
                      target='_blank'
                      className='f-s-12'
                      rel='noreferrer'
                    >
                      Learn more
                    </a>
                  </FormItemDescriptionStyled>
                </Form.Item>
                <Form.Item name='canBeUsedForOnboarding'>
                  <Checkbox
                    defaultChecked={initialValues.canBeUsedForOnboarding}
                    onChange={(e) => handleChange('canBeUsedForOnboarding', e.target.checked)}
                  >
                    Can be used in Onboarding
                  </Checkbox>
                </Form.Item>

                {courseType === COURSE_TYPE.BYO && (
                  <Form.Item>
                    <Checkbox
                      name='isAdditionalAttemptsEnabled'
                      defaultChecked={isAdditionalAttemptsEnabled}
                      onChange={handleCheckboxChange}
                    >
                      {getPartialLable(
                        'Allow extra attempts to submit assessments if failed',
                        3,
                        'BeforeInput',
                      )}
                      <Form.Item
                        noStyle
                        name='additionalAttempts'
                        rules={[
                          {
                            type: 'number',
                            min: 0,
                            max: 9999,
                            message: errorMessages.courseAdditionalAttempts,
                          },
                        ]}
                      >
                        <InputNumber
                          className='mx-2'
                          controls={false}
                          disabled={!isAdditionalAttemptsEnabled}
                          onChange={(value: number | null) =>
                            handleChange('additionalAttempts', value)
                          }
                        />
                      </Form.Item>
                      {getPartialLable(
                        'Allow extra attempts to submit assessments if failed',
                        3,
                        'AfterInput',
                      )}
                    </Checkbox>
                  </Form.Item>
                )}

                <Form.Item>
                  <Checkbox
                    name='isCompletionDaysEnabled'
                    defaultChecked={isCompletionDaysEnabled}
                    onChange={handleCheckboxChange}
                  >
                    {getPartialLable(
                      'Must be completed within days of assignment',
                      5,
                      'BeforeInput',
                    )}
                    <Form.Item
                      noStyle
                      name='completionDays'
                      rules={[
                        {
                          type: 'number',
                          min: 1,
                          max: 9999,
                          message: errorMessages.courseCompletionDays,
                        },
                      ]}
                    >
                      <InputNumber
                        className='mx-2'
                        controls={false}
                        disabled={!isCompletionDaysEnabled}
                        onChange={(value: number | null) => handleChange('completionDays', value)}
                      />
                    </Form.Item>
                    {getPartialLable(
                      'Must be completed within days of assignment',
                      5,
                      'AfterInput',
                    )}
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  {mode === ENTITY_MODE.CREATE && (
                    <div className='flex justify-center'>
                      <div className='w-48 mt-6 mb-12'>
                        <Button block type='primary' htmlType='submit' disabled={isLoading}>
                          Create
                        </Button>
                      </div>
                    </div>
                  )}
                  {mode === ENTITY_MODE.UPDATE && courseType === COURSE_TYPE.BYO && (
                    <div className='flex justify-between gap-52 mt-6'>
                      <Button block onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button block type='primary' htmlType='submit' loading={isLoading}>
                        Save
                      </Button>
                    </div>
                  )}
                  {mode === ENTITY_MODE.UPDATE && courseType === COURSE_TYPE.UPLOAD && (
                    <div className='flex justify-between gap-52 mt-6'>
                      <Button block onClick={handlePreview}>
                        Preview
                      </Button>
                      <Button
                        block
                        type='primary'
                        htmlType='button'
                        loading={isLoading}
                        onClick={handleUpdateBtnClick}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Form.Item>
                {mode === ENTITY_MODE.REVIEW && courseType === COURSE_TYPE.BYO && (
                  <div className='flex justify-between gap-52 mt-6'>
                    <Button block onClick={handleModalCancel} disabled={false}>
                      Cancel
                    </Button>
                    <Button
                      block
                      type='primary'
                      htmlType='submit'
                      loading={isLoading}
                      disabled={false}
                    >
                      Publish
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </CourseSettingsWrapperStyled>
        <Modal
          centered
          title='Replace course file'
          open={replaceScormModalOpen}
          onOk={() => {
            setReplaceScormModalOpen(false);
            form.submit();
          }}
          onCancel={() => setReplaceScormModalOpen(false)}
        >
          <p>
            Replacing this course file will reset progress for all workers who haven&apos;t
            completed the course.
          </p>
        </Modal>
        <Modal
          centered
          title='Error'
          open={fileUploadErrorModalOpen}
          onCancel={() => {
            setFileUploadModalError('');
            setFileUploadErrorModalOpen(false);
          }}
          footer={[
            <Button
              key='ok'
              type='primary'
              onClick={() => {
                setFileUploadModalError('');
                setFileUploadErrorModalOpen(false);
              }}
            >
              Ok
            </Button>,
          ]}
        >
          <p>
            {fileUploadModalError}
            <br />
            Please try again!
          </p>
        </Modal>
      </Spin>
    </>
  );
};

export default CourseForm;
