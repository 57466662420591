import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import TopBarProgress from 'react-topbar-progress-indicator';

import { RootState } from '../store';

TopBarProgress.config({
  barColors: {
    '0': '#132bad',
    '0.5': '#0a3a8e',
    '1.0': '#000',
  },
  shadowBlur: 0,
  barThickness: 2,
});

type IProgressBar = {
  progressBarStatus: boolean;
};

const ProgressBar = ({ progressBarStatus }: IProgressBar) => {
  if (progressBarStatus) {
    return <TopBarProgress />;
  } else {
    return <Fragment></Fragment>;
  }
};

const mapStateToProps = (state: RootState) => {
  return {
    progressBarStatus: state.ui.pageLoading,
  };
};

const mapDispatch = () => ({});

export default connect(mapStateToProps, mapDispatch)(ProgressBar);
