import React, { FC, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal } from 'antd';
import _ from 'lodash';

import { ContractType } from '../../@types/contract';
import { User } from '../../@types/user';
import { defaultCountry } from '../../common/constants/application';
import message from '../../common/message';
import ContractTypeLookup from '../../common/Search/ContractTypeLookup';
import CountryLookup from '../../common/Search/CountryLookup';
import { getPartnerCompanyId } from '../../helpers/util';
import history from '../../routes/history';

interface ICreate {
  visible: boolean;
  current: User;
  contractTypes: ReadonlyArray<ContractType>;
  onCancel: Function;
  createContract: Function;
}

const Create: FC<ICreate> = ({ current, visible, onCancel, createContract, contractTypes }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeCountry, setActiveCountry] = useState(defaultCountry);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onFinish = (values: any) => {
    const foundContract = _.find(contractTypes, (ctl) => ctl.id === values.contractType) || {
      questions: '',
    };
    const payload = {
      contractTypeID: values.contractType,
      title: values.name,
      countryId: values.countryId,
      referenceNumber: values.refNumber,
      questions: foundContract.questions,
      partnerCompanyId: getPartnerCompanyId(current),
      createdBy: (current || {}).id,
    };
    setLoading(true);
    createContract({ payload }).then(
      (response: any) => {
        setLoading(false);
        onClose();
        const { result } = response.data;
        history.push(`/contract/${result.assetId}/version/${result.version}`);
      },
      (errors: Array<any>) => {
        message.error(errors);
        setLoading(false);
      },
    );
  };
  return (
    <Modal visible={visible} title={null} footer={null} width={1000} onCancel={() => onClose()}>
      <div className='grid grid-cols-2 gap-4 p-4'>
        <div className='relative'>
          <div>
            <p className={'font-bold font-lg'}>Contracts</p>
            <p className='my-4'>
              This tool will provide you with the ability to create an Expedo Contract Template. To
              start, please provide the details of the new Template using the fields provided.
            </p>
          </div>
          <Divider className={'absolute -right-8 top-8'} type='vertical' style={{ height: 270 }} />
        </div>
        <div className={'px-12'}>
          <div className={'flex flex-start mb-8 items-center'}>
            <span className={'ml-1'}>Create a contract</span>
          </div>
          <div className='flex'>
            <Form
              name='contractForm'
              className={'w-full'}
              layout='vertical'
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label='Contract name'
                name='name'
                rules={[{ required: true, message: 'Contract name is required' }]}
              >
                <Input className='form__input form__input--block' placeholder='Contract name' />
              </Form.Item>

              <Form.Item
                label='Reference No'
                name='refNumber'
                rules={[{ required: true, message: 'Reference No is required' }]}
              >
                <Input className='form__input form__input--block' placeholder='Reference No' />
              </Form.Item>

              <Form.Item
                label='Country'
                name='countryId'
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <CountryLookup onChange={(value: number) => setActiveCountry(value)} />
              </Form.Item>

              <Form.Item
                label='Type of Contract?'
                name='contractType'
                rules={[{ required: true, message: 'Contract type is required' }]}
              >
                <ContractTypeLookup countryId={activeCountry} />
              </Form.Item>

              <Form.Item>
                <div className='flex justify-between gap-4'>
                  <Button
                    className='btn btn--default'
                    block
                    size={'large'}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='btn btn--primary'
                    block
                    size={'large'}
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                  >
                    Next
                    <ArrowRightOutlined className={'float-right mt-1'} />
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Create;
