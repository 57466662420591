export const colors = {
  primary: '#0b78ff',
  danger: '#e6413d',
  warning: '#f3c200',
  success: '#47C144',
  defaultBackground: '#f6faff',
  borderOrange: '#FF7700',
  borderBlue: '#0B78FF',
  borderGreen: '#53D100',
  borderRed: '#E85656',
  neutral: '#555',
  white: '#FFF',
  lightGray: '#ddd',
  lighterGray: '#f7f7f7',
  dawnBlue: '#0B78FF',
  dawnPurple: '#875BF7',
  dawnRed: '#F04438',
  dawnOrange: '#F79009',
  dawnGreen: '#15B79E',
  dawnGray: '#5D6B98',
};
