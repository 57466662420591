import { ContractParam } from '../@types/contract';
import { QueryFilter } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';
import { buildQueryParams, isNullUndefined } from '../helpers/util';

class LetterService {
  public listLetter(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'LETTER_LIST' });
    return http.get(url + '?' + buildQueryParams(params)).catch((error) => {
      throw error;
    });
  }

  public getLetter({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'LETTER_DETAIL',
      options: {
        ...options,
        versionNumber: isNullUndefined(options.versionNumber) ? '' : options.versionNumber,
      },
    });
    return http.get(url);
  }

  public getVersionHistory({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'LETTER_VERSION', options });
    return http.get(url);
  }

  public previewVersion({ options, payLoad = {} }: any): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'LETTER_PREVIEW_VERSION_URL', options });
    return http.getPreviewFIle(url, payLoad);
  }

  public previewWithMergeFields({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'PREVIEW_URL',
      options: {
        ...options,
        versionNumber: isNullUndefined(options.versionNumber) ? '' : options.versionNumber,
      },
    });
    return http.postPreviewFIle(url, payload);
  }

  public listLetterCategory(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'LETTER_CATEGORY' });
    return http.get(url, params);
  }

  public createLetter({ payload }: { payload: any }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CREATE_LETTER' });
    return http.post(url, payload);
  }

  public updateLetter({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'UPDATE_LETTER', options });
    return http.patch(url, payload);
  }

  public deleteLetter({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'UPDATE_LETTER', options });
    return http.del(url);
  }

  public publishLetter({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'LETTER_PUBLISH', options });
    return http.put(url, payload);
  }

  public retireLetter({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'LETTER_RETIRE', options });
    return http.put(url, payload);
  }

  public draftLetter({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'LETTER_DRAFT', options });
    return http.post(url, {});
  }

  public uploadLetterDocument({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'UPLOAD_LETTER_FILE', options });
    return http.postFile(url, payload);
  }
}

const instance = new LetterService();
Object.freeze(instance);

export default instance;
