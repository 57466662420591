import React from 'react';

import { Route, Switch } from 'react-router-dom';

import routes from './routes';
import { LoginActions, LogoutActions } from '../common/constants/auth';
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import PrivateRoute from '../routes/PrivateRoute';

const Index = () => (
  <Switch>
    {routes.map((view) => {
      return (
        <Route
          exact
          key={view.id}
          path={view.path}
          render={(props) => (
            <PrivateRoute {...props}>
              <view.layout {...props}>
                <view.page></view.page>
              </view.layout>
            </PrivateRoute>
          )}
        ></Route>
      );
    })}
    <Route path='/login' render={(props) => <Login action={LoginActions.Login} {...props} />} />

    <Route
      path='/confirm-login'
      render={(props) => <Login action={LoginActions.LoginCallback} {...props} />}
    />

    <Route path='/logout' render={(props) => <Logout action={LogoutActions.Logout} {...props} />} />

    <Route
      path='/confirm-logout'
      render={(props) => <Logout action={LogoutActions.LogoutCallback} {...props} />}
    />
  </Switch>
);

export default Index;
