import React, { FC, useEffect, useState } from 'react';

import { CustomToolbarItemModel, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { JSONQuestionItem, JSONQuestionModel } from '../../../../@types/global';
import {
  WorkflowDialogStep,
  WorkflowDocumentStep,
  WorkflowEvaluationStep,
  WorkflowLetterStep,
  WorkflowQuestionStep,
} from '../../../../@types/workflow';
import { workflowDefaultMergeFields } from '../../../../common/constants/entities';
import Loading from '../../../../common/Loading';
import DocumentHelper from '../../../../helpers/DocumentHelper';
import { delay, mapWorkflowStepType } from '../../../../helpers/util';
import history from '../../../../routes/history';
import { RootDispatch, RootState } from '../../../../store';
import DocumentEditor from '../../../Document';

interface IWorkflowDocument {
  steps: ReadonlyArray<
    | WorkflowDialogStep
    | WorkflowLetterStep
    | WorkflowDocumentStep
    | WorkflowQuestionStep
    | WorkflowEvaluationStep
  >;
  getSfdt: Function;
  getWorkflow: Function;
  uploadDocument: Function;
  getWorkflowStep: Function;
}

const WorkflowDocument: FC<IWorkflowDocument> = ({
  getSfdt,
  getWorkflow,
  getWorkflowStep,
  uploadDocument,
}) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({});
  const [questions, setWorkflowQuestions] = useState<JSONQuestionModel[]>([]);
  const { id, stepId, fileId } = useParams<any>();

  useEffect(() => {
    setLoading(true);
    getWorkflow({ options: { id } }).then((response: any) => {
      const { steps } = response.payload;
      let stepQuestions: Array<JSONQuestionItem> = [];
      const questionSteps = _.filter(
        steps,
        (step) => step.stepContentTypeID === mapWorkflowStepType('question'),
      );
      _.each(questionSteps, (step: any) => {
        if (!_.isEmpty(step.questions)) {
          const filterMergeFieldQuestions = _.map(
            _.filter(step.questions, (q) => q.mergeField) || [],
            (qm) => ({ ...qm, label: qm.questionTitle, name: qm.mergeField, hidden: false }),
          );
          stepQuestions = [...stepQuestions, ...filterMergeFieldQuestions];
        }
      });
      setWorkflowQuestions([{ hidden: false, questions: stepQuestions }]);
      setContent({ ...content, questions: stepQuestions });
      getWorkflowStep({ options: { id, stepID: stepId } }).then((r: any) => {
        const { result } = r.data;
        setContent({ ...content, ...result, fileID: result.fileId });
        delay(2000).then(() => setLoading(false));
      });
    });
  }, []);

  const saveDocument = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
      uploadDocument({
        options: { id: fileId },
        payload: { file: doc, name: 'Untitled', questions: {} },
      }).then(() => {
        history.push(`/workflow/${id}`);
      });
    });
  };

  const onToolbarClick = (args: any): void => {
    switch (args.item.id) {
      case 'Custom':
        saveDocument();
        break;
      default:
        break;
    }
  };
  if (loading) return <Loading position={'center'} />;
  const toolItem: CustomToolbarItemModel = {
    prefixIcon: 'e-icons e-save',
    tooltipText: 'Save',
    text: 'Save',
    id: 'Custom',
  };
  console.log(questions, 'question...');
  return (
    <>
      <DocumentEditor
        content={content}
        getSfdt={getSfdt}
        isReadOnly={false}
        questions={questions}
        onToolbarClick={onToolbarClick.bind(this)}
        toolbarItems={[
          'New',
          'Open',
          toolItem,
          'Undo',
          'Redo',
          'Image',
          'Table',
          'Hyperlink',
          'Bookmark',
          'TableOfContents',
          'Header',
          'Footer',
          'PageSetup',
          'PageNumber',
          'InsertFootnote',
          'InsertEndnote',
          'Find',
        ]}
        mergeFields={workflowDefaultMergeFields}
      />
    </>
  );
};

const mapProps = (state: RootState) => ({
  steps: state.workflow.steps,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSfdt: dispatch.letter.getLetterSfdt,
  getWorkflow: dispatch.workflow.getWorkflow,
  getWorkflowStep: dispatch.workflow.getWorkflowStep,
  uploadDocument: dispatch.letter.uploadLetterDocument,
});

export default connect(mapProps, mapDispatch)(WorkflowDocument);
