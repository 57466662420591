import { connect } from 'react-redux';

import ClientForm from '../../components/Partners/ClientForm';
import { RootDispatch, RootState } from '../../store';

const mapProps = (state: RootState) => ({
  current: state.users.current,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPartnerClient: dispatch.partners.getPartnerClient,
  createPartnerClient: dispatch.partners.createPartnerClient,
  updatePartnerClient: dispatch.partners.updatePartnerClient,
  listPartnerClients: dispatch.partners.listPartnerClients,
});

export default connect(mapProps, mapDispatch)(ClientForm);
