import BadgeV2 from '../../common/BadgeV2';

interface IPageTitle {
  pageTitle?: string;
  badge?: any;
}

const PageTitle = ({ pageTitle, badge }: IPageTitle) => {
  return (
    <>
      <h1 className='text-xl ml-1.5'>
        <div className='flex flex-row'>
          {pageTitle}
          {badge && (
            <BadgeV2 className={'ml-5'} statusType={badge.statusType}>
              {badge.statusName}
            </BadgeV2>
          )}
        </div>
      </h1>
    </>
  );
};

export default PageTitle;
