import React from 'react';

import { Button, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { ErrorBoundary } from 'react-error-boundary';

import { LgEllipsis } from './assets';
import { ErrorFallback } from './Error/Fallback';
import { mustBeArray } from '../helpers/util';

interface IHeaderActionProp {
  handleActionClick: any;
  actions: Array<any>;
}

const HeaderActions = ({ actions, handleActionClick }: IHeaderActionProp) => {
  const limit = 3;
  const mainActions = _.take(_.clone(actions), limit);
  const subActions = _.clone(actions).splice(limit, actions.length);
  const menu = (
    <Menu>
      {_.map(mustBeArray(subActions), (action: any) => (
        <Menu.Item
          key={nanoid()}
          onClick={() => handleActionClick(action.name)}
          className={
            'text-gray-900 dark:text-gray-700 block pl-4 pr-12 py-2 text-sm flex items-center'
          }
        >
          {action.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {mainActions &&
        !_.isEmpty(mainActions) &&
        _.map(mustBeArray(mainActions), (action: any) => {
          switch (action.style) {
            case 'primary':
              return (
                <button
                  className={'mr-2 btn btn--primary inline-flex items-center text-sm font-normal'}
                  data-tut={`reactour-${action.name.replace(' ', '_')}`}
                  key={nanoid()}
                  onClick={() => handleActionClick(action.name)}
                  hidden={!action.visible}
                >
                  {action.name}
                </button>
              );
            case 'danger':
              return (
                <button
                  key={nanoid()}
                  className={'mr-2 btn btn--danger inline-flex items-center text-sm font-normal'}
                  data-tut={`reactour-${action.name.replace(' ', '_')}`}
                  onClick={() => handleActionClick(action.name)}
                >
                  {action.name}
                </button>
              );
            case 'success':
              return (
                <button
                  key={nanoid()}
                  className={'mr-2 btn--success inline-flex items-center text-sm font-normal'}
                  data-tut={`reactour-${action.name.replace(' ', '_')}`}
                  onClick={() => handleActionClick(action.name)}
                >
                  {action.name}
                </button>
              );
            default:
              return (
                <button
                  key={nanoid()}
                  className={'mr-2 btn btn--default inline-flex items-center text-sm font-normal'}
                  data-tut={`reactour-${action.name.replace(' ', '_')}`}
                  onClick={() => handleActionClick(action.name)}
                >
                  {action.name}
                </button>
              );
          }
        })}
      {subActions && !_.isEmpty(subActions) && (
        <Dropdown overlay={menu}>
          <Button className='btn-action' data-tut='reactour-more' type={'text'}>
            <LgEllipsis />
          </Button>
        </Dropdown>
      )}
    </ErrorBoundary>
  );
};

export default HeaderActions;
