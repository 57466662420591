import { RematchDispatch } from '@rematch/core';
import _ from 'lodash';

import { QueryFilter } from '../@types/global';
import {
  ClientUser,
  Partner,
  PartnerClient,
  PartnerClientDetail,
  PartnerDetail,
} from '../@types/partners';
import PartnerService from '../services/PartnerService';

type State = {
  partners: ReadonlyArray<Partner>;
  clients: ReadonlyArray<PartnerClient>;
  clientUsers: ReadonlyArray<ClientUser>;
  clientDetail: PartnerClientDetail;
  partner: PartnerDetail;
  total: number;
  clientUserTotal: number;
  clientTotal: number;
};
const model = {
  state: {} as State,
  reducers: {
    loadList: (state: State, payload: { total: number; rows: Array<Partner> }) => {
      return { ...state, total: payload.total, partners: payload.rows };
    },
    loadClients: (state: State, payload: { total: number; rows: Array<PartnerClient> }) => {
      return { ...state, clientTotal: payload.total, clients: payload.rows };
    },
    listUsers: (state: State, payload: { total: number; rows: Array<ClientUser> }) => {
      return { ...state, clientUserTotal: payload.total, clientUsers: payload.rows };
    },
    detail: (state: State, payload: PartnerDetail) => {
      return { ...state, partner: payload };
    },
    update: (state: State, payload: any) => {
      return {
        ...state,
        partners: _.map(state.partners, (partner) => {
          if (partner.id === payload.id) {
            return { ...partner, ...payload };
          }
          return partner;
        }),
      };
    },
    updateClient: (state: State, payload: any) => {
      return {
        ...state,
        clients: _.map(state.clients, (client) => {
          if (client.id === payload.id) {
            return { ...client, ...payload };
          }
          return client;
        }),
      };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async listPartners(params: QueryFilter) {
      const response: any = await PartnerService.partnerActionHandler({
        action: 'list',
        queryOptions: params,
      });
      dispatch.partners.loadList(response?.data.result);
    },
    async updatePartner({ options, payLoad }: any) {
      dispatch.partners.update({ ...payLoad, id: options.id });
      return PartnerService.partnerActionHandler({ id: options.id, action: 'patch', payLoad });
    },
    async createPartner({ payLoad }: any) {
      return PartnerService.partnerActionHandler({ action: 'post', payLoad });
    },
    async getPartner({ options }: any) {
      const response: any = await PartnerService.partnerActionHandler({
        action: 'get',
        id: options.id,
      });
      dispatch.partners.detail(response?.data?.result);
    },
    async listPartnerClients(params: QueryFilter) {
      const response: any = await PartnerService.partnerClientHandler({
        action: 'list',
        queryOptions: params,
      });
      dispatch.partners.loadClients(response.data);
    },
    async getPartnerClient({ options }: { options: any }) {
      return PartnerService.partnerClientHandler({ id: options.clientID, action: 'get' });
    },
    async createPartnerClient({ payLoad }: { payLoad: any }) {
      return PartnerService.partnerClientHandler({ action: 'post', payLoad });
    },
    async updatePartnerClient({ options, payLoad }: any) {
      dispatch.partners.updateClient({ ...payLoad, id: options.clientID });
      return PartnerService.partnerClientHandler({
        id: options.clientID,
        action: 'patch',
        payLoad,
      });
    },
    async listClientUsers({ options, queryOptions }: any) {
      const response: any = await PartnerService.partnerUserActionHandler({
        action: 'list',
        queryOptions,
        options,
      });
      dispatch.partners.listUsers(response.data.result);
    },
    async updateClientUser({ options, payLoad }: any) {
      return PartnerService.partnerUserActionHandler({ action: 'patch', id: options.id, payLoad });
    },
    async activateClientUser({ options, payLoad }: any) {
      return PartnerService.partnerUserActionHandler({ action: 'activate', options, payLoad });
    },
    async saveClientUser({ payLoad }: any) {
      return PartnerService.partnerUserActionHandler({ action: 'post', payLoad });
    },
    async inviteClientUser({ options }: any) {
      return PartnerService.partnerUserActionHandler({ action: 'invitation', options });
    },
    async getPartnerPreference({ options }: any) {
      return PartnerService.partnerPreference({ action: 'get', options });
    },
    async setPartnerPreference({ payLoad }: any) {
      return PartnerService.partnerPreference({ action: 'post', payLoad });
    },
  }),
};

export default model;
