import React, { FC, useEffect, useState } from 'react';

import { Button, Checkbox, Form, Input, Switch } from 'antd';
import _ from 'lodash';

import { PartnerDetail } from '../../@types/partners';
import { defaultCountry, TEMP_CONTENT_ID } from '../../common/constants/application';
import message from '../../common/message';
import CountryLookup from '../../common/Search/CountryLookup';
import StateLookup from '../../common/Search/StateLookup';
import { errorMessages } from '../../helpers/messages';
import useStateCallback from '../../helpers/useStateCallback';
import { conditionalRender, mustBeArray } from '../../helpers/util';

interface IPartnerForm {
  onCancel: Function;
  partner: PartnerDetail;
  createPartner: Function;
  updatePartner: Function;
  getPartner: Function;
}

const PartnerForm: FC<IPartnerForm> = ({
  partner,
  onCancel,
  createPartner,
  updatePartner,
  getPartner,
}) => {
  const [form] = Form.useForm();
  const [isPublisher, updatePublisher] = useStateCallback(false);
  const [isAffiliate, updateAffiliate] = useStateCallback(false);
  const [isSameAddress, updateSameAddress] = useState(false);
  const [countryId, setCountry] = useState(defaultCountry);
  const [features, setFeature] = useStateCallback<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    _.each(mustBeArray(partner.partnerRoles), (role) => {
      if (Number(role) === 1) {
        updateAffiliate(true, () => form.setFieldsValue({ isAffiliate: true }));
      } else if (Number(role) === 2) {
        updatePublisher(true, () => form.setFieldsValue({ isPublisher: true }));
      }
    });
    const featureList: Array<number> = [];
    _.each(mustBeArray(partner.features), (feature) => {
      if (feature.code === 'CTR' && feature.value === 'Y') {
        featureList.push(1);
      } else if (feature.code === 'LTR' && feature.value === 'Y') {
        featureList.push(2);
      } else if (feature.code === 'CRS' && feature.value === 'Y') {
        featureList.push(3);
      } else if (feature.code === 'HND' && feature.value === 'Y') {
        featureList.push(4);
      } else if (feature.code === 'WRK' && feature.value === 'Y') {
        featureList.push(5);
      }
    });
    setFeature(featureList, () => form.setFieldsValue({ features: featureList }));
  }, [partner.id, partner.uuid]);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onFinish = (values: any) => {
    if (!values.isAffiliate && !values.isPublisher) {
      return message.error(errorMessages.missingRole);
    }
    const payLoad = {
      countryId: values.countryId,
      features: [
        {
          code: 'CTR',
          value: mustBeArray(values.features).includes(1) ? 'Y' : 'N',
        },
        {
          code: 'LTR',
          value: mustBeArray(values.features).includes(2) ? 'Y' : 'N',
        },
        {
          code: 'CRS',
          value: mustBeArray(values.features).includes(3) ? 'Y' : 'N',
        },
        {
          code: 'HND',
          value: mustBeArray(values.features).includes(4) ? 'Y' : 'N',
        },
        {
          code: 'WRK',
          value: mustBeArray(values.features).includes(5) ? 'Y' : 'N',
        },
      ],
      partnerRoles: conditionalRender(
        values.isAffiliate && values.isPublisher,
        [1, 2],
        conditionalRender(values.isAffiliate, [1], [2]),
      ),
      operationTimeZoneId: 46,
      isActive: values.isActive,
      name: values.name,
      businessNumber: values.businessNumber,
      registeredBusinessName: values.registeredBusinessName,
      postalAddressLine1: isSameAddress ? values.registeredAddressLine1 : values.postalAddressLine1,
      postalAddressLine2: isSameAddress ? values.registeredAddressLine2 : values.postalAddressLine2,
      postalPostalCode: isSameAddress ? values.registeredPostalCode : values.postalPostalCode,
      postalStateId: isSameAddress ? values.registeredStateId : values.postalStateId,
      postalSuburb: isSameAddress ? values.registeredSuburb : values.postalSuburb,
      registeredAddressLine1: values.registeredAddressLine1,
      registeredAddressLine2: values.registeredAddressLine2,
      registeredPostalCode: values.registeredPostalCode,
      registeredStateId: values.registeredStateId,
      registeredSuburb: values.registeredSuburb,
    };
    setLoading(true);

    if (partner.id === TEMP_CONTENT_ID) {
      createPartner({ payLoad }).then((response: any) => {
        setLoading(false);
        onCancel();
      });
    } else {
      updatePartner({ options: { id: partner.id }, payLoad }).then(() => {
        setLoading(false);
        getPartner({ options: { id: partner.id } });
        onCancel();
      });
    }
  };

  return (
    <div className='flex'>
      <Form
        name='clientForm'
        layout='vertical'
        className={'w-full'}
        form={form}
        initialValues={{ ...partner, isPublisher, isAffiliate, features }}
        onFinish={onFinish}
      >
        <div className={'flex flex-row'}>
          <Form.Item
            label='Affiliate'
            className={'mr-12'}
            name='isAffiliate'
            valuePropName='checked'
            rules={[{ required: false }]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label='Publisher'
            className={'mr-12'}
            name='isPublisher'
            valuePropName='checked'
            rules={[{ required: false }]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label='Active'
            className={'mr-12'}
            name='isActive'
            valuePropName='checked'
            rules={[{ required: false }]}
          >
            <Switch />
          </Form.Item>
        </div>

        <Form.Item label='Publishers can create' name='features' rules={[{ required: false }]}>
          <Checkbox.Group style={{ width: '100%' }}>
            <Checkbox value={1}>Contract</Checkbox>
            <Checkbox value={2}>Letter</Checkbox>
            <Checkbox value={3}>Course</Checkbox>
            <Checkbox value={4}>Handbook</Checkbox>
            <Checkbox value={5}>Workflow</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <div className={'grid grid-cols-12 gap-1'}>
          <div className='col-span-6'>
            <div className='flex flex-row'>
              <Form.Item
                label='Company Name'
                name='name'
                className={'mr-12'}
                rules={[{ required: true, message: 'Company name is required!' }]}
              >
                <Input placeholder='Company name' value={partner.name} />
              </Form.Item>
              <Form.Item
                label='Registered business name'
                name='registeredBusinessName'
                rules={[{ required: true, message: 'Registered business name is required!' }]}
              >
                <Input
                  placeholder='Registered business name'
                  value={partner.registeredBusinessName}
                />
              </Form.Item>
            </div>
            <div className={'mr-4'}>
              <p className={'font-medium mb-2'}>Business Address</p>
              <Form.Item
                label='Address 1'
                name='registeredAddressLine1'
                rules={[{ required: true, message: 'Business address is required!' }]}
              >
                <Input placeholder='Address 1' value={partner.registeredAddressLine1} />
              </Form.Item>
              <Form.Item
                label='Address 2'
                name='registeredAddressLine2'
                rules={[{ required: false }]}
              >
                <Input placeholder='Address 1' value={partner.registeredAddressLine2 || ''} />
              </Form.Item>
              <div className='flex flex-row justify-between mr-4'>
                <Form.Item
                  label='Suburb'
                  name='registeredSuburb'
                  rules={[{ required: true, message: 'Suburb is required!' }]}
                >
                  <Input placeholder='Suburb' value={partner.registeredSuburb} />
                </Form.Item>
                <Form.Item
                  label='State'
                  name='registeredStateId'
                  rules={[{ required: false, message: 'Business state is required' }]}
                >
                  <StateLookup value={partner.registeredStateId} countryId={countryId} />
                </Form.Item>
                <Form.Item
                  label='Postal Code'
                  name='registeredPostalCode'
                  rules={[{ required: true, message: 'Business postal code is required!' }]}
                >
                  <Input placeholder='Postal code' value={partner.registeredPostalCode} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className='col-span-6'>
            <div className='flex flex-row'>
              <Form.Item
                label='Business Number'
                name='businessNumber'
                className={'mr-12'}
                rules={[{ required: true, message: 'Company business number is required!' }]}
              >
                <Input placeholder='Business Number' value={partner.businessNumber} />
              </Form.Item>
              <Form.Item
                label='Country'
                name='countryId'
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <CountryLookup triggerUpdate={(countryID: number) => setCountry(countryID)} />
              </Form.Item>
            </div>
            <div className={'mr-4'}>
              <div className={'float-right'}>
                Same as business address &nbsp;
                <Switch checked={isSameAddress} onChange={updateSameAddress} />
              </div>
              <p className={'font-medium mb-2'}>Postal Address</p>
              {isSameAddress ? (
                <>
                  <Form.Item
                    label='Address 1'
                    name='registeredAddressLine1'
                    rules={[{ required: true, message: 'Business address is required!' }]}
                  >
                    <Input
                      placeholder='Address 1'
                      disabled={true}
                      value={partner.registeredAddressLine1}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Address 2'
                    name='registeredAddressLine2'
                    rules={[{ required: false }]}
                  >
                    <Input
                      placeholder='Address 1'
                      disabled={true}
                      value={partner.registeredAddressLine2 || ''}
                    />
                  </Form.Item>
                  <div className='flex flex-row justify-between mr-4'>
                    <Form.Item
                      label='Suburb'
                      name='registeredSuburb'
                      rules={[{ required: true, message: 'Suburb is required!' }]}
                    >
                      <Input
                        placeholder='Suburb'
                        disabled={true}
                        value={partner.registeredSuburb}
                      />
                    </Form.Item>
                    <Form.Item
                      label='State'
                      name='registeredStateId'
                      rules={[{ required: false, message: 'Business state is required' }]}
                    >
                      <StateLookup
                        disabled={true}
                        value={partner.registeredStateId}
                        countryId={countryId}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Postal Code'
                      name='registeredPostalCode'
                      rules={[{ required: true, message: 'Business postal code is required!' }]}
                    >
                      <Input
                        placeholder='Postal code'
                        disabled={true}
                        value={partner.registeredPostalCode}
                      />
                    </Form.Item>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item
                    label='Address 1'
                    name='postalAddressLine1'
                    rules={[{ required: true, message: 'Postal address is required!' }]}
                  >
                    <Input placeholder='Address 1' value={partner.postalAddressLine1} />
                  </Form.Item>
                  <Form.Item
                    label='Address 2'
                    name='postalAddressLine2'
                    rules={[{ required: false }]}
                  >
                    <Input placeholder='Address 1' value={partner.postalAddressLine2 || ''} />
                  </Form.Item>
                  <div className='flex flex-row justify-between'>
                    <Form.Item
                      label='Suburb'
                      name='postalSuburb'
                      rules={[{ required: true, message: 'Suburb is required!' }]}
                    >
                      <Input placeholder='Suburb' value={partner.postalSuburb} />
                    </Form.Item>
                    <Form.Item
                      label='State'
                      name='postalStateId'
                      rules={[{ required: false, message: 'Postal state is required' }]}
                    >
                      <StateLookup value={partner.postalStateId} countryId={countryId} />
                    </Form.Item>
                    <Form.Item
                      label='Postal Code'
                      name='postalPostalCode'
                      rules={[{ required: true, message: 'Postal code is required!' }]}
                    >
                      <Input placeholder='Postal code' value={partner.postalPostalCode} />
                    </Form.Item>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <Form.Item>
          <div className='flex justify-center'>
            <Button className={'mr-4'} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PartnerForm;
