import React, { useEffect } from 'react';

import { Popover, Select } from 'antd';
import { connect } from 'react-redux';

import { ContractType } from '../../@types/contract';
import { mustBeArray } from '../../helpers/util';
import { RootDispatch, RootState } from '../../store';

type ContractTypeProps = {
  listContractType: Function;
  contractTypes: ReadonlyArray<ContractType>;
  countryId?: number;
  disabled?: boolean;
  value?: number;
  placeholder?: string;
  onChange?: any;
};

const ContractTypeLookup = ({
  listContractType,
  contractTypes,
  onChange,
  disabled,
  value,
  countryId,
}: ContractTypeProps) => {
  useEffect(() => {
    listContractType({ countryId, per_page: -1 });
  }, [countryId]);

  const triggerChange = (v: any) => {
    onChange?.(v);
  };

  return (
    <>
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder={'Contract Type'}
        disabled={disabled}
        value={value}
        onChange={(v) => triggerChange(v)}
      >
        {contractTypes &&
          mustBeArray(contractTypes).map((item) => (
            <Select.Option key={item.id} value={item.id}>
              <Popover title={item.name} content={<React.Fragment />}>
                {item.name}
              </Popover>
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

const mapProps = (state: RootState) => ({
  contractTypes: state.contract.contractTypes,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  listContractType: dispatch.contract.listContractType,
});

export default connect(mapProps, mapDispatch)(ContractTypeLookup);
