// @ts-nocheck
import React from 'react';

import { ExclamationIcon, PencilIcon, PlayIcon, UploadIcon } from '@heroicons/react/solid';
import {
  Card,
  Checkbox,
  CheckboxChangeEvent,
  Input,
  InputNumber,
  message,
  Select,
  Switch,
  Upload,
} from 'antd';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { ErrorBoundary } from 'react-error-boundary';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { ErrorFallback } from '../../common/Error/Fallback';
import Loading from '../../common/Loading';
import RTEditor from '../../common/RTEditor';
import ContractTypeLookup from '../../common/Search/ContractTypeLookup';
import CountryLookUp from '../../common/Search/CountryLookup';
import CourseCategoryLookup from '../../common/Search/CourseCategory';
import CourseLevelLookup from '../../common/Search/CourseLevel';
import LetterCategoryLookup from '../../common/Search/LetterCategoryLookup';
import { errorMessages } from '../../helpers/messages';
import { delay, getBase64, isNullUndefined, mustBeArray } from '../../helpers/util';

interface IPublishSettingProps {
  content: any;
  config: Array<any>;
  updateSettings: Function;
  actionHelpers?: Function;
  updateContentType?: Function;
  reloadContent?: Function;
  initialValue?: any;
  status?: Array<any>;
  id: any;
  visible: boolean;
}

interface IPublishSettingState {
  imageLoading: boolean;
  videoLoading: boolean;
  id: string;
  contractTypeID?: string;
  categoryID?: string;
}

const CheckboxInput = (props) => props.render();

const getPartialLable = (
  label: string,
  inputPosition: number,
  location: 'BeforeInput' | 'AfterInput',
): string => {
  const wordArr = label.split(' ');
  const filteredWordArr =
    location === 'BeforeInput'
      ? wordArr.filter((word, index) => index < inputPosition - 1)
      : wordArr.filter((word, index) => index >= inputPosition - 1);
  const resultStr = filteredWordArr.join(' ');
  return resultStr;
};

export const StyledSectionTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

class Setting extends React.Component<IPublishSettingProps, IPublishSettingState> {
  state: IPublishSettingState = {
    imageLoading: false,
    videoLoading: false,
    id: nanoid(),
  };

  componentDidMount() {
    this.initializeFields();
  }

  initializeFields = () => {
    const { content, config, updateSettings } = this.props;

    delay(500).then(() => {
      this.setState(
        (prevState) => {
          let newState = prevState;
          mustBeArray(config).forEach((c) => {
            if (isNullUndefined(content[c.key])) {
              newState = {
                ...newState,
                [c.key]: c.type === 'multiselect' ? [] : '',
              };
            } else {
              newState = {
                ...newState,
                [c.key]: content[c.key],
              };
            }

            newState = {
              ...newState,
              isAdditionalAttemptsEnabled:
                !!newState.additionalAttempts && newState.additionalAttempts > 0 ? true : false,
              isCompletionDaysEnabled:
                !!newState.completionDays && newState.completionDays > 0 ? true : false,
            };
          });
          return newState;
        },
        () => {
          updateSettings(this.state);
        },
      );
    });
  };

  componentDidUpdate(prevProps: IPublishSettingProps) {
    if (prevProps.visible !== this.props.visible) {
      this.initializeFields();
    }
  }

  beforeUpload = (file: any, elem: any) => {
    const { id } = this.props;
    const isValidType =
      elem.type === 'image'
        ? ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
        : ['video/mp4', 'video/mov', 'video/mpeg'].includes(file.type);
    if (!isValidType) {
      return message.error(errorMessages.invalidFileType);
    }
    this.setState((prevState) => {
      prevState = {
        ...prevState,
        [`${elem.type}Loading`]: true,
      };
      return prevState;
    });
    this.props
      .actionHelpers({
        payLoad: { file, name: file.name },
        options: { id, type: elem.type, content: elem.key },
      })
      .then((resp: any) => {
        this.setState((prevState) => {
          prevState = {
            ...prevState,
            [`${elem.type}Loading`]: false,
          };
          return prevState;
        });
        if (resp.status) {
          if (elem.type === 'image') {
            getBase64(file, (imageUrl: string) => {
              this.setState({
                ...this.state,
                [elem.key]: imageUrl,
              });
            });
          } else {
            // @ts-ignore
            const rPlayer = document.querySelector('.react-player').getElementsByTagName('video');
            if (_.isUndefined(rPlayer[0])) {
              this.props.reloadContent({ options: { id } });
            } else {
              rPlayer[0].src = URL.createObjectURL(file);
            }
          }
          return message.success({ content: `${elem.type} successfully uploaded` });
        } else {
          return message.error('File Format not supported');
        }
      });
    return false;
  };

  handleChange = (key: string, value: any, elem: any) => {
    this.setState(
      {
        ...this.state,
        [key]: value,
        [elem.inverse]: elem.inverse && value ? false : '',
      },
      () => {
        this.props.updateSettings(this.state);
      },
    );
  };

  getEnableLabel = (label: string) => `is${label.charAt(0).toUpperCase() + label.slice(1)}Enabled`;

  handleCheckboxInputEnable = (e: CheckboxChangeEvent, elem: unknown): void => {
    !e.target.checked
      ? this.setState(
          {
            ...this.state,
            [elem.key]: elem.key === 'completionDays' ? null : elem.min,
            [this.getEnableLabel(elem.key)]: false,
          },
          () => {
            this.props.updateSettings(this.state, elem);
          },
        )
      : this.setState(
          {
            ...this.state,
            [this.getEnableLabel(elem.key)]: true,
            [elem.key]: elem.initialValue,
          },
          () => {
            this.props.updateSettings(this.state, elem);
          },
        );
  };

  render() {
    const { config, content } = this.props;
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className='grid grid-cols-6 gap-4'>
          {mustBeArray(config).map((elem, i) => {
            switch (elem.type) {
              case 'textbox':
                return (
                  <div key={i} className={'col-span-3'}>
                    {this.state[`${elem.key}-editable`] && (
                      <div className={'flex items-center gap-6 text-red-400'}>
                        <ExclamationIcon className='h-8 w-8 m-r-2' />
                        <span>
                          Changing the title may confuse clients already using this contract
                          template
                        </span>
                      </div>
                    )}
                    <label htmlFor='fld-course-name' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    {elem.subLabel && (
                      <label htmlFor='fld-course-name' className='m-b-12 f-w-400'>
                        {elem.subLabel}
                      </label>
                    )}
                    <Input
                      value={this.state[elem.key]}
                      placeholder={elem.placeholder}
                      disabled={elem.confirmEdit && !this.state[`${elem.key}-editable`]}
                      onChange={(value) =>
                        this.handleChange(elem.key, value.currentTarget.value, elem)
                      }
                      addonAfter={
                        elem.confirmEdit && !this.state[`${elem.key}-editable`] ? (
                          <PencilIcon
                            className='h-5 w-5'
                            onClick={() => this.handleChange(`${elem.key}-editable`, true, elem)}
                          />
                        ) : (
                          <div />
                        )
                      }
                    />
                  </div>
                );

              case 'simple-textarea':
                return (
                  <div key={i} className={'col-span-6'}>
                    {this.state[`${elem.key}-editable`] && (
                      <div className={'flex items-center text-red-400'}>
                        <ExclamationIcon className='h-8 w-8' />
                        <span>
                          Changing the title may confuse clients already using this contract
                          template
                        </span>
                      </div>
                    )}
                    <label htmlFor='fld-course-name' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    {elem.subLabel && (
                      <label htmlFor='fld-course-name' className='m-b-12 f-w-400'>
                        {elem.subLabel}
                      </label>
                    )}
                    <Input.TextArea
                      value={this.state[elem.key]}
                      placeholder={elem.placeholder}
                      disabled={elem.confirmEdit && !this.state[`${elem.key}-editable`]}
                      onChange={(value) =>
                        this.handleChange(elem.key, value.currentTarget.value, elem)
                      }
                      addonAfter={
                        elem.confirmEdit &&
                        !this.state[`${elem.key}-editable`] && (
                          <PencilIcon
                            className='h-5 w-5'
                            onClick={() => this.handleChange(`${elem.key}-editable`, true, elem)}
                          />
                        )
                      }
                    />
                  </div>
                );

              case 'textarea':
                return (
                  elem.display && (
                    <div key={i} className={'col-span-6'}>
                      <label htmlFor='fld-course-description' className='m-b-4 f-w-600'>
                        {elem.label}
                      </label>
                      {elem.subLabel && (
                        <label htmlFor='fld-course-name' className='m-b-12 f-w-400'>
                          {elem.subLabel}
                        </label>
                      )}
                      <RTEditor
                        value={this.state[elem.key]}
                        visible={true}
                        handleEditorChange={(value) => this.handleChange(elem.key, value, elem)}
                      />
                    </div>
                  )
                );

              case 'switch':
                return (
                  elem.display && (
                    <div key={i} className={'col-span-3'}>
                      <Switch
                        checked={this.state[elem.key] || false}
                        onChange={(checked) => this.handleChange(elem.key, checked, elem)}
                      />{' '}
                      {elem.label}
                    </div>
                  )
                );
              case 'contractTypeDD':
                return (
                  <div key={i} className={'col-span-3'}>
                    <label htmlFor='fld-contract-type' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    <ContractTypeLookup
                      disabled={elem.disabled}
                      value={this.state[elem.key]}
                      onChange={(value) => this.handleChange(elem.key, value, elem)}
                    />
                  </div>
                );
              case 'letterCategoryID':
                return (
                  <div key={i} className={'col-span-3'}>
                    <label htmlFor='fld-letter-category' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    <LetterCategoryLookup
                      disabled={elem.disabled}
                      value={this.state[elem.key]}
                      onChange={(value) => this.handleChange(elem.key, value, elem)}
                    />
                  </div>
                );
              case 'courseCategoryDD':
                return (
                  <div key={i} className={'col-span-3'}>
                    <label htmlFor='fld-course-category' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    <CourseCategoryLookup
                      value={this.state[elem.key]}
                      onChange={(value) => this.handleChange(elem.key, value, elem)}
                    />
                  </div>
                );
              case 'courseLevelDD':
                return (
                  <div key={i} className={'col-span-3'}>
                    <label htmlFor='fld-course-level' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    <CourseLevelLookup
                      value={this.state[elem.key]}
                      onChange={(value) => this.handleChange(elem.key, value, elem)}
                    />
                  </div>
                );
              case 'select':
                return (
                  <div key={i} className={'col-span-3'}>
                    <label htmlFor='fld-course-category' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      disabled={elem.disabled}
                      value={this.state[elem.key]}
                      onChange={(value) => this.handleChange(elem.key, value, elem)}
                    >
                      <Select.Option key='dummy' value={0}>
                        {elem.placeholder}
                      </Select.Option>
                      {this.props[elem.data] &&
                        mustBeArray(this.props[elem.data]).map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                );
              case 'countryDD':
                return (
                  <div key={i} className={'col-span-3'}>
                    <label htmlFor='fld-country' className='m-b-4 f-w-600'>
                      {elem.label}
                    </label>
                    <CountryLookUp
                      value={this.state[elem.key]}
                      disabled={elem.disabled}
                      size={'default'}
                      onChange={(value) => this.handleChange(elem.key, value, elem)}
                      width={'100%'}
                      leftMargin={'0'}
                    />
                  </div>
                );
              case 'multiselect':
                return (
                  <div key={i} className={'col-span-3 multiselect-country'}>
                    <label htmlFor='fld-country' className='m-b-4'>
                      {elem.label}
                    </label>
                    <CountryLookUp
                      value={this.state[elem.key]}
                      mode='multiple'
                      disabled={elem.disabled}
                      size={'default'}
                      onChange={(value) => this.handleChange(elem.key, value, elem)}
                      width={'100%'}
                      leftMargin={'0'}
                    />
                  </div>
                );
              case 'image':
                return (
                  <div key={i} className={'col-span-6 mt-2'}>
                    <p className='m-b-4'>{elem.label}</p>
                    <Card className='bg-gray-400 radius-2  flex items-center justify-center '>
                      <Upload
                        className='hand'
                        accept='.jpg,.jpeg,.png'
                        beforeUpload={(file) => this.beforeUpload(file, elem)}
                        showUploadList={false}
                      >
                        <div
                          className={`bg-auto bg-no-repeat bg-center flex items-center justify-center ${
                            this.state[elem.key] ? 'ant-uploaded-file' : 'ant-uploaded-none'
                          }`}
                          style={{
                            height: 350,
                            width: 500,
                            backgroundImage: `url(${this.state[elem.key]})`,
                          }}
                        >
                          {this.state.imageLoading ? (
                            <Loading />
                          ) : (
                            <div className='ant-upload-icon' style={{ position: 'absolute' }}>
                              <UploadIcon className='h-5 w-5' />
                              <span className={'-ml-3'} style={{ display: 'block' }}>
                                Browse
                              </span>
                            </div>
                          )}
                        </div>
                      </Upload>
                    </Card>
                  </div>
                );

              case 'video':
                return (
                  <div key={i} className={'col-span-6'}>
                    <p className='m-b-4'>{elem.label}</p>
                    <Card className='bg-gray-400  flex items-center justify-center  radius-2'>
                      <Upload
                        className='hand'
                        accept='.mp4,.mpeg,.mov'
                        beforeUpload={(file) => this.beforeUpload(file, elem)}
                        showUploadList={false}
                      >
                        <div
                          style={{ height: 350 }}
                          className={`flex items-center justify-center ${
                            content[elem.key] ? 'ant-uploaded-file' : 'ant-uploaded-none'
                          }`}
                        >
                          <ReactPlayer
                            url={content[elem.key]}
                            className='react-player'
                            height='100%'
                            width='auto'
                            controls={true}
                          />
                          {this.state.videoLoading ? (
                            <Loading />
                          ) : (
                            <div className='ant-upload-icon' style={{ position: 'absolute' }}>
                              <PlayIcon className='h-5 w-5' />
                              <span className={'-ml-3'} style={{ display: 'block' }}>
                                Browse
                              </span>
                            </div>
                          )}
                        </div>
                      </Upload>
                    </Card>
                  </div>
                );
              case 'checkboxInput':
                return (
                  <div key={i} className={'col-span-6'}>
                    <CheckboxInput
                      render={() => (
                        <Checkbox
                          checked={this.state[this.getEnableLabel(elem.key)]}
                          onChange={(e: CheckboxChangeEvent) =>
                            this.handleCheckboxInputEnable(e, elem)
                          }
                        >
                          {getPartialLable(elem.label, elem.inputPosition, 'BeforeInput')}
                          <InputNumber
                            className='mx-2'
                            min={elem.min}
                            max={elem.max}
                            controls={false}
                            value={this.state[elem.key]}
                            placeholder={elem.placeholder}
                            disabled={!this.state[this.getEnableLabel(elem.key)]}
                            onChange={(value) => {
                              this.handleChange(elem.key, value, elem);
                            }}
                          />
                          {getPartialLable(elem.label, elem.inputPosition, 'AfterInput')}
                        </Checkbox>
                      )}
                    />
                  </div>
                );
              case 'sectionTitle':
                return (
                  <div key={i} className={'col-span-6'}>
                    <StyledSectionTitle>
                      <h4>{elem.label}</h4>
                    </StyledSectionTitle>
                  </div>
                );
              default:
                return <div key={i}>Default</div>;
            }
          })}
        </div>
      </ErrorBoundary>
    );
  }
}

export default Setting;
