export const generalMessages = {};

export const successMessages = {
  // Defaults
  defaultForm: 'Success - Form Saved',

  // Member
  login: 'You are now logged in',
  signUp: 'You are now signed up. Please login to continue.',
  forgotPassword: 'Password reset. Please check your email.',
};

export const errorMessages = {
  // Defaults
  default: 'Hmm, an unknown error occured',
  timeout: 'Server Timed Out. Check your internet connection',
  invalidJson: 'Response returned is not valid JSON',
  missingData: 'Missing data',

  // Member
  memberNotAuthd: 'You need to be logged in, to update your profile',
  memberExists: 'Member already exists',
  missingFirstName: 'First name is missing',
  missingLastName: 'Last name is missing',
  missingEmail: 'Email is missing',
  missingPassword: 'Password is missing',

  // File
  invalidFileType: 'This file type is not supported.',

  // Clause
  clauseDeleted: 'You have deleted this clause',

  // Partner
  missingRole: 'You need to select at-least one role',

  // Course
  courseNameEmpty: 'Course name is empty',
  courseCategoryEmpty: 'You need to select course category',
  courseDescriptionEmpty: 'Course description is required',
  courseCountryEmpty: 'You need to select course country',
  courseDescriptionMaxLength: 'You have exceeded maximum character length for description',
  courseTitleMaxLength: 'You have exceeded maximum character length for title',
  courseDeleted: 'You have deleted this course',
  courseAdditionalAttempts: 'Entered a valid number between 1-9999',
  courseCompletionDays: 'Entered a valid number between 1-9999',

  // Handbook
  handbookNameEmpty: 'Handbook name is empty',
  handbookCategoryEmpty: 'You need to select handbook category',
  handbookDescriptionEmpty: 'Handbook description is required',
  handbookCountryEmpty: 'You need to select handbook country',
  handbookDescriptionMaxLength: 'You have exceeded maximum character length for description',
  handbookTitleMaxLength: 'You have exceeded maximum character length for title',
  handbookDeleted: 'You have deleted this handbook',
};
