import { connect } from 'react-redux';

import Sidebar from '../../components/Sidebar';
import { RootState } from '../../store';

const mapProps = (state: RootState) => ({
  current: state.users.current,
});

export default connect(mapProps)(Sidebar);
