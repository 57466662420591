import React from 'react';

import { Col, Input, Row } from 'antd';
import moment from 'moment';

import RTEditor from '../../common/RTEditor';
import { conditionalRender, isNullUndefined } from '../../helpers/util';

interface IVersionSetting {
  updateSettings: Function;
  publisher: string | undefined;
  versionNumber?: number | string;
  mode: 'publish' | 'retire';
  note: any;
  type: 'contract' | 'letter';
  visible: boolean;
  releaseNotes?: string | null;
}

const VersionSetting = ({
  updateSettings,
  publisher,
  versionNumber,
  mode,
  note,
  visible,
  type,
  releaseNotes,
}: IVersionSetting) => {
  const versionNo = conditionalRender(
    mode === 'publish',
    isNullUndefined(versionNumber) ? 1 : Number(versionNumber) + 1,
    versionNumber,
  );
  return (
    <div className={''}>
      {note && (
        <Row>
          {mode === 'publish' && visible && <label className={'mb-1'}>Version Release Notes</label>}
          {mode === 'retire' && visible && (
            <label className={'mb-1'}>
              Enter some notes explaining the reason for the retirement of this {type} from the
              market.
            </label>
          )}
          {visible && (
            <RTEditor
              value={releaseNotes}
              visible={true}
              handleEditorChange={(value: string) => updateSettings({ releaseNotes: value })}
            />
          )}
        </Row>
      )}
      <Row gutter={8} className={'my-2'}>
        <Col span={8}>
          <label>
            {mode === 'publish' ? 'Publish' : conditionalRender(mode === 'retire', 'Retired', '')}{' '}
            By
          </label>
          <Input placeholder='Publisher' value={publisher} disabled={true} />
        </Col>
        <Col span={8}>
          <label>Version Number</label>
          <Input placeholder='Version number' value={versionNo} disabled={true} />
        </Col>
        <Col span={8}>
          <label>
            {mode === 'publish' ? 'Published' : conditionalRender(mode === 'retire', 'Retired', '')}{' '}
            On
          </label>
          <Input
            placeholder='Published date'
            value={moment().format('YYYY MM DD')}
            disabled={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default VersionSetting;
