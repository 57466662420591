import _ from 'lodash';
import moment from 'moment';

import { User } from '../@types/user';
import {
  entityType,
  lectureType,
  sectionContentType,
  workflowContentTypes,
} from '../common/constants/entities';
import stateStorage from '../helpers/stateStorage';

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const guid = () => {
  const crypto = window.crypto;
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

export const buildArrayParams = (key: string, params: any) => {
  return params.map((p: any) => `${key}=${p}`).join('&');
};

export const removeUndefined = (params: any) => {
  _.each(params, (value, key) => {
    if (_.isUndefined(value)) {
      delete params[key];
    }
  });
  return params;
};

export const reFormatClauseQuestion = () => {
  const questionList = stateStorage.getValue('clauseQuestion', []);
  _.each(questionList, (q) => {
    _.each(q.questions, (question) => {
      if (question.type === 3) {
        question.options = _.map(question.options, (v, idx) =>
          _.isObject(v) ? v : { id: idx + 1, name: v },
        );
      }
    });
  });
  return JSON.stringify(questionList);
};

export const buildQueryParams = (params: any) => {
  const oldParams = _.cloneDeep(params);
  if (params.q) {
    delete params.q;
  }
  params = { ...params, ...oldParams.q };
  const esc = encodeURIComponent;
  return !_.isUndefined(params)
    ? Object.keys(removeUndefined(params))
        .map((k) => {
          let queryString = '';
          if (_.isArray(params[k])) {
            queryString = buildArrayParams(esc(k), params[k]);
          } else {
            queryString = esc(k) + '=' + esc(params[k]);
          }
          return queryString;
        })
        .join('&')
    : '';
};

export const removeEmptyValuesInObject = (object: any) => {
  const newObject = _.clone(object);
  _.forEach(Object.keys(object), (o) => {
    if (object[o] === '' || _.isNull(object[o]) || _.isUndefined(object[o])) {
      delete newObject[o];
    }
  });
  return newObject;
};

export const templateEngine = function (html: string, options: any) {
  const re = /<%(.+?)%>/g;
  const reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g;

  let code = 'with(obj) { var r=[];\n',
    cursor = 0,
    result,
    match;

  const add = function (line: any, js?: any) {
    js
      ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n')
      : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
    return add;
  };

  while ((match = re.exec(html))) {
    add(html.slice(cursor, match.index))(match[1], true);
    cursor = match.index + match[0].length;
  }

  add(html.substr(cursor, html.length - cursor));
  code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');
  try {
    const tempOptions = _.cloneDeep(options);
    if (isNullUndefined(tempOptions.versionNumber)) {
      tempOptions.versionNumber = 'null';
    }
    result = new Function('obj', code).apply(tempOptions, [tempOptions]);
  } catch (err) {
    if (err instanceof Error) console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
  }
  return result;
};

export const getBase64 = (img: any, callback: Function) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getRandomNumber = () => {
  const crypto = window.crypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
};

export const mapWorkflowStepType = (contentType: string) => {
  const workflowType = _.find(workflowContentTypes, (wc) => wc.type === contentType);
  return workflowType && workflowType.value;
};

export const mapContentType = (type: string) => {
  const contentType = _.find(entityType, (et) => et.type === type);
  return contentType;
};

export const mapSectionContentType = (type: string) => {
  const sectionContent = _.find(sectionContentType, (wc) => wc.value === type);
  return sectionContent?.attr;
};
export const mapLectureType = (type: string) => {
  const lye = _.find(lectureType, (wc) => wc.value === type);
  return lye?.attr;
};

export const mustBeArray = (items: any) => {
  return _.isArray(items) ? items : [];
};

export const resolveJSONArray = (Json: string) => {
  return JSON.parse(Json || '[]');
};

export const move = (array: Array<any>, moveIndex: number, toIndex: number) => {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};

export const getPartnerCompanyId = (member: User) => {
  const persona = stateStorage.getValue('a-persona');
  return persona
    ? (
        _.find(
          mustBeArray(member.partnerCompanyUsers),
          (partner) => partner.partnerCompanyId === persona.partnerCompanyId,
        ) || {}
      ).partnerCompanyId
    : member &&
        member.partnerCompanyUsers &&
        member.partnerCompanyUsers &&
        _.isArray(member.partnerCompanyUsers) &&
        (_.first(member.partnerCompanyUsers) || {}).partnerCompanyId;
};

export const getPartnerCompany = (member: User) => {
  const persona = stateStorage.getValue('a-persona');
  return persona
    ? _.find(
        mustBeArray(member.partnerCompanyUsers),
        (partner) => partner.partnerCompanyId === persona.partnerCompanyId,
      ) || {}
    : member &&
        member.partnerCompanyUsers &&
        member.partnerCompanyUsers &&
        _.isArray(member.partnerCompanyUsers) &&
        (_.first(member.partnerCompanyUsers) || {});
};

export const isNullUndefined = (input: any) => {
  return _.isNull(input) || _.isUndefined(input) || ['null', 'undefined'].includes(input);
};

export const localeDate = (utcDate: any) => {
  return moment(+moment.utc(utcDate));
};

export const conditionalRender = <T>(condition: boolean, output1: T, output2: T) => {
  return condition ? output1 : output2;
};

export const truncateString = (text: string, limit: number) => {
  return text && text.length > limit
    ? _.truncate(text, { length: limit, separator: ' ' })
    : _.isNull(text)
    ? 'No content available'
    : text;
};
