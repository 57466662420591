import { RematchDispatch } from '@rematch/core';
import _ from 'lodash';

import { QueryFilter } from '../@types/global';
import {
  Workflow,
  WorkflowDetail,
  WorkflowDialogStep,
  WorkflowDocumentStep,
  WorkflowEvaluationStep,
  WorkflowLetterStep,
  WorkflowQuestionStep,
} from '../@types/workflow';
import { platformViewTypes } from '../common/constants/entities';
import stateStorage from '../helpers/stateStorage';
import { move, mustBeArray } from '../helpers/util';
import WorkflowService from '../services/WorkflowService';

type State = {
  workflows: ReadonlyArray<Workflow>;
  workflow: WorkflowDetail;
  steps: ReadonlyArray<
    | WorkflowDialogStep
    | WorkflowLetterStep
    | WorkflowDocumentStep
    | WorkflowQuestionStep
    | WorkflowEvaluationStep
  >;
  contents: Array<any>;
  total: number;
};

const model = {
  state: {} as State,
  reducers: {
    loadList: (
      state: State,
      payload: { rows: ReadonlyArray<Workflow>; total: number; page: number },
    ) => {
      const pageView =
        (stateStorage.getValue('WorkflowPageView') || {}).view || platformViewTypes['Grid'];
      return pageView === platformViewTypes['Table'] || payload.page === 1
        ? {
            ...state,
            workflows: payload.rows,
            total: payload.total,
          }
        : {
            ...state,
            workflows: [...mustBeArray(state.workflows), ...payload.rows],
            total: payload.total,
          };
    },
    detail: (state: State, payload: WorkflowDetail) => {
      return { ...state, workflow: payload };
    },
    update: (state: State, payload: any) => {
      return { ...state, workflow: { ...state.workflow, ...payload } };
    },
    clearContent: (state: State) => {
      return { ...state, contents: [] };
    },
    loadSteps: (
      state: State,
      payload: {
        rows: ReadonlyArray<
          | WorkflowDialogStep
          | WorkflowLetterStep
          | WorkflowDocumentStep
          | WorkflowQuestionStep
          | WorkflowEvaluationStep
        >;
        total: number;
      },
    ) => {
      return { ...state, steps: payload.rows };
    },
    updateStep: (state: State, { options, payLoad }: any) => {
      return {
        ...state,
        steps: _.map(state.steps, (step) => {
          if (step.id === options.stepID) {
            return { ...step, ...payLoad };
          }
          return step;
        }),
      };
    },
    deleteStep: (state: State, payLoad: any) => {
      return { ...state, steps: _.filter(state.steps, (step) => step.id !== payLoad.stepID) };
    },
    stepContent: (state: State, payLoad: { rows: Array<any>; total: number }) => {
      return { ...state, contents: payLoad.rows };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getWorkflows(params: QueryFilter) {
      dispatch.ui.start();
      const response = await WorkflowService.listWorkflow(params);
      const { result } = response.data;
      dispatch.ui.stop();
      dispatch.workflow.loadList({ ...result, page: params.page });
    },
    async getWorkflow({ options }: any) {
      dispatch.ui.start();
      const response: any = await WorkflowService.workflowActionHandler({
        action: 'get',
        id: options.id,
      });
      const { result } = response.data;
      dispatch.ui.stop();
      return dispatch.workflow.detail(result);
    },
    async createWorkflow({ options, payLoad }: any) {
      return WorkflowService.workflowActionHandler({ action: 'create', options, payLoad });
    },
    async updateWorkflow({ options, payLoad }: any) {
      await WorkflowService.workflowActionHandler({
        id: options.id,
        payLoad,
        options,
        action: 'update',
      });
      return dispatch.workflow.update(payLoad);
    },
    async deleteWorkflow({ options }: any) {
      return WorkflowService.workflowActionHandler({ action: 'delete', id: options.id });
    },
    async getWorkflowSteps({ options }: any) {
      const response: any = await WorkflowService.workflowStepActionHandler({
        action: 'list',
        options,
      });
      const { result } = response.data;
      return dispatch.workflow.loadSteps(result);
    },
    async updateWorkflowStep({ options, payLoad }: any) {
      await WorkflowService.workflowStepActionHandler({
        action: 'update',
        id: options.stepID,
        options,
        payLoad,
      });
      return dispatch.workflow.updateStep({ options, payLoad });
    },
    async deleteWorkflowStep({ options }: any) {
      await WorkflowService.workflowStepActionHandler({
        action: 'delete',
        id: options.stepID,
        options,
      });
      dispatch.workflow.deleteStep(options);
    },
    async createWorkflowStep({ options, payLoad }: any) {
      await WorkflowService.workflowStepActionHandler({ action: 'create', options, payLoad });
      return dispatch.workflow.clearContent();
    },
    async getWorkflowStep({ options }: any) {
      return WorkflowService.workflowStepActionHandler({
        action: 'get',
        options,
        id: options.stepID,
      });
    },
    async getWorkflowStepContents({ options }: any) {
      const response: any = await WorkflowService.workflowContentHandler({
        action: 'list',
        options,
        payLoad: { stepContentTypeID: options.stepContentTypeID },
      });
      const { result } = response?.data || { rows: [] };
      if (result) {
        dispatch.workflow.stepContent(result);
      }
    },
    async updateWorkflowStepContent({ options, payLoad }: any) {
      if (options.contentID === 0) {
        return WorkflowService.workflowContentHandler({ action: 'create', options, payLoad });
      } else {
        return WorkflowService.workflowContentHandler({
          action: 'update',
          options,
          payLoad,
          id: options.contentID,
        });
      }
    },
    async deleteWorkflowStepContent({ options, payLoad }: any) {
      return WorkflowService.workflowContentHandler({
        action: 'delete',
        options,
        id: options.contentID,
        payLoad,
      });
    },
    async dragWorkflowSteps({ options, source, destination, list }: any) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      dispatch.workflow.loadSteps({ rows: cloneList, total: cloneList.length });
      const payLoad = { steps: _.map(cloneList, (l) => l.id) };
      return WorkflowService.dragWorkflowStep({ options, payLoad });
    },
    async dragWorkflowQuestions({ options, source, destination, list }: any) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      const payLoad = { questions: _.map(cloneList, (l) => l.id) };
      await WorkflowService.dragWorkflowStepContent({ options, payLoad });
      return cloneList;
    },
    async dragWorkflowEvaluation({ options, source, destination, list }: any) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      const payLoad = { evaluation: _.map(cloneList, (l) => l.id) };
      dispatch.workflow.stepContent({ rows: cloneList, total: cloneList.length });
      return WorkflowService.dragWorkflowStepContent({ options, payLoad });
    },

    async documentPreview({ options }: any) {
      const response = await WorkflowService.documentPreview({
        id: options.id,
        stepId: options.stepId,
      });
      return new Response(response.data).arrayBuffer();
    },

    async workflowThumbnailUpload({ options, payLoad }: any) {
      return WorkflowService.workflowActionHandler({ action: 'upload', options, payLoad });
    },
  }),
};

export default model;
