import clause from './clause';
import contract from './contract';
import course from './course';
import hbook from './hbook';
import letter from './letter';
import lookup from './lookup';
import partners from './partners';
import toasts from './toasts';
import ui from './ui';
import users from './users';
import workflow from './workflow';

const models = {
  users,
  toasts,
  ui,
  course,
  workflow,
  clause,
  letter,
  contract,
  hbook,
  lookup,
  partners,
};

export default models;
