import { Route, Switch } from 'react-router-dom';

import CourseCreate from '../components/Course/Create';
import WorkflowDocumentStep from '../components/Workflow/Editor/Form/Document';
import IframeHelper from '../helpers/IframeHelper';
import TemplateNothing from '../layout/TemplateNothing';
import Courses from '../pages/Course';
import CourseEdit from '../pages/Course/edit';
import CourseEditV2 from '../pages/Course/EditV2';
import Workflows from '../pages/Workflow';
import WorkflowView from '../pages/Workflow/view';

const ssoConfig = IframeHelper.getConfig();
console.log(ssoConfig, 'config');
const Index = () => (
  <Switch>
    <Route
      exact
      path='/'
      render={() => {
        return (
          <TemplateNothing>
            {(() => {
              switch (ssoConfig.module) {
                case 'course':
                  switch (ssoConfig.action) {
                    case 'update':
                      return <CourseEdit />;
                    case 'update:scorm':
                      return <CourseEditV2 />;
                    case 'create':
                      return <CourseCreate />;
                    case 'list':
                    default:
                      return <Courses />;
                  }

                case 'workflow':
                  switch (ssoConfig.action) {
                    case 'update':
                      return <WorkflowView />;
                    case 'list':
                    default:
                      return <Workflows />;
                  }
              }
            })()}
          </TemplateNothing>
        );
      }}
    />
    <Route
      exact
      path={'/courses'}
      render={() => {
        return (
          <TemplateNothing>
            <Courses />
          </TemplateNothing>
        );
      }}
    />
    <Route
      exact
      path={'/course/create'}
      render={() => {
        return (
          <TemplateNothing>
            <CourseCreate />
          </TemplateNothing>
        );
      }}
    />
    <Route
      exact
      path={'/workflows'}
      render={() => {
        return (
          <TemplateNothing>
            <Workflows />
          </TemplateNothing>
        );
      }}
    />
    <Route
      exact
      path={'/course/edit/:id'}
      render={() => {
        return (
          <TemplateNothing>
            <CourseEditV2 />
          </TemplateNothing>
        );
      }}
    />
    <Route
      exact
      path={'/editor/courses/:id'}
      render={() => {
        return (
          <TemplateNothing>
            <CourseEdit />
          </TemplateNothing>
        );
      }}
    />
    <Route
      exact
      path={'/workflow/:id'}
      render={() => {
        return (
          <TemplateNothing>
            <WorkflowView />
          </TemplateNothing>
        );
      }}
    />
    <Route
      exact
      path={'/workflow/:id/step/:stepId/document/file/:fileId'}
      render={() => {
        return (
          <TemplateNothing>
            <WorkflowDocumentStep />
          </TemplateNothing>
        );
      }}
    />
  </Switch>
);

export default Index;
