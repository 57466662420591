import { QueryFilter } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';

class LookupService {
  public country(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'COUNTRY_LOOKUP' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public states(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'STATE_LOOKUP' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public timezone(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'TIMEZONE_LOOKUP' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public courseLevel(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_LEVEL_LOOKUP' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public courseCategory(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_CATEGORY_LOOKUP' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public handbookStatus(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_STATUS_LOOKUP' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public handbookCategory(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_CATEGORY_LOOKUP' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }
}

const instance = new LookupService();
Object.freeze(instance);

export default instance;
