import React from 'react';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import _ from 'lodash';

import { delay } from '../helpers/util';

export class Message {
  success(text: any) {
    const errorMessage = _.isString(text) ? text : this.resolveErrorMessage(text);
    message.success({
      content: errorMessage,
      className: 'xemplo-message',
      icon: <CheckCircleOutlined className='xemplo-message-icon' />,
    });
  }

  warning(text: any) {
    const errorMessage = _.isString(text) ? text : this.resolveErrorMessage(text);
    message.warning({
      content: errorMessage,
      className: 'xemplo-message',
      icon: <WarningOutlined className='xemplo-message-icon' />,
    });
  }

  error(text: any) {
    const errorMessage = _.isString(text) ? text : this.resolveErrorMessage(text);
    message.error({
      content: errorMessage,
      className: 'xemplo-message',
      icon: <CloseCircleOutlined className='xemplo-message-icon' />,
    });
  }

  icon(text: any) {
    const errorMessage = _.isString(text) ? text : this.resolveErrorMessage(text);
    message.info({
      content: errorMessage,
      icon: <InfoCircleOutlined className='xemplo-message-icon' />,
    });
    this.changeColor('#56CCF2');
  }

  private changeColor(color: string) {
    delay(10).then(() => {
      _.each(document.querySelectorAll('.ant-message-notice-content'), (elem) => {
        (elem as HTMLFormElement).style.backgroundColor = color;
        (_.first(elem.getElementsByTagName('span')) as HTMLFormElement).style.color = '#fff';
      });
    });
  }

  private resolveErrorMessage(errors: any) {
    return _.reduce(
      errors,
      (value, obj: any) => {
        value += _.isString(obj) ? obj : obj?.errorMessage.message;
        return value;
      },
      '',
    );
  }
}

const instance = new Message();
Object.freeze(instance);

export default instance;
