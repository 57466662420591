import { ActionHandler, QueryFilter } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';
import { delay } from '../helpers/util';

class HandbookService {
  public listHandbooks(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_URL' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public handbookActionHandler = ({
    id,
    action,
    payLoad,
    options = {},
    queryOptions = {},
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_URL', isMocked: false });
        return http.get(`${url}/${id}`, queryOptions);

      case 'list':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_URL', isMocked: false });
        return http.get(url, queryOptions);

      case 'create':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_URL', isMocked: false });
        return http.post(url, payLoad);

      case 'upload':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_IMAGE_UPLOAD',
          options: options,
          isMocked: false,
        });
        return http.postFile(url, payLoad);

      case 'update':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_URL', isMocked: false });
        return http.patch(`${url}/${id}`, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_URL', isMocked: false });
        return http.del(`${url}/${id}`);
      default:
        return delay(100);
    }
  };

  public handbookSectionActionHandler = ({
    queryOptions,
    id,
    action,
    payLoad,
    options,
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'list':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.get(url, queryOptions);

      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.get(`${url}/${id}`, queryOptions);

      case 'create':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.post(url, payLoad);

      case 'upload':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_SECTION_URL',
          options: { ...options, id },
          isMocked: false,
        });
        return http.postFile(url, payLoad);

      case 'update':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.patch(`${url}/${id}`, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      default:
        return delay(100);
    }
  };

  public contentActionHandler = ({
    id,
    options = {},
    queryOptions = {},
    payLoad = {},
    action,
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'GET_HANDBOOK_CONTENT_URL',
          options: options,
          isMocked: false,
        });
        return http.get(`${url}/${id}`, queryOptions);

      case 'post':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_CONTENT_URL',
          options: options,
          isMocked: false,
        });
        return http.post(url, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_CONTENT_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      case 'patch':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_CONTENT_URL',
          options: options,
          isMocked: false,
        });
        return http.patch(`${url}/${id}`, payLoad);

      case 'upload_file':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_FILEUPLOAD',
          options: options,
          isMocked: false,
        });
        return http.postFile(url, payLoad);

      default:
        return delay(100);
    }
  };

  public contentResourcesHandler = ({
    id,
    options = {},
    queryOptions = {},
    payLoad = {},
    action,
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_RESOURCE_URL',
          options: options,
          isMocked: false,
        });
        return http.get(url, queryOptions);

      case 'post':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_RESOURCE_URL',
          options: options,
          isMocked: false,
        });
        return http.postFile(url, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'HANDBOOK_RESOURCE_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      default:
        return delay(100);
    }
  };

  public sectionItemsDrag({
    handbookID,
    payLoad,
  }: {
    handbookID?: number;
    payLoad: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'HANDBOOK_URL', isMocked: false });
    return http.patch(`${url}/${handbookID}`, payLoad);
  }
}

const instance = new HandbookService();
Object.freeze(instance);

export default instance;
