const environment = {
  baseurl: process.env.REACT_APP_BASEURL || 'https://advisoryapi-dev.expedo.team',
  callback_url: process.env.REACT_APP_REDIRECT_URL
    ? `${process.env.REACT_APP_REDIRECT_URL}/confirm-login`
    : 'http://localhost:3000/confirm-login',
  redirect_url: process.env.REACT_APP_REDIRECT_URL
    ? `${process.env.REACT_APP_REDIRECT_URL}/confirm-logout`
    : 'http://localhost:3000/confirm-logout',
  identity_baseurl: process.env.REACT_APP_IDENTITY_URL || 'https://id-staging.expedo.team',
  insight_key: process.env.REACT_APP_INSIGHT_KEY || '51ce6aec-0926-40ae-8651-21f1f566cd03',
  eims_endpoint: process.env.REACT_APP_EIMS_URL || 'https://id-dev.expedo.team/api/v1',
  start_app_url: '',
};

export default environment;
