import { FileParam } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';

class DocumentService {
  public getSfdtFile(options: FileParam): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'SFDT_URL', options });
    return http.get(url);
  }
}

const instance = new DocumentService();
Object.freeze(instance);

export default instance;
