import React, { Fragment, useEffect, useState } from 'react';

import { Redirect, RouteProps } from 'react-router-dom';

import { authService } from '../services/AuthService';

export const PrivateRoute = ({ children, location, ...rest }: RouteProps) => {
  const [isAuthenticated, updateAuth] = useState(false);
  const [loading, updateLoading] = useState(true);

  useEffect(function () {
    authService.getUser().then((user) => {
      authService.isAuthenticated().then((isLoggedIn) => {
        if (user && isLoggedIn) {
          updateAuth(true);
          updateLoading(false);
        } else if (user) {
          return authService.renewToken().then(() => {
            updateAuth(true);
            updateLoading(false);
          });
        } else {
          updateAuth(false);
          updateLoading(false);
        }
      });
    });
  }, []);

  const link = document.createElement('a');
  link.href = location?.pathname as string;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `/login?returnUrl=${encodeURI(returnUrl)}`;

  if (!isAuthenticated && !loading) {
    return <Redirect to={redirectUrl} />;
  }

  if (loading || !isAuthenticated) {
    return <span>Loading....</span>;
  }
  return <Fragment>{children}</Fragment>;
};

export default PrivateRoute;
