import { useState } from 'react';

import { message, Modal } from 'antd';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ClauseParams, ClauseQuestionVariant } from '../../../@types/clause';
import { Contract } from '../../../@types/contract';
import HeaderActions from '../../../common/headerActions';
import Loading from '../../../common/Loading';
import DocumentHelper from '../../../helpers/DocumentHelper';
import { delay, isNullUndefined, mustBeArray } from '../../../helpers/util';
import history from '../../../routes/history';
import { RootDispatch, RootState } from '../../../store';
import VariantReleaseForm from '../Actions/VariantReleaseForm';

const VariantEditAction = () => {
  const { id, paragraphId, fileId } = useParams<any>();
  const { clause, variant } = useSelector((state: RootState) => state.clause);

  const dispatch = useDispatch<RootDispatch>();
  const {
    clause: { updateClauseParagraph, checkHasVariantRelatedContracts },
    contract: { uploadContractDocument, getContractSfdt },
  } = dispatch;

  const validActions = [
    { name: 'Save', style: 'primary', visible: true },
    { name: 'Download', style: 'default', visible: true },
    { name: 'Close', style: 'default', visible: true },
  ];

  const saveDocument = (data: any) => {
    return uploadContractDocument({
      options: { id: fileId } as Contract,
      payload: {
        file: data.file,
        questions: {},
      },
    });
  };

  const resolveVariantName = async () => {
    if (variant.title) {
      return delay(100);
    } else {
      return getContractSfdt({ id: fileId }).then((response: AxiosResponse) => {
        return { isNew: true, data: response.data };
      });
    }
  };

  const saveClauseVariant = () => {
    const questionsUsed: string[] = [];
    const documentEditor = DocumentHelper.get();

    const clauseQuestions = clause.questions && JSON.parse(clause.questions);

    mustBeArray(clauseQuestions).forEach((question) => {
      question.questions.forEach((variant: ClauseQuestionVariant) => {
        documentEditor.documentEditor.searchModule.findAll(variant.name);

        if (documentEditor.documentEditor.searchModule.searchResults.length > 0) {
          questionsUsed.push(variant.name);
        }
      });
    });

    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: AxiosResponse) => {
      saveDocument({ file: doc, type: 'Docx', title: nanoid() }).then(async () => {
        await resolveVariantName().then((response: any) => {
          let title = variant.title;
          if (response?.isNew) {
            try {
              const stdfObject = JSON.parse(response.data || '[]');
              title = stdfObject.sections[0]['blocks'][0]['inlines'][0]['text'];
            } catch (e) {
              console.log(e);
            }
          }
          return updateClauseParagraph({
            options: { clauseID: id, paragraphID: paragraphId } as ClauseParams,
            payLoad: { title, questionsUsed, publishMessage },
          }).then(
            () => {
              history.push(`/clause/${id}`);
              return message.success('Variant saved successfully');
            },
            () => {
              return message.error('Error');
            },
          );
        });
      });
    });
  };

  const handleActionClick = async (action: string) => {
    switch (action) {
      case 'Save': {
        const hasVariantRelatedContracts = await checkHasVariantRelatedContracts(id);
        if (hasVariantRelatedContracts) {
          toggleVariantReleaseModalVisible();
        } else {
          saveClauseVariant();
        }
        break;
      }
      case 'Download': {
        const documentEditor = DocumentHelper.get();
        documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
          saveAs(doc, `${variant.title}.docx`);
        });
        break;
      }
      default:
        history.push(`/clause/${id}`);
    }
  };

  const [variantReleaseModalVisible, setVariantReleaseModalVisible] = useState<boolean>(false);
  const [publishMessage, setPublishMessage] = useState<string>('');
  const toggleVariantReleaseModalVisible = () => {
    setVariantReleaseModalVisible(!variantReleaseModalVisible);
    setPublishMessage('');
  };

  if (isNullUndefined(variant)) return <Loading />;

  return (
    <>
      <HeaderActions actions={validActions} handleActionClick={handleActionClick} />
      <Modal
        visible={variantReleaseModalVisible}
        centered
        width={'30vw'}
        footer={null}
        onCancel={toggleVariantReleaseModalVisible}
        title='Enter the release note for this update'
      >
        <VariantReleaseForm
          onSubmit={saveClauseVariant}
          onCancel={toggleVariantReleaseModalVisible}
          onPublishMessageUpdate={setPublishMessage}
        />
      </Modal>
    </>
  );
};

export default VariantEditAction;
