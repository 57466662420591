import { init, RematchDispatch, RematchRootState } from '@rematch/core';

import models from '../models';

const store = init({
  models,
  redux: {
    middlewares: [],
  },
});

export type RootState = RematchRootState<typeof models>;
export type RootDispatch = RematchDispatch<typeof models>;

export default store;
