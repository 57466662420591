import React from 'react';

import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import HeaderActions from '../../../common/headerActions';
import DocumentHelper from '../../../helpers/DocumentHelper';
import history from '../../../routes/history';
import { RootDispatch, RootState } from '../../../store';

const EditWorkflowDocumentAction = ({ uploadDocument }: { uploadDocument: Function }) => {
  const { id, fileId } = useParams<any>();
  const validActions = [
    { name: 'Save', style: 'primary', visible: true },
    { name: 'Download', style: 'default', visible: true },
    { name: 'Print', style: 'default', visible: true },
    { name: 'Close', style: 'default', visible: true },
  ];
  const handleActionClick = (action: string) => {
    switch (action) {
      case 'Print':
        printDocument();
        break;
      case 'Save':
        saveDocument();
        break;
      case 'Download':
        downloadDocument();
        break;
      case 'Close':
        close();
        break;
      default:
        return false;
    }
  };

  const printDocument = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.print();
  };
  const downloadDocument = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
      saveAs(doc, 'Untitled.docx');
    });
  };
  const saveDocument = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
      uploadDocument({
        options: { id: fileId },
        payload: { file: doc, name: 'Untitled', questions: {} },
      }).then(() => close());
    });
  };
  const close = () => {
    history.push(`/workflow/${id}`);
  };

  return <HeaderActions actions={validActions} handleActionClick={handleActionClick} />;
};

const mapProps = (state: RootState) => ({});
const mapDispatch = (dispatch: RootDispatch) => ({
  uploadDocument: dispatch.letter.uploadLetterDocument,
});

export default connect(mapProps, mapDispatch)(EditWorkflowDocumentAction);
