import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import {
  AuthenticationResultStatus,
  LoginActions,
  QueryParameterNames,
} from '../../common/constants/auth';
import Loading from '../../common/Loading';
import { authService } from '../../services/AuthService';

type IMetaProps = {
  action: string;
};

const Login = ({ action }: IMetaProps) => {
  const [message, setMessage] = useState('');

  const getReturnUrl = (state: any = {}) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
  };

  const navigateToReturnUrl = (url: string) => {
    window.location.replace(url);
  };

  useEffect(function () {
    switch (action) {
      case LoginActions.Login:
        setMessage('Processing login');
        login(getReturnUrl());
        break;
      case LoginActions.LoginCallback:
        setMessage('Confirming Login...');
        processLoginCallback();
        break;
      case LoginActions.LoginFailed: {
        const params = new URLSearchParams(window.location.search);
        const error: any = params.get(QueryParameterNames.Message);
        setMessage(error);
        break;
      }
      case LoginActions.Profile:
      case LoginActions.Register:
        setMessage('');
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }, []);

  const login = async (returnUrl: string) => {
    const result: any = await authService.login();
    switch (result && result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        setMessage(
          result && result.message
            ? _.isObject(result.message)
              ? result.message.message
              : result.message
            : '',
        );
        break;
      default:
        throw new Error(`Invalid status result ${result && result.status}.`);
    }
  };

  const processLoginCallback = async () => {
    const url = window.location.href;
    try {
      const result: any = await authService.loginCallback(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error('Should not redirect.');
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result && result.message);
          break;
        default:
          throw new Error(`Invalid authentication result status '${result && result.status}'.`);
      }
    } catch (ex) {
      throw new Error(`Error during login : ${ex}`);
    }
  };

  return (
    <div>
      <Loading position={'center'} />
      <p className='text-center'>{message}</p>
    </div>
  );
};

export default Login;
