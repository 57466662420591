import { RematchDispatch } from '@rematch/core';

import { FileParam, JSONQuestionModel, QueryFilter } from '../@types/global';
import { Letter, LetterCategory, LetterDetail, LetterParam } from '../@types/letter';
import { platformViewTypes } from '../common/constants/entities';
import stateStorage from '../helpers/stateStorage';
import { mustBeArray } from '../helpers/util';
import DocumentService from '../services/DocumentService';
import LetterService from '../services/LetterService';

type State = {
  letters: ReadonlyArray<Letter>;
  letter: LetterDetail;
  letterCategory: ReadonlyArray<LetterCategory>;
  total: number;
  questions: Array<JSONQuestionModel>;
};

const model = {
  state: {} as State,
  reducers: {
    loadList: (
      state: State,
      payload: { rows: ReadonlyArray<Letter>; total: number; page: number },
    ) => {
      const pageView =
        (stateStorage.getValue('LetterPageView') || {}).view || platformViewTypes['Grid'];
      return pageView === platformViewTypes['Table'] || payload.page === 1
        ? {
            ...state,
            letters: payload.rows,
            total: payload.total,
          }
        : {
            ...state,
            letters: [...mustBeArray(state.letters), ...payload.rows],
            total: payload.total,
          };
    },
    detail: (state: State, payload: LetterDetail) => {
      return { ...state, letter: payload, questions: JSON.parse(payload.data || '[]') };
    },
    letterCategory: (state: State, payload: { rows: ReadonlyArray<LetterCategory> }) => {
      return { ...state, letterCategory: payload.rows };
    },
    updateQuestions: (state: State, payload: any) => {
      return { ...state, questions: payload };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getLetters(params: QueryFilter) {
      dispatch.ui.start();
      const response = await LetterService.listLetter(params);
      dispatch.ui.stop();
      return dispatch.letter.loadList({ ...response.data, page: params.page });
    },
    async getLetter(params: { options: LetterParam }) {
      dispatch.ui.start();
      const response = await LetterService.getLetter({ options: params.options });
      dispatch.ui.stop();
      return dispatch.letter.detail(response.data.result);
    },
    async getLetterSfdt(params: FileParam) {
      return DocumentService.getSfdtFile(params);
    },
    async getLetterVersion(params: { options: LetterParam }) {
      return await LetterService.getVersionHistory({ options: params.options });
    },
    async getLetterVersionPreview(params: any) {
      const response = await LetterService.previewVersion({
        options: params.options,
        payLoad: params.payLoad,
      });
      return new Response(response.data).arrayBuffer();
    },
    async listLetterCategory(params: QueryFilter) {
      const response = await LetterService.listLetterCategory(params);
      dispatch.letter.letterCategory(response.data.result);
    },
    async createLetter({ payload }: { payload: any }) {
      return LetterService.createLetter({ payload });
    },
    async updateLetter({ options, payload }: { options: LetterParam; payload: any }) {
      return LetterService.updateLetter({ options, payload });
    },
    async uploadLetterDocument({ options, payload }: { options: LetterParam; payload: any }) {
      dispatch.ui.start();
      const response = await LetterService.uploadLetterDocument({ options, payload });
      dispatch.ui.stop();
      return response;
    },
    async publishLetter({ options, payload }: { options: LetterParam; payload: any }) {
      return LetterService.publishLetter({ options, payload });
    },
    async retireLetter({ options, payload }: { options: LetterParam; payload: any }) {
      return LetterService.retireLetter({ options, payload });
    },
    async draftLetter({ options }: { options: LetterParam }) {
      return LetterService.draftLetter({ options });
    },
    async deleteLetter({ options }: { options: LetterParam; payload: any }) {
      return LetterService.deleteLetter({ options });
    },
    async previewWithMergeFields({ options, payload }: { options: LetterParam; payload: any }) {
      dispatch.ui.start();
      const response = await LetterService.previewWithMergeFields({ options, payload });
      dispatch.ui.stop();
      return response;
    },
  }),
};

export default model;
