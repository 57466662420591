import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import Loading from '../common/Loading';
import ProgressBar from '../common/pageProgress';
import Header from '../containers/Header';
import Sidebar from '../containers/Sidebar';
import { RootDispatch } from '../store';

type IMainProps = {
  children: ReactNode;
  getCurrentUser: any;
};

const TemplateSidebar = ({ children, getCurrentUser }: IMainProps) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCurrentUser().then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading position={'center'} />;
  }
  return (
    <Fragment>
      <ProgressBar />
      <div className='h-screen bg-white dark:bg-darkSecondary overflow-hidden'>
        <Header />
        <div className='flex h-full'>
          <Sidebar />
          <div className='w-full'>
            <main className='w-screen sm:w-full flex-1 overflow-y-auto bg-xemplo-gray-50 sm:rounded-tl-lg sm:rounded-bl-lg p-6 sm:ml-6 lg:ml-0 content-height'>
              {children}
            </main>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapPros = () => ({});

const mapDispatch = (dispatch: RootDispatch) => ({
  getCurrentUser: dispatch.users.loadCurrentUser,
});

export default connect(mapPros, mapDispatch)(TemplateSidebar);
