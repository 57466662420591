import React from 'react';

import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import HeaderActions from '../../../common/headerActions';
import history from '../../../routes/history';

const PreviewCourseAction = () => {
  const { id } = useParams<any>();
  const search = useLocation().search;
  const validActions = [{ name: 'Close', style: 'primary', visible: true }];
  const handleActionClick = () => {
    const param = queryString.parse(search);
    if (param.origin === 'detail') {
      history.push(`/editor/courses/${id}`);
    } else {
      history.push('/courses');
    }
  };

  return <HeaderActions actions={validActions} handleActionClick={handleActionClick} />;
};

export default PreviewCourseAction;
