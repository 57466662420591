import { useCallback, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, Switch } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

export interface IVariantReleaseFormProps {
  onSubmit: () => void;
  onCancel: () => void;
  onPublishMessageUpdate: (value: string) => void;
}

export interface IFormData {
  isSilentRelease: boolean;
  publishMessage?: string;
}

const VariantReleaseForm = (props: IVariantReleaseFormProps) => {
  const {
    onSubmit: handleSubmit,
    onCancel: handleCancel,
    onPublishMessageUpdate: handlePublishMessageUpdate,
  } = props;
  const [form] = Form.useForm<IFormData>();
  const isSilentRelease = Form.useWatch('isSilentRelease', form);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getFormItemLabel = useCallback((text: string) => {
    const customLabelStyles = { fontSize: '14px', fontWeight: 'bold' };
    return <p style={customLabelStyles}>{text}</p>;
  }, []);

  const handleSilentReleaseChange = useCallback(() => {
    form.setFieldValue('isSilentRelease', !isSilentRelease);
    if (!isSilentRelease) form.setFieldValue('publishMessage', '');
  }, [form, isSilentRelease]);

  const handlePublishMessageChange = useCallback(
    (value: string) => {
      form.setFieldValue('publishMessage', value);
      handlePublishMessageUpdate(value);
    },
    [form, handlePublishMessageUpdate],
  );

  return (
    <>
      <Spin indicator={loadingIcon} spinning={isLoading}>
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-start-2 col-span-10'>
            <Form
              form={form}
              layout='vertical'
              initialValues={{
                isSilentRelease: false,
                publishMessage: '',
              }}
              onFinish={handleSubmit}
              autoComplete='off'
            >
              <div className='flex flex-row items-center mb-4'>
                <Form.Item label='' name='isSilentRelease' className='mb-0'>
                  <Switch onChange={handleSilentReleaseChange} />
                </Form.Item>
                <div className='ml-2'>
                  {getFormItemLabel('Toggle this option to make this a silent release')}
                </div>
              </div>

              <Form.Item
                label=''
                name='publishMessage'
                dependencies={['isSilentRelease']}
                required
                rules={[{ required: !isSilentRelease, message: 'Please enter a publish message!' }]}
              >
                <TextAreaStyled
                  disabled={isSilentRelease}
                  className='w-full'
                  rows={4}
                  placeholder='E.g. Employee Handbook'
                  onChange={(e) => handlePublishMessageChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item>
                <div className='flex justify-center gap-10'>
                  <Button block onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button block type='primary' htmlType='submit' loading={isLoading}>
                    Publish
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </>
  );
};

export const TextAreaStyled = styled(TextArea)`
  border-radius: 1rem;
`;

export default VariantReleaseForm;
