import { ContractIcon, Evaluation, LetterIcon, Message, Questionaire } from '../assets';

export const clientDateFormat = 'DD/MM/YYYY';

export const platformViewTypes = {
  Grid: 1,
  Table: 2,
};

export const entityType = [
  {
    name: 'Contract',
    type: 'C',
  },
  {
    name: 'Letter',
    type: 'L',
  },
  {
    name: 'Section',
    type: 'S',
  },
  {
    name: 'Workflow',
    type: 'W',
  },
  {
    name: 'Course',
    type: 'Co',
  },
  {
    name: 'Handbook',
    type: 'H',
  },
];

export const workflowContentTypes = [
  {
    name: 'Dialog',
    type: 'dialog',
    icon: Message,
    value: 1,
  },
  {
    name: 'Questionnaires',
    type: 'question',
    icon: Questionaire,
    value: 2,
  },
  // {
  //   name: "Send Contract",
  //   type: "contract",
  //   icon: ContractIcon,
  //   value: 3
  // },
  {
    name: 'Send Letter',
    type: 'letter',
    icon: LetterIcon,
    value: 4,
  },
  {
    name: 'Document',
    type: 'document',
    icon: ContractIcon,
    value: 5,
  },
  // {
  //   name: "Tasks",
  //   type: "task",
  //   icon: theIcon.Task,
  //   value: 6
  // },
  {
    name: 'Evaluation',
    type: 'evaluation',
    icon: Evaluation,
    value: 7,
  },
];

export const workflowQuestionTypes = [
  {
    typeId: 1,
    text: 'Short text',
  },
  {
    typeId: 2,
    text: 'Long text',
  },
  {
    typeId: 3,
    text: 'Radio button',
  },
  {
    typeId: 4,
    text: 'Checklist',
  },
  {
    typeId: 5,
    text: 'Yes/No',
  },
  {
    typeId: 6,
    text: 'Date field',
  },
  {
    typeId: 7,
    text: 'File upload',
  },
];

export const monthsSuffix = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const workerMergeField = [
  { text: 'First Name', name: 'EmployeeFirstName' },
  { text: 'Last Name', name: 'EmployeeLastName' },
  { text: 'Personal Email', name: 'EmployeeEmailPersonal' },
  { text: 'Job Title', name: 'ContractJobTitle' },
  { text: 'Work Email', name: 'EmployeeEmailWork' },
  { text: 'Mobile', name: 'EmployeeMobile' },
  { text: 'Home Phone', name: 'EmployeeHomePhone' },
  { text: 'Work Phone', name: 'EmployeeWorkPhone' },
  { text: 'Birth Date', name: 'EmployeeDOB' },
  { text: 'Gender', name: 'EmployeeGender' },
  { text: 'Physical Address', name: 'EmployeePhysicalAddress' },
  { text: 'Postal Address', name: 'EmployeePostalAddress' },
  { text: 'Is Contractor', name: 'EmployeeIsIndependentContractor' },
  { text: 'Company Name', name: 'EmployeeCompanyName' },
  { text: 'Employee ABN', name: 'EmployeeABN' },
  { text: 'Is GST Reg', name: 'EmployeeIsGSTRegistered' },
];

export const contractDefaultMergeFields = [
  {
    name: 'Worker',
    value: workerMergeField,
    fieldId: 'reactour-mergeFieldWorker',
  },
  {
    name: 'Client',
    value: [
      { text: 'Client Name', name: 'ClientName' },
      { text: 'Client Has Default Super Fund', name: 'ClientHasDefaultSuperFund' },
      { text: 'Client Default Super Fund', name: 'ClientDefaultSuperFund' },
      { text: 'Client Physical Address', name: 'ClientPhysicalAddress' },
      { text: 'Client Physical Suburb', name: 'ClientPhysicalSuburb' },
      { text: 'Client Physical State', name: 'ClientPhysicalState' },
      { text: 'Client Physical Code', name: 'ClientPhysicalCode' },
      { text: 'Client Postal Address', name: 'ClientPostalAddress' },
      { text: 'Client Postal Suburb', name: 'ClientPostalSuburb' },
      { text: 'Client Postal State', name: 'ClientPostalState' },
      { text: 'Client Postal Code', name: 'ClientPostalCode' },
      { text: 'Client ABN', name: 'ClientABN' },
      { text: 'Client Logo Large', name: 'ClientLogoLarge' },
      { text: 'Client Logo Portal', name: 'ClientLogoPortal' },
      { text: 'Client Logo Small', name: 'ClientLogoSmall' },
    ],
    fieldId: 'reactour-mergeFieldClient',
  },
  {
    name: 'Contract',
    value: [
      { text: 'Client Sigature', name: 'ClientSignature' },
      { text: 'Client Signed Date', name: 'ClientSignedDate' },
      { text: 'Commencement Date', name: 'CommencementDate' },
      { text: 'Date Submitted', name: 'ContractDateSubmitted' },
      { text: 'Document Package', name: 'ContractDocumentPackage' },
      { text: 'Manager', name: 'ContractManager' },
      { text: 'Manager Email', name: 'ContractManagerEmail' },
      { text: 'Manager Position', name: 'ContractManagerPosition' },
      { text: 'Offer Expiry Date', name: 'ContractOfferExpiryDate' },
      { text: 'Require Position', name: 'ContractReqPosition' },
      { text: 'Require Profile Picture', name: 'ContractReqProfilePicture' },
      { text: 'Require Qualification', name: 'ContractReqQualifications' },
      { text: 'Require Work Rights', name: 'ContractReqWorkRights' },
      { text: 'Total Hours', name: 'ContractTotalHours' },
      { text: 'Contract Date', name: 'Date' },
      { text: 'Employee Signature', name: 'EmployeeSignature' },
      { text: 'Employee Signed Date', name: 'EmployeeSignedDate' },
      { text: 'Contract Job Title / Position', name: 'ContractJobTitle' },
      { text: 'Contract Job Description', name: 'JobDescription' },
      { text: 'Contract Job Requirements', name: 'JobRequirements' },
      { text: 'Contract Job Responsibilities', name: 'JobResponsibilities' },
      { text: 'Contract Job Details', name: 'JobDetails' },
    ],
    fieldId: 'reactour-mergeFieldcontract',
  },
];

export const workflowDefaultMergeFields = [
  {
    name: 'Worker',
    value: workerMergeField,
    fieldId: 'reactour-mergeFieldWorker',
  },
  {
    name: 'Workflow',
    value: [
      { text: 'Assignee First Name', name: 'AssigneeFirstName' },
      { text: 'Assignee Last Name', name: 'AssigneeLastName' },
    ],
    fieldId: 'reacttour-meargeFieldWorkflow',
  },
  {
    name: 'Client',
    value: [
      { text: 'Client Name', name: 'ClientName' },
      { text: 'Client Has Default Super Fund', name: 'ClientHasDefaultSuperFund' },
      { text: 'Client Default Super Fund', name: 'ClientDefaultSuperFund' },
      { text: 'Client Physical Address', name: 'ClientPhysicalAddress' },
      { text: 'Client Physical Suburb', name: 'ClientPhysicalSuburb' },
      { text: 'Client Physical State', name: 'ClientPhysicalState' },
      { text: 'Client Physical Code', name: 'ClientPhysicalCode' },
      { text: 'Client Postal Address', name: 'ClientPostalAddress' },
      { text: 'Client Postal Suburb', name: 'ClientPostalSuburb' },
      { text: 'Client Postal State', name: 'ClientPostalState' },
      { text: 'Client Postal Code', name: 'ClientPostalCode' },
      { text: 'Client ABN', name: 'ClientABN' },
      { text: 'Client Logo Large', name: 'ClientLogoLarge' },
      { text: 'Client Logo Portal', name: 'ClientLogoPortal' },
      { text: 'Client Logo Small', name: 'ClientLogoSmall' },
    ],
    fieldId: 'reactour-mergeFieldClient',
  },
  {
    name: 'Contract',
    value: [
      { text: 'Client Sigature', name: 'ClientSignature' },
      { text: 'Client Signed Date', name: 'ClientSignedDate' },
      { text: 'Commencement Date', name: 'CommencementDate' },
      { text: 'Date Submitted', name: 'ContractDateSubmitted' },
      { text: 'Document Package', name: 'ContractDocumentPackage' },
      { text: 'Manager', name: 'ContractManager' },
      { text: 'Manager Email', name: 'ContractManagerEmail' },
      { text: 'Manager Position', name: 'ContractManagerPosition' },
      { text: 'Offer Expiry Date', name: 'ContractOfferExpiryDate' },
      { text: 'Require Position', name: 'ContractReqPosition' },
      { text: 'Require Profile Picture', name: 'ContractReqProfilePicture' },
      { text: 'Require Qualification', name: 'ContractReqQualifications' },
      { text: 'Require Work Rights', name: 'ContractReqWorkRights' },
      { text: 'Total Hours', name: 'ContractTotalHours' },
      { text: 'Contract Date', name: 'Date' },
      { text: 'Employee Signature', name: 'EmployeeSignature' },
      { text: 'Employee Signed Date', name: 'EmployeeSignedDate' },
    ],
    fieldId: 'reactour-mergeFieldContract',
  },
];

export const LetterDefaultMergeFields = [
  {
    name: 'Worker',
    value: workerMergeField,
    fieldId: 'reactour-mergeFieldWorker',
  },
  {
    name: 'Client',
    value: [
      { text: 'Client Name', name: 'ClientName' },
      { text: 'Client Has Default Super Fund', name: 'ClientHasDefaultSuperFund' },
      { text: 'Client Default Super Fund', name: 'ClientDefaultSuperFund' },
      { text: 'Client Physical Address', name: 'ClientPhysicalAddress' },
      { text: 'Client Physical Suburb', name: 'ClientPhysicalSuburb' },
      { text: 'Client Physical State', name: 'ClientPhysicalState' },
      { text: 'Client Physical Code', name: 'ClientPhysicalCode' },
      { text: 'Client Postal Address', name: 'ClientPostalAddress' },
      { text: 'Client Postal Suburb', name: 'ClientPostalSuburb' },
      { text: 'Client Postal State', name: 'ClientPostalState' },
      { text: 'Client Postal Code', name: 'ClientPostalCode' },
      { text: 'Client ABN', name: 'ClientABN' },
      { text: 'Client Logo Large', name: 'ClientLogoLarge' },
      { text: 'Client Logo Portal', name: 'ClientLogoPortal' },
      { text: 'Client Logo Small', name: 'ClientLogoSmall' },
    ],
    fieldId: 'reactour-mergeFieldClient',
  },
  {
    name: 'Letter',
    value: [
      { text: 'Author Name', name: 'AuthorName' },
      { text: 'Author Phone', name: 'AuthorPhone' },
      { text: 'Author Position', name: 'AuthorPosition' },
      { text: 'Date Sent', name: 'DateSent' },
      { text: 'Date Acknowledged', name: 'DateAcknowledged' },
      { text: 'Approver Name', name: 'ApproverName' },
      { text: 'Approver Position', name: 'ApproverPosition' },
      { text: 'Approver Signature', name: 'ApproverSignature' },
      { text: 'Employee Signature', name: 'EmployeeSignature' },
    ],
    fieldId: 'reactour-mergeFieldletter',
  },
];

export const contentStatus = {
  Draft: 1,
  Published: 2,
  Retired: 3,
  DraftReleased: 4,
};

export const lectureType = [
  {
    attr: 'A',
    full: 'article',
    value: 'article',
  },
  {
    attr: 'S',
    full: 'slide',
    value: 'slide',
  },
  {
    attr: 'V',
    full: 'video',
    value: 'video',
  },
];

export const sectionContentType = [
  {
    attr: 'L',
    full: 'lecture',
    value: 'lecture',
  },
  {
    attr: 'A',
    full: 'assessment',
    value: 'assessment',
  },
];
