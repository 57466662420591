import env from './env';
import { templateEngine } from './util';

class ApiUrlHelper {
  private static instance: ApiUrlHelper;
  private _urls: any;
  private _mockedEndpoint: string;

  private constructor() {
    this._urls = {
      CALLBACK: '/api/account/me',
      CLAIMS_URL: '/api/Users/claims',
      PARTNER_URL: '/api/PartnerCompanies',
      PARTNER_USER_URL: '/api/PartnerCompanies/<%id%>/users',
      PARTNER_USER_ACTIVATE_URL: '/api/PartnerCompanies/<%id%>/users/<%userID%>/activation',
      PARTNER_INVITATION_URL: '/api/PartnerCompanies/<%id%>/resendinvitation/<%userID%>',
      PARTNER_USER: '/api/Users',
      LETTER_CATEGORY: '/api/Lookup/lettercategories',
      COURSE_URL: '/api/Courses',
      COURSE_FILE_UPLOAD_URL: '/api/Courses/<%id%>/uploadcourse<%type%>',
      SCORM_COURSE_FILE_UPLOAD_URL: '/api/courses/<%id%>/scorm/externalfile',
      GET_SECTION_URL: '/api/Courses/sections',
      MEMBER_PREFERENCE: '/api/account/me/preferences',
      COURSE_SECTION_URL: '/api/Courses/<%id%>/sections',
      SECTION_ITEM_URL: '/api/courses/sections/items',
      FILE_UPLOAD: '/api/Files/uploadfile/<%id%>',
      GET_LECTURE_URL: '/api/courses/sections/items/lectures',
      UPLOAD_COURSES_RESOURCES: '/api/Courses/<%courseID%>/uploadcourse<%resourceType%>',
      ASSESSMENT_URL: '/api/courses/<%id%>/sections/<%sectionID%>/items/assessments',
      GET_ASSESSMENT_QUESTION_URL:
        '/api/courses/sections/items/assessments/<%assessmentID%>/questions',
      ASSESSMENT_QUESTION_URL:
        '/api/courses/<%id%>/sections/<%sectionID%>/items/assessments/<%assessmentID%>/questions',
      ASSESSMENT_QUESTION_IMAGE_UPLOAD:
        '/api/courses/<%id%>/sections/<%sectionID%>/items/<%assessmentID%>/asessment-resources',
      LECTURE_URL: '/api/courses/<%id%>/sections/<%sectionID%>/items/lectures',
      LECTURE_FILE_URL:
        '/api/courses/<%id%>/sections/<%sectionID%>/items/lectures/<%lectureID%>/uploadfile',
      ARTICLE_IMAGE_UPLOAD: '/api/courses/<%id%>/sections/<%sectionID%>/items/<%lectureID%>',
      HANDBOOK_URL: '/api/handbooks',
      HANDBOOK_SECTION_URL: '/api/handbooks/<%id%>/sections',
      GET_HANDBOOK_CONTENT_URL: '/api/handbooks/sections/contents',
      HANDBOOK_CONTENT_URL: '/api/handbooks/<%id%>/sections/<%sectionID%>/contents',
      HANDBOOK_FILEUPLOAD:
        '/api/handbooks/<%id%>/sections/<%sectionID%>/contents/<%contentId%>/fileupload',
      HANDBOOK_RESOURCE_URL:
        '/api/handbooks/<%id%>/sections/<%sectionID%>/contents/<%contentId%>/resources',
      HANDBOOK_IMAGE_UPLOAD: '/api/handbooks/<%id%>/imageupload',
      PREVIEW_URL: '/api/files/preview',
      WORKFLOW_URL: '/api/workflow',
      WORKFLOW_STEP_URL: '/api/workflow/<%id%>/steps',
      WORKFLOW_FILE_URL: '/api/Workflow/<%id%>/workflow-image',
      WORKFLOW_CHART_URL: '/api/Workflow/<%id%>/workflow-flowchart',
      WORKFLOW_STEP_DIALOG: '/api/workflow/<%id%>/steps/<%stepID%>/dialogs',
      WORKFLOW_STEP_CONTRACT: '/api/workflow/<%id%>/steps/<%stepID%>/contracts',
      WORKFLOW_STEP_LETTER: '/api/workflow/<%id%>/steps/<%stepID%>/letters',
      WORKFLOW_STEP_DOCUMENT: '/api/workflow/<%id%>/steps/<%stepID%>/documents',
      WORKFLOW_STEP_EVALUATION: '/api/workflow/<%id%>/steps/<%stepID%>/evaluationrules',
      WORKFLOW_STEP_QUESTION: '/api/workflow/<%id%>/steps/<%stepID%>/questions',
      WORKFLOW_DOC_DOWNLOAD: '/api/workflow/<%id%>/steps/<%stepId%>/document-download',
      WORKFLOW_PREVIEW_DOWNLOAD: '/api/workflow/<%id%>/steps/<%stepId%>/document-download/pdf',
      WORKFLOW_STEP_TASK: '/api/workflow/<%id%>/steps/<%stepID%>/tasks',
      GET_ASSESSMENT_URL: '/api/courses/sections/items/assessments',
      COURSE_CATEGORY_LOOKUP: '/api/Lookup/courses/category',
      COURSE_LEVEL_LOOKUP: '/api/Lookup/courselevel',
      COURSE_STATUS_LOOKUP: '/api/Lookup/coursestatus',
      HANDBOOK_CATEGORY_LOOKUP: '/api/Lookup/handbook/category',
      HANDBOOK_STATUS_LOOKUP: '/api/Lookup/handbook/status',
      TIMEZONE_LOOKUP: '/api/Lookup/operatingtimezone',
      COUNTRY_LOOKUP: '/api/Lookup/countries',
      STATE_LOOKUP: '/api/Lookup/states',
      SECTION_URL: '/api/section',
      SECTION_PARAGRAPH_URL: '/api/section/<%clauseID%>/paragraphs',
      SECTION_PARAGRAPH_CLONE_URL: '/api/section/<%clauseID%>/paragraphs/<%paragraphID%>/clone',
      SECTION_CONTRACT: '/api/Contracts/<%id%>/sections',
      COURSE_SECTION_SORT: '/api/Contracts/<%id%>/sections/sort',
      LETTER_LIST: '/api/letters',
      CREATE_LETTER: '/api/letters',
      UPDATE_LETTER: '/api/letters/<%assetID%>',
      LETTER_DETAIL: '/api/letters/<%assetID%>/version/<%versionNumber%>',
      LETTER_PUBLISH: '/api/Letters/<%assetID%>/draft/publish',
      LETTER_RETIRE: '/api/Letters/<%assetID%>/version/<%versionNumber%>/retire',
      LETTER_DRAFT: '/api/Letters/<%assetID%>/draft',
      LETTER_VERSION: '/api/Letters/<%assetID%>/version-history',
      LETTER_PREVIEW_MERGEFIELD_URL: '/api/letters/<%assetID%>/latest/workflows/preview',
      UPLOAD_LETTER_FILE: '/api/Files/uploadfile/<%id%>',
      CONTRACT_LIST: '/api/contracts',
      CONTRACT_TYPE: '/api/ContractTypes',
      CREATE_CONTRACT: '/api/Contracts',
      UPDATE_CONTRACT: '/api/Contracts/<%assetID%>',
      UPLOAD_CONTRACT_FILE: '/api/Files/uploadfile/<%id%>',
      CONTRACT_DETAIL: '/api/contracts/<%assetID%>/version/<%versionNumber%>',
      CONTRACT_PUBLISH: '/api/Contracts/<%assetID%>/draft/publish',
      CONTRACT_RETIRE: '/api/Contracts/<%assetID%>/version/<%versionNumber%>/retire',
      CONTRACT_DRAFT: '/api/Contracts/<%assetID%>/draft',
      CONTRACT_VERSION: '/api/Contracts/<%assetID%>/version-history',
      CONTRACT_PREVIEW_VERSION_URL: '/api/Contracts/<%assetID%>/version/<%versionNumber%>/preview',
      CONTRACT_PREVIEW:
        '/api/Contracts/<%assetID%>/version/<%versionNumber%>/preview-with-sections',
      LETTER_PREVIEW_VERSION_URL: '/api/Letters/<%assetID%>/version/<%versionNumber%>/preview',
      CLIENT_URL: '/api/partner-clients',
      SFDT_URL: '/api/files/<%id%>/getsfdt',
    };
    this._mockedEndpoint = 'https://6aa1093e-81d5-4f50-9974-41213d483235.mock.pstmn.io';
  }

  static getInstance() {
    if (!ApiUrlHelper.instance) {
      ApiUrlHelper.instance = new ApiUrlHelper();
    }
    return ApiUrlHelper.instance;
  }

  public constructEndpoint({ endpoint, options = {}, isMocked = false }: any) {
    return (
      (isMocked ? this._mockedEndpoint : env.baseurl) +
      templateEngine(this._urls[endpoint], options)
    );
  }
}

const instance = ApiUrlHelper.getInstance();
Object.freeze(instance);

export default instance;
