import React from 'react';

import Badge from '../../common/Badge';

interface IPageTitle {
  pageTitle?: string;
  badge?: any;
}

const PageTitle = ({ pageTitle, badge }: IPageTitle) => {
  return (
    <>
      <h1 className='text-xl ml-1.5'>
        {pageTitle}{' '}
        {badge && (
          <Badge className={'ml-5'} type={badge.class}>
            {badge.statusName}
          </Badge>
        )}
      </h1>
    </>
  );
};

export default PageTitle;
