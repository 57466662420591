export const UploadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      fill='none'
      viewBox='0 0 80 80'
      {...props}
    >
      <circle
        cx={40.964}
        cy={38.964}
        r={27.271}
        fill='url(#icon_document_upload_svg__a)'
        opacity={0.1}
      />
      <path
        stroke='url(#icon_document_upload_svg__b)'
        strokeLinecap='round'
        strokeWidth={1.5}
        d='M32.096 5.868a34.263 34.263 0 0 0-13.583 58.979m22.7 8.38a34.264 34.264 0 0 0 22.575-8.708M74.98 43.066A34.263 34.263 0 0 0 49.35 5.743'
      />
      <path
        fill='url(#icon_document_upload_svg__c)'
        fillRule='evenodd'
        d='M39.813 28.346v16.512a1.031 1.031 0 0 0 2.062 0V28.22l5.93 5.93a1.031 1.031 0 0 0 1.458-1.46l-7.626-7.625a1.031 1.031 0 0 0-.793-.302c-.395 0-.738.221-.911.547l-7.38 7.38a1.031 1.031 0 0 0 1.458 1.46l5.802-5.803Zm-12.026 14.21c.57 0 1.031.462 1.031 1.031v4.876c0 .95.77 1.72 1.72 1.72h19.92c.949 0 1.718-.77 1.718-1.72v-4.876a1.031 1.031 0 1 1 2.063 0v4.876a3.781 3.781 0 0 1-3.781 3.782h-19.92a3.781 3.781 0 0 1-3.782-3.782v-4.876c0-.57.462-1.031 1.031-1.031Z'
        clipRule='evenodd'
      />
      <defs>
        <linearGradient
          id='icon_document_upload_svg__a'
          x1={13.693}
          x2={68.234}
          y1={66.235}
          y2={11.693}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3991FF' />
          <stop offset={0.502} stopColor='#A48AFB' />
          <stop offset={1} stopColor='#FA7066' />
        </linearGradient>
        <linearGradient
          id='icon_document_upload_svg__b'
          x1={8.151}
          x2={76.651}
          y1={79.964}
          y2={-5.036}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3991FF' />
          <stop offset={0.502} stopColor='#A48AFB' />
          <stop offset={1} stopColor='#FA7066' />
        </linearGradient>
        <linearGradient
          id='icon_document_upload_svg__c'
          x1={26.756}
          x2={54.239}
          y1={24.762}
          y2={52.245}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3991FF' />
          <stop offset={0.502} stopColor='#A48AFB' />
          <stop offset={1} stopColor='#FA7066' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const BuildYourOwnIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      fill='none'
      viewBox='0 0 80 80'
      {...props}
    >
      <circle
        cx={40.964}
        cy={38.964}
        r={27.271}
        fill='url(#icon_document_build_svg__a)'
        opacity={0.1}
      />
      <path
        stroke='url(#icon_document_build_svg__b)'
        strokeLinecap='round'
        strokeWidth={1.5}
        d='M32.096 5.868a34.263 34.263 0 0 0-13.583 58.979m22.7 8.38a34.264 34.264 0 0 0 22.575-8.708M74.98 43.066A34.263 34.263 0 0 0 49.35 5.743'
      />
      <path
        fill='url(#icon_document_build_svg__c)'
        fillRule='evenodd'
        d='M30.875 25.094a3.781 3.781 0 0 0-3.781 3.781V33a3.781 3.781 0 0 0 3.781 3.781h19.25A3.781 3.781 0 0 0 53.906 33v-4.125a3.781 3.781 0 0 0-3.781-3.781h-19.25Zm-1.719 3.781c0-.95.77-1.719 1.719-1.719h19.25c.95 0 1.719.77 1.719 1.719V33c0 .95-.77 1.719-1.719 1.719h-19.25c-.95 0-1.719-.77-1.719-1.719v-4.125Zm1.719 11.344A3.781 3.781 0 0 0 27.094 44v4.125a3.781 3.781 0 0 0 3.781 3.781h19.25a3.781 3.781 0 0 0 3.781-3.781V44a3.781 3.781 0 0 0-3.781-3.781h-19.25ZM29.156 44c0-.95.77-1.719 1.719-1.719h19.25c.95 0 1.719.77 1.719 1.719v4.125c0 .95-.77 1.719-1.719 1.719h-19.25c-.95 0-1.719-.77-1.719-1.719V44Z'
        clipRule='evenodd'
      />
      <defs>
        <linearGradient
          id='icon_document_build_svg__a'
          x1={13.693}
          x2={68.234}
          y1={66.235}
          y2={11.693}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3991FF' />
          <stop offset={0.502} stopColor='#A48AFB' />
          <stop offset={1} stopColor='#FA7066' />
        </linearGradient>
        <linearGradient
          id='icon_document_build_svg__b'
          x1={8.151}
          x2={76.651}
          y1={79.964}
          y2={-5.036}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3991FF' />
          <stop offset={0.502} stopColor='#A48AFB' />
          <stop offset={1} stopColor='#FA7066' />
        </linearGradient>
        <linearGradient
          id='icon_document_build_svg__c'
          x1={27.094}
          x2={53.906}
          y1={25.094}
          y2={51.906}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3991FF' />
          <stop offset={0.502} stopColor='#A48AFB' />
          <stop offset={1} stopColor='#FA7066' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const UploadFileIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='74'
      height='72'
      viewBox='0 0 74 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g filter='url(#filter0_d_1235_51932)'>
        <path
          d='M24.2695 8.5H43.5791L57.7695 21.1424V49.8727C57.7695 53.4801 54.4675 56.5 50.2695 56.5H24.2695C20.0715 56.5 16.7695 53.4801 16.7695 49.8727V15.1273C16.7695 11.5199 20.0715 8.5 24.2695 8.5Z'
          fill='white'
          stroke='#DCDFEA'
        />
        <rect
          opacity='0.63'
          x='23.3086'
          y='24.0479'
          width='10.2267'
          height='1.66974'
          rx='0.834871'
          fill='#DCDFEA'
        />
        <rect
          opacity='0.4'
          x='25.1445'
          y='24.0479'
          width='2.88199'
          height='1.66974'
          rx='0.834871'
          fill='#B9C0D4'
        />
        <rect
          opacity='0.4'
          x='40.5557'
          y='24'
          width='3.93645'
          height='1.53408'
          rx='0.767038'
          fill='#B9C0D4'
        />
        <rect
          opacity='0.63'
          x='32.75'
          y='24.0479'
          width='8.84687'
          height='1.66974'
          rx='0.834871'
          fill='#DCDFEA'
        />
        <rect
          opacity='0.6'
          x='23.2695'
          y='29.7969'
          width='27'
          height='1.66974'
          rx='0.834871'
          fill='#DCDFEA'
        />
        <rect
          opacity='0.3'
          x='29.6963'
          y='29.7969'
          width='2.21084'
          height='1.66974'
          rx='0.834871'
          fill='#B9C0D4'
        />
        <rect
          opacity='0.3'
          x='37.959'
          y='29.7969'
          width='3.67249'
          height='1.66974'
          rx='0.834871'
          fill='#B9C0D4'
        />
        <rect
          opacity='0.6'
          x='23.3086'
          y='35.5459'
          width='15.7629'
          height='1.66974'
          rx='0.834871'
          fill='#DCDFEA'
        />
        <rect
          opacity='0.15'
          x='26.9814'
          y='35.5459'
          width='3.67249'
          height='1.66974'
          rx='0.834871'
          fill='#B9C0D4'
        />
        <rect
          opacity='0.6'
          x='45.3359'
          y='35.7617'
          width='3.3741'
          height='1.53408'
          rx='0.767038'
          fill='#DCDFEA'
        />
        <rect
          opacity='0.6'
          x='23.2695'
          y='41.3301'
          width='6.52163'
          height='1.66974'
          rx='0.834871'
          fill='#DCDFEA'
        />
        <path d='M43.1836 9V13C43.1836 17.4183 46.7653 21 51.1836 21H57.6836' stroke='#DCDFEA' />
      </g>
      <circle cx='21.2695' cy='10' r='10' fill='#0B78FF' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.8945 5.625C21.8945 5.27982 21.6147 5 21.2695 5C20.9244 5 20.6445 5.27982 20.6445 5.625L20.6445 9.16667H16.8945C16.5494 9.16667 16.2695 9.44649 16.2695 9.79167C16.2695 10.1368 16.5494 10.4167 16.8945 10.4167H20.6445L20.6445 14.375C20.6445 14.7202 20.9244 15 21.2695 15C21.6147 15 21.8945 14.7202 21.8945 14.375L21.8945 10.4167H25.6445C25.9897 10.4167 26.2695 10.1368 26.2695 9.79167C26.2695 9.44649 25.9897 9.16667 25.6445 9.16667H21.8945L21.8945 5.625Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_d_1235_51932'
          x='6.26953'
          y='2.12'
          width='62'
          height='69'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4.12' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.25098 0 0 0 0 0.286275 0 0 0 0 0.407843 0 0 0 0.08 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1235_51932' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1235_51932'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export const BadgeV2Icon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill={props.color} {...props}>
      <path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z' />
    </svg>
  );
};
