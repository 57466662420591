import { useEffect, useState } from 'react';

import { Button, Drawer, message } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ClauseDetail } from '../../../@types/clause';
import { JSONQuestionModel } from '../../../@types/global';
import HeaderActions from '../../../common/headerActions';
import Loading from '../../../common/Loading';
import PublishSettings from '../../../components/Document/Setting';
import { isNullUndefined, reFormatClauseQuestion } from '../../../helpers/util';
import history from '../../../routes/history';
import { RootDispatch, RootState } from '../../../store';

interface IClauseEditAction {
  clause: ClauseDetail;
  updateClause: Function;
  questions: Array<JSONQuestionModel>;
}

const ClauseEditAction = ({ clause, updateClause }: IClauseEditAction) => {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [settings, setSettings] = useState<any>({});

  useEffect(() => {
    if (clause) {
      setSettings({
        name: clause.name,
        uniqueCode: clause.uniqueCode,
        isMandatory: clause.isMandatory,
        isRecommended: clause.isRecommended,
        description: clause.description,
        guidance: clause.guidance,
      });
    }
  }, [clause]);

  if (isNullUndefined(clause)) return <Loading />;

  const validActions = [
    { name: 'Edit', style: 'primary', visible: true },
    { name: 'Close', style: 'default', visible: true },
  ];

  const PublishConfig = [
    {
      label: 'Clause title',
      type: 'textbox',
      placeholder: 'Section title',
      key: 'name',
    },
    {
      label: 'Unique Code',
      type: 'textbox',
      placeholder: 'Unique code',
      key: 'uniqueCode',
    },
    {
      label: 'Mandatory',
      type: 'switch',
      display: true,
      placeholder: 'Mandatory',
      key: 'isMandatory',
    },
    {
      label: 'Is Recommended',
      type: 'switch',
      display: !settings.isMandatory,
      placeholder: 'Is Recommended',
      key: 'isRecommended',
    },
    {
      label: 'Write a brief description of this clause.',
      subLabel: 'This text will not be available to clients. It is for internal use.',
      type: 'simple-textarea',
      placeholder: 'description',
      key: 'description',
    },
    {
      label: 'Guidance',
      subLabel: 'This is a guide text that will be seen by the client.',
      type: 'textarea',
      display: true,
      placeholder: 'guidance',
      key: 'guidance',
    },
  ];
  const onUpdateClause = () => {
    const { name, description, isMandatory, guidance, isRecommended, uniqueCode } = settings;
    if (_.isUndefined(name) || _.isEmpty(name.trim())) {
      return message.error('Clause should contain title');
    }
    if (name.length > 200) {
      return message.error("title shouldn't exceed max length");
    }
    if (uniqueCode && uniqueCode.length > 15) {
      return message.error('Unique code cannot be greater than 15 character long');
    }

    setLoading(true);
    updateClause({
      options: { clauseID: id },
      payLoad: {
        name,
        description,
        isMandatory: isMandatory || false,
        guidance,
        isRecommended: isRecommended || false,
        uniqueCode,
        questions: reFormatClauseQuestion(),
      },
    }).then(
      (response: any) => {
        setLoading(false);
        if (response?.errorData?.length) {
          return message.error(response?.errorData[0].errorMessage.message);
        } else {
          setSettingsVisible(false);
          return message.success('Updated clause settings');
        }
      },
      () => {
        setLoading(false);
        return message.error('Error in clause update');
      },
    );
  };

  const handleActionClick = (action: string) => {
    if (action === 'Edit') {
      setSettingsVisible(true);
    } else {
      history.push('/clause');
    }
  };

  const updateContractSetting = (changes: any) => {
    setSettings({ ...settings, ...changes });
  };

  return (
    <>
      <HeaderActions actions={validActions} handleActionClick={handleActionClick} />
      <Drawer
        title={
          <div>
            <div className={'float-left'}>Clause settings</div>
            <div className={'float-right'}>
              <Button className={'mr-2'} onClick={() => setSettingsVisible(false)}>
                {' '}
                Cancel{' '}
              </Button>
              <Button
                type={'primary'}
                className={'m-l-8'}
                loading={loading}
                onClick={() => onUpdateClause()}
              >
                {' '}
                Save{' '}
              </Button>
            </div>
            <div className={'clearfix'}></div>
          </div>
        }
        placement='right'
        width={600}
        closable={false}
        onClose={() => setSettingsVisible(false)}
        visible={settingsVisible}
      >
        <PublishSettings
          config={PublishConfig}
          id={id}
          visible={settingsVisible}
          initialValue={settings}
          updateSettings={updateContractSetting}
          content={clause}
        />
      </Drawer>
    </>
  );
};

const mapProps = (state: RootState) => ({
  clause: state.clause.clause,
  questions: state.clause.questions,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  updateClause: dispatch.clause.updateClause,
});

export default connect(mapProps, mapDispatch)(ClauseEditAction);
