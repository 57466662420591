import React from 'react';

import { Avatar, Button, Card, Col, Dropdown, List, Menu, Row, Table } from 'antd';
import _ from 'lodash';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';

import { LgEllipsis } from '../../common/assets';
import { monthsSuffix } from '../../common/constants/entities';
import { getRandomNumber, mapContentType } from '../../helpers/util';

interface IUsageData {
  month: string;
  city: string;
  temperature: number;
}

const Dashboard = () => {
  const data = [
    {
      title: 'Luis Jenkins sent you a message',
      description: '2 days ago',
      avatar: 'https://randomuser.me/api/portraits/men/64.jpg',
    },
    {
      title: 'Brianna Walters sent you mail',
      description: '5 days ago',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    },
    {
      title: 'Jonathan Chen sent you a message',
      description: '7 days ago',
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
    },
    {
      title: 'Lena Duncan sent you mail',
      description: '9 days ago',
      avatar: 'https://randomuser.me/api/portraits/women/55.jpg',
    },
    {
      title: 'Michele Phillips sent you a message',
      description: '11 days ago',
      avatar: 'https://randomuser.me/api/portraits/women/56.jpg',
    },
    {
      title: 'John Mayor sent you a message',
      description: '7 days ago',
      avatar: 'https://randomuser.me/api/portraits/women/26.jpg',
    },
  ];

  const resourcesData = [
    {
      title: 'FT or PT Employment Contract',
      type: 'C',
      iconcolor: 'white',
      bgcolor: 'bg-primary',
      description: '413 workers engaged',
      publish: 'Today',
    },
    {
      title: 'Termination Letter',
      type: 'L',
      bgcolor: 'bg-danger',
      description: '393  workers engaged',
      publish: 'Yesterday',
    },
    {
      title: 'Workforce Safety Instruction',
      type: 'Co',
      bgcolor: 'bg-success',
      description: '359  workers engaged',
      publish: 'Aug 25',
    },
    {
      title: 'Managing misconduct',
      type: 'W',
      bgcolor: 'bg-warning',
      description: '213  workers engaged',
      publish: 'Aug 19',
    },
    {
      title: 'Casual Contract',
      type: 'C',
      iconcolor: 'white',
      bgcolor: 'bg-primary',
      description: '141 workers engaged',
      publish: 'Aug 18',
    },
    {
      title: 'Warning Letter',
      type: 'L',
      bgcolor: 'bg-danger',
      description: '127  workers engaged',
      publish: 'Aug 17',
    },
  ];

  const usageData: Array<IUsageData> = [];

  _.each(monthsSuffix, (month: string) => {
    usageData.push({
      month,
      city: 'Contract',
      temperature: Math.floor(getRandomNumber() * 6) + 1,
    });
    usageData.push({
      month,
      city: 'Letter',
      temperature: Math.floor(getRandomNumber() * 6) + 1,
    });
    usageData.push({
      month,
      city: 'Workflow',
      temperature: Math.floor(getRandomNumber() * 6) + 1,
    });
    usageData.push({
      month,
      city: 'Course',
      temperature: Math.floor(getRandomNumber() * 6) + 1,
    });
  });

  const dataSource = [
    {
      id: '426e4d87-801a-4510-8d1c-258a719a754b',
      title: 'Important notice for System Analysis',
      resources: 'Full time Award Contract',
      date_published: '2016-01-20',
      date_updated: '2015-04-19',
      status: 2,
    },
    {
      id: '28ca0bed-9ceb-4083-9c47-236a1256e4b1',
      resources: 'Casual Award Contract',
      title: 'New contract updates need to review',
      date_published: '2015-01-28',
      date_updated: '2015-03-30',
      status: 1,
    },
    {
      id: '74c23668-ed53-4aee-b82c-66fc155a5e2d',
      resources: 'Full time Award Contract',
      title: 'Mobile-friendly tool for unscrambling words',
      date_published: '2019-02-21',
      date_updated: '2018-02-20',
      status: 2,
    },
    {
      id: '71498050-82ef-4d2f-9915-b35fd3062489',
      resources: 'Part Time Non-Award',
      title: '7 letter words made by unscrambling the letters',
      date_published: '2019-05-24',
      date_updated: '2014-05-30',
      status: 3,
    },
    {
      id: '71498050-82ef-4d2f-9915-b32fd3062e89',
      resources: 'Part Time Non-Award',
      title: 'Lorem ipsum dolor meita and chees',
      date_published: '2019-05-24',
      date_updated: '2014-05-30',
      status: 3,
    },
    {
      id: '71498050-82ef-4dxf-9915-b35fd3062e89',
      resources: 'Part Time Non-Award',
      title: 'In publishing and graphic design',
      date_published: '2019-05-24',
      date_updated: '2014-05-30',
      status: 3,
    },
  ];

  const columnResource = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record: number) => {
        let statusNode;
        switch (record) {
          case 1:
            statusNode = <span className='badge badge-green'>{'Published'}</span>;
            break;
          case 2:
            statusNode = <span className='badge badge-warning'>{'Pending'}</span>;
            break;
          default:
            statusNode = <span className='badge badge-default'>{'Draft'}</span>;
            break;
        }
        return statusNode;
      },
    },
  ];

  const lineDate = {
    labels: ['Jan', 'Feb', 'Mar', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Contracts',
        data: [12, 19, 3, 5, 22, 3],
        fill: false,
        borderColor: '#0b78ff',
        backgroundColor: '#bbd6f6',
        tension: 0.5,
      },
      {
        label: 'Letters',
        data: [40, 19, 30, 51, 2, 13],
        fill: false,
        borderColor: '#FF8D86',
        backgroundColor: '#ecc4c1',
        tension: 0.5,
      },
      {
        label: 'Course',
        data: [59, 9, 32, 25, 12, 23],
        fill: false,
        borderColor: '#DFF5E2',
        backgroundColor: '#dfece0',
        tension: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <React.Fragment>
      <div>
        <div className='grid grid-cols-2 md:grid-cols-2 xs:grid-cols-1 gap-8 mt-10'>
          <div>
            <div aria-label='doughnutChart' className='portlet portlet--white'>
              <div className='portlet__title'>Client Engagement</div>
              <div className='portlet__body pt-4'>
                <div className='px-12'>
                  <Line data={lineDate} options={options} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div aria-label='doughnutChart' className='portlet portlet--white'>
              <div className='portlet__title'>Usage statistics</div>
              <div className='portlet__body pt-4'>
                <div className='px-12'>
                  <Bar data={lineDate} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-4 md:grid-cols-4 xs:grid-cols-1 gap-8 mt-10'>
          <div>
            <div aria-label='doughnutChart' className='portlet portlet--white'>
              <div className='portlet__title'>Most used resources</div>
              <div className='portlet__body pt-4'>
                <div className='mb-2 mt-4 width-full'>
                  <List
                    itemLayout='horizontal'
                    dataSource={resourcesData}
                    renderItem={(item) => {
                      const content = mapContentType(item.type);
                      return (
                        <List.Item
                          className='bg-gray-100 border border-white hover:border-gray-400 rounded-md mb-2 px-4 hover:shadow-lg cursor-pointer'
                          style={{ borderBottomColor: '#fff' }}
                        >
                          <Avatar
                            size='large'
                            className='text-black uppercase font-medium bg-gray-400'
                          >
                            {content && content.type}
                          </Avatar>
                          <List.Item.Meta
                            title={<button className={'bg-none b-0 p-0'}>{item.title}</button>}
                            description={item.description}
                            className='ml-4'
                          />
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div aria-label='doughnutChart' className='portlet portlet--white'>
              <div className='portlet__title'>New clients</div>
              <div className='portlet__body pt-4'>
                <div className='mb-2 mt-4 width-full'>
                  <List
                    itemLayout='horizontal'
                    dataSource={data}
                    renderItem={(item, index) => (
                      <List.Item
                        key={index}
                        className='bg-gray-100 border border-white hover:border-gray-400 rounded-md mb-2 px-4 hover:shadow-lg cursor-pointer'
                        style={{ borderBottomColor: '#fff' }}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src={item.avatar} size='large' />}
                          title={<a href='/support'>{item.title}</a>}
                          description={item.description}
                          className='p-lr-16 flex flex-center'
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div aria-label='doughnutChart' className='portlet portlet--white'>
              <div className='portlet__title'>Recently Updated</div>
              <div className='portlet__body pt-4'>
                <div className='mb-2 mt-4 width-full'>
                  <List
                    itemLayout='horizontal'
                    dataSource={resourcesData}
                    renderItem={(item, index) => {
                      const content = mapContentType(item.type);
                      return (
                        <List.Item
                          key={index}
                          className='bg-gray-100 border border-white hover:border-gray-400 rounded-md mb-2 px-4 hover:shadow-lg cursor-pointer'
                          style={{ borderBottomColor: '#fff' }}
                        >
                          <Avatar
                            size='large'
                            className='text-black uppercase font-medium bg-gray-400'
                          >
                            {content && content.type}
                          </Avatar>
                          <List.Item.Meta
                            title={<button className={'bg-none b-0 p-0'}>{item.title}</button>}
                            className='ml-4'
                          />
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div aria-label='doughnutChart' className='portlet portlet--white'>
              <div className='portlet__title'>Recent News Posts</div>
              <div className='portlet__body'>
                <div className='mb-2 width-full'>
                  <Table
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    columns={columnResource}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
