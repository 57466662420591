import _ from 'lodash';

import { isNullUndefined } from './util';

class IframeHelper {
  private static instance: IframeHelper;
  private _config?: any;

  constructor() {
    if (!IframeHelper.instance) {
      IframeHelper.instance = this;
    }
    this._config = {};
    return IframeHelper.instance;
  }

  isIframeScreen() {
    return !isNullUndefined(this._config.access_token);
  }

  setConfig(obj: any) {
    _.merge(this._config, obj);
  }

  getConfig() {
    return this._config;
  }
}

const instance = new IframeHelper();
Object.freeze(instance);

export default instance;
