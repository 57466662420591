import env from './env';

export class Constants {
  public static stsAuthority = env.identity_baseurl;
  public static clientId = 'expedo.advisory';
  // public static clientRoot = 'http://localhost:4200/';
  public static clientScope = 'openid profile email address standard_api IdentityServerApi';
  public static clientCallbackUrl = env.redirect_url;
  public static clientRedirectUrl = env.callback_url;

  public static apiRoot = env.baseurl;
}
