import axios from 'axios';
import { saveAs } from 'file-saver';
import _ from 'lodash';

import env from './env';
import IframeHandler from './IframeHelper';
import { QueryFilter } from '../@types/global';
import { authService } from '../services/AuthService';

/**
 * Axios defaults
 */
axios.defaults.baseURL = env.baseurl;

// Headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

/**
 * Request Interceptor
 */

axios.interceptors.request.use(
  async (inputConfig) => {
    const config = inputConfig;
    let token = '';
    if (IframeHandler.isIframeScreen()) {
      const iframeConfig = IframeHandler.getConfig();
      token = iframeConfig.access_token;
      config.headers.common.Authorization = `Ex-AccessToken ${token}`;
      config.headers.common['X-BaseUrl'] = iframeConfig.base_url;
      config.headers.common['X-UserId'] = iframeConfig.userid;
    } else {
      try {
        token = await authService.getAccessToken();
      } catch (error) {
        /* Nothing */
      }
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

/**
 * Response Interceptor
 */

const onError = async (error: any) => {
  if (axios.isCancel(error)) {
    return { message: error.message, cancelled: true };
  }
  if (error.response) {
    let { description } = error.response.data;
    if (error.response.status === 503) {
      description = 'Service Unavailable';
    }

    // Handle Unauthorized response
    if (error.response.status === 401) {
      if (!error.config._retry) {
        const newConfig = error.config;
        newConfig._retry = true;
        await authService.renewToken();
        const token = await authService.getAccessToken();
        newConfig.headers['Authorization'] = `Bearer ${token ?? ''}`;
        return axios(newConfig);
      } else {
        return authService.logout();
      }
    }
    return {
      message: description || 'Internal Server Error',
      response: error.response,
      errorData: error.response.data,
    };
  } else if (error.request) {
    return { message: 'The request was made but no response was received' };
  } else {
    return { message: error.message || 'Something happened in setting up the request' };
  }
};

axios.interceptors.response.use((res) => {
  // Status code isn't a success code - throw error
  if (!`${res.status}`.startsWith('2')) {
    throw res.data;
  }

  // Otherwise just return the data
  return res;
}, onError);

const requestPayloadWrapper = function (method: any) {
  return async function (url: string, data?: any) {
    return axios({
      method,
      url,
      data,
    });
  };
};

const requestWrapper = function (method: any) {
  return async function (url: string, params?: QueryFilter, cancelToken?: any) {
    return axios({
      method,
      url,
      params,
      cancelToken,
    });
  };
};

const requestFileWrapper = function (method: any) {
  return async function (url: string, data?: any) {
    return axios({
      url: url,
      method: method,
      data,
      responseType: 'blob',
    });
  };
};

const blobToFile = (theBlob: any, fileName: string) => {
  return new File([theBlob], fileName);
};

const requestFilePayloadWrapper = function (method: string) {
  return async function (url: string, payload: any) {
    const access_token = await authService.getAccessToken();
    return new Promise((resolve) => {
      const req = new XMLHttpRequest();
      const formData = new FormData();
      const { file, questions, name } = payload;
      formData.append('file', blobToFile(file, name));
      if (!_.isUndefined(questions)) {
        formData.append('questions', JSON.stringify(questions || {}));
      }
      if (!_.isUndefined(name)) {
        formData.append('title', name);
      }

      req.open(method, url, true);

      if (IframeHandler.isIframeScreen()) {
        const iframeConfig = IframeHandler.getConfig();
        req.setRequestHeader('Authorization', `Ex-AccessToken ${iframeConfig.access_token}`);
        req.setRequestHeader('X-BaseUrl', `${iframeConfig.base_url}`);
        req.setRequestHeader('X-UserId', `${iframeConfig.userid}`);
      } else {
        req.setRequestHeader('Authorization', `bearer ${access_token}`);
      }
      req.send(formData);
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          return resolve(req);
        }
      };
    });
  };
};

const requestArrayBufferWrapper = function (method: string) {
  return async function (url: string, payload: any) {
    const access_token = await authService.getAccessToken();
    return new Promise((resolve) => {
      const req = new XMLHttpRequest();
      const formData = new FormData();
      const { file, questions } = payload;
      if (!_.isUndefined(file)) {
        formData.append('file', file);
      }
      if (!_.isUndefined(questions)) {
        formData.append('questions', JSON.stringify(questions || {}));
      }
      req.responseType = 'arraybuffer';
      req.open(method, url, true);
      req.setRequestHeader('Authorization', `bearer ${access_token}`);
      req.send(formData);
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
          return resolve(req);
        }
      };
    });
  };
};

const downloadWrapper = function () {
  return async function (url: string, filename: string) {
    return axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      saveAs(response.data, filename);
    });
  };
};

const requestAllWrapper = function () {
  return async function (requests: any) {
    return axios.all(requests);
  };
};

export const http = {
  get: requestWrapper('get'),
  put: requestPayloadWrapper('put'),
  patch: requestPayloadWrapper('patch'),
  post: requestPayloadWrapper('post'),
  del: requestWrapper('delete'),
  postFile: requestFilePayloadWrapper('post'),
  all: requestAllWrapper(),
  download: downloadWrapper(),
  postPreviewFIle: requestArrayBufferWrapper('post'),
  postFilePreview: requestFileWrapper('post'),
  getPreviewFIle: requestFileWrapper('get'),
};
