import { connect } from 'react-redux';

import { User } from '../../@types/user';
import Header from '../../components/Header';
import FullHeader from '../../components/Header/HeaderFull';
import { RootState } from '../../store';

interface IHeader {
  current: User;
  template?: string;
}

const HeaderBar = ({ template, current }: IHeader) => {
  switch (template) {
    case 'main':
      return <Header current={current} />;
    case 'full':
      return <FullHeader current={current} />;
    default:
      return <Header current={current} />;
  }
};

const mapProps = (state: RootState) => ({
  current: state.users.current,
});

export default connect(mapProps)(HeaderBar);
