import React, { FC } from 'react';

import HeaderAvater from './HeaderAvater';
import { User } from '../../@types/user';
import Logo from '../../common/assets/logo';

type HeaderProps = {
  current: User;
};

const Header: FC<HeaderProps> = ({ current }: HeaderProps) => {
  return (
    <div className='mx-auto flex justify-between items-center px-4 py-2'>
      <Logo />
      <div className='flex justify-between flex-1'>
        <div className='flex justify-end sm:justify-start md:ml-5 w-full sm:w-50 relative text-black focus-within:text-black'>
          <div className='absolute inset-y-0 right-3 sm:left-3 pl-3 flex items-center pointer-events-none'></div>
        </div>
        <HeaderAvater current={current}></HeaderAvater>
      </div>
    </div>
  );
};

export default Header;
