import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cx from 'classnames';

const Loading = ({ position }: { position?: 'center' }) => {
  return (
    <div className={cx(position === 'center' && 'flex justify-center my-10')}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
    </div>
  );
};
export default Loading;
