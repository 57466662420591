import { RematchDispatch } from '@rematch/core';

import { UIState } from '../@types/global';

type State = UIState;
const model = {
  state: { pageLoading: false } as UIState,
  reducers: {
    start: (state: State) => {
      return { ...state, pageLoading: true };
    },
    stop: (state: State) => {
      return { ...state, pageLoading: false };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    startPageLoading() {
      dispatch.ui.start();
    },
    stopPageLoading() {
      dispatch.ui.stop();
    },
  }),
};

export default model;
