import React from 'react';

import cx from 'classnames';

import { IconProps } from '../../../@types/global';

function DragSelection({ className }: IconProps) {
  return (
    <svg width='22' height='22' fill='none' viewBox='0 0 22 22' className={cx(className)}>
      <circle cx='2.39' cy='2.39' r='2.39' fill='#999' />
      <circle cx='2.39' cy='11' r='2.39' fill='#999'>
        /
      </circle>
      <circle cx='2.39' cy='19.61' r='2.39' fill='#999' />
      <circle cx='11' cy='2.39' r='2.39' fill='#999' />
      <circle cx='11' cy='11' r='2.39' fill='#999' />
      <circle cx='11' cy='19.61' r='2.39' fill='#999' />
    </svg>
  );
}

function ExpandIcon({ className }: IconProps) {
  return (
    <svg width='11' height='10' fill='none' viewBox='0 0 11 10' className={cx(className)}>
      <path fill='#1B1063' d='M0 0.5H11V1.5H0z'></path>
      <path fill='#1B1063' d='M0 8.5H11V9.5H0z'></path>
      <path stroke='#041160' d='M0.5 3.5H10.5V6.5H0.5z'></path>
    </svg>
  );
}

function CollapseIcon({ className }: IconProps) {
  return (
    <svg width='11' height='10' fill='none' viewBox='0 0 11 10' className={cx(className)}>
      <path fill='#1B1063' d='M0 0.5H11V1.5H0z'></path>
      <path fill='#1B1063' d='M0 2.5H11V3.5H0z'></path>
      <path fill='#1B1063' d='M0 4.5H11V5.5H0z'></path>
      <path fill='#1B1063' d='M0 6.5H11V7.5H0z'></path>
      <path fill='#1B1063' d='M0 8.5H11V9.5H0z'></path>
    </svg>
  );
}

export const SettingIcon = ({ className }: IconProps) => {
  return (
    <svg width='26' height='27' fill='none' viewBox='0 0 26 27' className={cx(className)}>
      <path
        fill='#041160'
        d='M3.957 16.717V.574a.564.564 0 10-1.13 0v16.143C1.22 16.993 0 18.406 0 20.107c0 1.7 1.22 3.113 2.826 3.389v2.355a.564.564 0 101.13 0v-2.355c1.606-.276 2.827-1.69 2.827-3.39 0-1.7-1.221-3.113-2.826-3.389zm-.566 5.687c-1.243 0-2.26-1.034-2.26-2.298 0-1.263 1.017-2.298 2.26-2.298 1.244 0 2.261 1.035 2.261 2.298 0 1.264-1.017 2.298-2.26 2.298zM14.13 4.079V.574a.564.564 0 10-1.13 0V4.08c-1.605.275-2.826 1.689-2.826 3.39 0 1.7 1.22 3.113 2.826 3.388v14.994a.564.564 0 101.13 0V10.857c1.606-.275 2.826-1.688 2.826-3.389 0-1.7-1.22-3.114-2.826-3.39zm-.565 5.687c-1.243 0-2.26-1.034-2.26-2.298s1.017-2.298 2.26-2.298c1.244 0 2.261 1.034 2.261 2.298s-1.017 2.298-2.26 2.298zM23.174 20.164V.574a.564.564 0 10-1.13 0v19.59c-1.606.276-2.827 1.689-2.827 3.39 0 1.895 1.526 3.446 3.392 3.446 1.865 0 3.39-1.551 3.39-3.447 0-1.7-1.22-3.113-2.825-3.39zm-.565 5.687c-1.244 0-2.261-1.034-2.261-2.298s1.017-2.298 2.26-2.298c1.244 0 2.261 1.034 2.261 2.298s-1.017 2.298-2.26 2.298z'
      ></path>
    </svg>
  );
};

function VideoIcon({ height = 22, width = 22, className }: IconProps) {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 22 22' className={cx(className)}>
      <path
        fill='#5F6D73'
        d='M8.628 15.89c-.24 0-.479-.08-.69-.229-.376-.26-.604-.717-.604-1.218v-6.88c0-.503.228-.959.604-1.226.394-.28.878-.3 1.29-.058l5.96 3.44c.433.254.701.743.701 1.283 0 .541-.268 1.036-.701 1.284l-5.96 3.44c-.19.111-.394.163-.6.163zm0-8.471c-.045 0-.08.02-.097.032a.13.13 0 00-.062.111v6.88a.13.13 0 00.062.111.15.15 0 00.177.007l5.96-3.44c.058-.026.08-.065.08-.118a.123.123 0 00-.074-.117l-5.96-3.44a.146.146 0 00-.086-.026z'
      ></path>
      <circle cx='11' cy='11' r='10.5' stroke='#5F6D73'></circle>
    </svg>
  );
}

function ArticleIcon({ height = 18, width = 23, className }: IconProps) {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 18 17' className={cx(className)}>
      <path
        fill='#5F6D73'
        d='M6.48 10.89c.994 0 1.8-.685 1.8-1.529 0-.843-.806-1.528-1.8-1.528-.993 0-1.8.685-1.8 1.528 0 .844.807 1.528 1.8 1.528zm0-2.445c.594 0 1.08.412 1.08.916s-.486.917-1.08.917c-.594 0-1.08-.413-1.08-.917s.486-.916 1.08-.916z'
      ></path>
      <path
        fill='#5F6D73'
        d='M17.64 6H.36C.162 6 0 6.138 0 6.306v10.388c0 .169.162.306.36.306h17.28c.198 0 .36-.137.36-.306V6.306c0-.168-.162-.306-.36-.306zm-.36.611v7.275l-3.967-2.404a2.055 2.055 0 00-2.128.021l-3.539 2.252-2.253-1.369a2.055 2.055 0 00-2.128.022L.72 14.036V6.611h16.56zM.72 16.39v-1.586l2.977-1.91a1.232 1.232 0 011.275-.012l2.47 1.497a.415.415 0 00.424-.003l3.751-2.386a1.232 1.232 0 011.275-.012l4.388 2.658v1.754H.72z'
      ></path>
      <rect width='18' height='1' y='2' fill='#5F6D73' rx='0.5'></rect>
      <rect width='18' height='1' y='4' fill='#5F6D73' rx='0.5'></rect>
      <rect width='18' height='1' fill='#5F6D73' rx='0.5'></rect>
    </svg>
  );
}

function AssessmentIcon({ height = 18, width = 23, className }: IconProps) {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 23 18' className={cx(className)}>
      <g fill='#5F6D73' clipPath='url(#clip0)'>
        <path d='M14.5 17.634h-6a.503.503 0 010-1.008h6a.503.503 0 010 1.008zM22.5 16.122H.5a.503.503 0 01-.5-.504V1.26C0 .564.56 0 1.25 0h20.5C22.44 0 23 .564 23 1.26v14.358a.503.503 0 01-.5.504zM1 15.114h21V1.26a.249.249 0 00-.25-.252H1.25c-.14 0-.25.11-.25.252v13.854z'></path>
        <path d='M5.5 6.046a.51.51 0 01-.355-.146l-1-1.008a.504.504 0 010-.71.495.495 0 01.705 0l.65.65 1.645-1.658a.495.495 0 01.705 0 .504.504 0 010 .71l-2 2.016a.49.49 0 01-.35.146zM19 4.03H9v1.008h10V4.03zM5.5 9.572a.51.51 0 01-.355-.146l-1-1.007a.504.504 0 010-.71.495.495 0 01.705 0l.65.65L7.145 6.7a.495.495 0 01.705 0 .504.504 0 010 .71l-2 2.015a.49.49 0 01-.35.146zM19 7.557H9v1.008h10V7.557zM5.5 13.1a.51.51 0 01-.355-.147l-1-1.008a.504.504 0 010-.71.495.495 0 01.705 0l.65.65 1.645-1.658a.495.495 0 01.705 0 .504.504 0 010 .71l-2 2.016a.49.49 0 01-.35.146zM19 11.084H9v1.008h10v-1.008z'></path>
      </g>
      <defs>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0H23V17.633H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function AddCircle({ className }: IconProps) {
  return (
    <svg width='16' height='16' fill='#006EF5' className={cx(className)} viewBox='0 0 28 28'>
      <circle cx='14' cy='14' r='13.5' stroke='#fff' />
      <path stroke='#fff' strokeWidth='2' d='M20.533 14.067L7.467 14.067' />
      <path stroke='#fff' strokeWidth='2' d='M14.066 7.467L14.066 20.534' />
    </svg>
  );
}

export {
  DragSelection,
  ExpandIcon,
  CollapseIcon,
  VideoIcon,
  ArticleIcon,
  AssessmentIcon,
  AddCircle,
};
