import React, { FC } from 'react';

import { SyncOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

import { guid } from '../../helpers/util';

interface IAccessKey {
  placeholder: string;
  value: string;
  onChange?: Function;
}

const AccessKeyInput: FC<IAccessKey> = ({ placeholder, value, onChange }) => {
  const handleChangeEvent = (v: string) => {
    onChange?.(v);
  };

  return (
    <div>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(val) => handleChangeEvent(val.currentTarget.value)}
        disabled
      />
      <Button icon={<SyncOutlined />} type='text' onClick={() => handleChangeEvent(guid())}>
        {' '}
        Generate new key
      </Button>
    </div>
  );
};

export default AccessKeyInput;
