import { connect } from 'react-redux';

import PartnerForm from '../../components/Partners/PartnerForm';
import { RootDispatch, RootState } from '../../store';

const mapProps = (state: RootState) => ({
  current: state.users.current,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createPartner: dispatch.partners.createPartner,
  updatePartner: dispatch.partners.updatePartner,
  getPartner: dispatch.partners.getPartner,
});

export default connect(mapProps, mapDispatch)(PartnerForm);
