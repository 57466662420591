import React, { FC } from 'react';

import BreadcrumbNav from './BreadcrumbNav';
import { User } from '../../@types/user';
import Logo from '../../common/assets/logo';
import HeaderActions from '../../containers/Header/HeaderAction';

type HeaderProps = {
  current: User;
};

const HeaderFull: FC<HeaderProps> = ({ current }: HeaderProps) => {
  return (
    <div className='mx-auto flex justify-between items-center px-4 py-2'>
      <div className='flex flex-shrink-0'>
        <button className={'flex bg-none b-0 p-0'}>
          <span className='sr-only'>Expedo</span>
          <Logo />
        </button>
      </div>
      <div className='flex justify-between flex-1'>
        <div className='ml-8'>
          <BreadcrumbNav />
        </div>
      </div>
      <HeaderActions />
    </div>
  );
};

export default HeaderFull;
