import React from 'react';

const Logo = () => {
  return (
    <div className='flex flex-start flex-v-center m-t-16 m-lr-16' style={{ maxHeight: '50px' }}>
      <svg viewBox='0 0 880.06 200' width='180' height='50'>
        <defs>
          <linearGradient
            id='linear-gradient'
            x1='36.07'
            y1='178.32'
            x2='36.07'
            y2='108.57'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.2' stopColor='#6daeff' />
            <stop offset='0.21' stopColor='#6daeff' stopOpacity='0.98' />
            <stop offset='0.51' stopColor='#6daeff' stopOpacity='0.57' />
            <stop offset='0.75' stopColor='#6daeff' stopOpacity='0.26' />
            <stop offset='0.92' stopColor='#6daeff' stopOpacity='0.07' />
            <stop offset='1' stopColor='#6daeff' stopOpacity='0' />
          </linearGradient>
        </defs>
        <title>expedo_advisory</title>
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Layer_1-2' data-name='Layer 1'>
            <path
              className='cls-1'
              fill={'url(#linear-gradient)'}
              d='M68.82,129.5H54.2v-4.87H66.08a36.5,36.5,0,0,0-3.35-4.28H44.06v-4.88H57.3A36.08,36.08,0,0,0,0,143.89c0,.25,0,.5,0,.76s0,.5,0,.75a36.07,36.07,0,0,0,23.11,32.92V172a43.33,43.33,0,0,0,13,1.95c19.62,0,35.58-12.72,36.07-28.57,0-.25,0-.5,0-.75s0-.51,0-.76A36.06,36.06,0,0,0,68.82,129.5Z'
            />
            <path
              className='cls-2'
              fill={'#0b78ff'}
              d='M96.31,125.18a36.07,36.07,0,0,0-72.13,0c0,.26,0,.5,0,.76s0,.5,0,.76c.49,15.84,16.45,28.56,36.07,28.56a43.27,43.27,0,0,0,12.94-1.95v6.3A36.07,36.07,0,0,0,96.31,126.7v-1.52ZM76.42,129.5H54.2v-4.87H76.42Zm0-9.15H44.06v-4.88H76.42Z'
            />
            <path
              className='cls-3'
              fill={'#020f20'}
              d='M176.59,90h6.21l40.49,87.56h-7.08l-11.06-24.34H153.86l-11.05,24.34H136.1Zm25.83,57.38L179.57,97.1l-23,50.3Z'
            />
            <path
              className='cls-3'
              d='M226.89,146.16v-.25c0-20.62,15.53-33.16,30.8-33.16,12.67,0,20.87,7.2,26.08,15.15V90.6h6.09v87h-6.09V163.42c-5.46,8.32-13.53,15.65-26.08,15.65C242.42,179.07,226.89,166.77,226.89,146.16Zm57.26-.13v-.25c0-16.27-12.67-27.19-25.83-27.19-13.79,0-24.84,10-24.84,27.07v.25c0,16.64,11.55,27.32,24.84,27.32S284.15,162.18,284.15,146Z'
            />
            <path
              className='cls-3'
              d='M300.3,114.24h7.07l24,56.51,24.22-56.51h6.83L334,178.07h-5.21Z'
            />
            <path className='cls-3' d='M373,90.6h7.58v8.32H373Zm.75,23.64h6.08v63.34H373.7Z' />
            <path
              className='cls-3'
              d='M392.08,169.63l3.6-4.84c6.95,5.34,14.65,8.32,22.72,8.32,8.57,0,15.28-4.72,15.28-12.05v-.25c0-7.45-7.95-10.18-16.76-12.67-10.31-3-21.74-6.08-21.74-17.38v-.25c0-10.19,8.57-17.51,20.87-17.51a42.75,42.75,0,0,1,22.47,7L435.29,125c-5.83-3.85-12.79-6.33-19.49-6.33-8.7,0-14.41,4.72-14.41,11V130c0,7.08,8.57,9.69,17.64,12.3,10.18,2.85,20.86,6.58,20.86,17.76v.24c0,11.31-9.69,18.51-21.86,18.51A43.37,43.37,0,0,1,392.08,169.63Z'
            />
            <path
              className='cls-3'
              d='M448.71,146.16v-.25c0-17.76,13.79-33.16,32.66-33.16s32.42,15.15,32.42,32.91v.25c0,17.76-13.79,33.16-32.67,33.16S448.71,163.92,448.71,146.16Zm58.49,0v-.25c0-15.28-11.42-27.45-26.08-27.45-15,0-25.83,12.3-25.83,27.2v.25c0,15.27,11.43,27.44,26.08,27.44C496.4,173.35,507.2,161.06,507.2,146.16Z'
            />
            <path
              className='cls-3'
              d='M526.34,114.24h6.08v18c5-11.3,15.53-19.5,28.07-19V120h-.62c-14.66,0-27.45,11-27.45,31.67v26h-6.08Z'
            />
            <path
              className='cls-3'
              d='M565,195.09l2.11-5.34a22.16,22.16,0,0,0,10.81,2.36c6.58,0,10.93-3.85,15.65-14.91l-30.43-63h7.08l26.33,56.63,22.85-56.63h6.7l-27.44,65.57c-5.59,13.29-12,18-21.11,18A28.54,28.54,0,0,1,565,195.09Z'
            />
            <path
              className='cls-3'
              d='M154.6,51c-10.44,0-18.5-7.57-18.5-18.69v-.14c0-10.3,7.3-18.77,17.61-18.77,11.46,0,17.13,9.42,17.13,17.75A3.86,3.86,0,0,1,167,35.07H144.36c.89,5.87,5,9.15,10.37,9.15a13,13,0,0,0,8.53-3.14,3.08,3.08,0,0,1,2.12-.75,3.24,3.24,0,0,1,3.27,3.34,3.65,3.65,0,0,1-1.09,2.53A18.5,18.5,0,0,1,154.6,51Zm8.18-21.36c-.54-5.32-3.68-9.48-9.14-9.48-5,0-8.6,3.89-9.35,9.48Z'
            />
            <path
              className='cls-3'
              d='M209,17.88a4.07,4.07,0,0,1,4.09-4.16,4.14,4.14,0,0,1,4.17,4.16v2.25c2.66-3.75,6.41-6.76,12.28-6.76,8.46,0,16.72,6.69,16.72,18.7v.14c0,11.94-8.19,18.69-16.72,18.69a14.7,14.7,0,0,1-12.28-6.34V57.32a4.13,4.13,0,0,1-8.26,0Zm28.86,14.33v-.14c0-7-4.7-11.53-10.3-11.53s-10.51,4.64-10.51,11.53v.14c0,6.89,4.91,11.53,10.51,11.53S237.87,39.3,237.87,32.21Z'
            />
            <path
              className='cls-3'
              d='M267.1,51c-10.44,0-18.49-7.57-18.49-18.69v-.14c0-10.3,7.3-18.77,17.6-18.77,11.47,0,17.13,9.42,17.13,17.75a3.85,3.85,0,0,1-3.89,3.95H256.87c.88,5.87,5.05,9.15,10.37,9.15a13,13,0,0,0,8.53-3.14,3.05,3.05,0,0,1,2.11-.75,3.25,3.25,0,0,1,3.28,3.34,3.65,3.65,0,0,1-1.09,2.53A18.53,18.53,0,0,1,267.1,51Zm8.19-21.36c-.55-5.32-3.68-9.48-9.14-9.48-5,0-8.6,3.89-9.35,9.48Z'
            />
            <path
              className='cls-3'
              d='M322.94,46.4a4.13,4.13,0,0,1-8.25,0V44.15C312,47.9,308.27,50.9,302.4,50.9c-8.46,0-16.72-6.75-16.72-18.69v-.14c0-11.94,8.13-18.7,16.72-18.7a14.76,14.76,0,0,1,12.29,6.35V4.09a4.13,4.13,0,0,1,8.25,0ZM294,32.07v.14c0,7,4.78,11.53,10.37,11.53s10.44-4.64,10.44-11.53v-.14c0-7-4.91-11.53-10.44-11.53S294,24.91,294,32.07Z'
            />
            <path
              className='cls-3'
              d='M325.29,32.34v-.13a18.83,18.83,0,0,1,19.24-18.84c11.05,0,19.17,8.4,19.17,18.7v.14c0,10.23-8.19,18.76-19.31,18.76S325.29,42.58,325.29,32.34Zm30.16,0v-.13c0-6.35-4.58-11.6-11.06-11.6s-10.85,5.18-10.85,11.46v.14c0,6.28,4.57,11.53,11,11.53C351.21,43.74,355.45,38.55,355.45,32.34Z'
            />
            <circle className='cls-3' cx='200.26' cy='44.08' r='6.41' />
            <circle className='cls-3' cx='200.26' cy='20.13' r='6.41' />
            <circle className='cls-3' cx='189.13' cy='32.11' r='6.41' />
            <circle className='cls-3' cx='178.01' cy='20.13' r='6.41' />
            <circle className='cls-3' cx='178.01' cy='44.08' r='6.41' />
            <path
              className='cls-3'
              d='M360,13.39v0a3.67,3.67,0,0,1,7.33,0v0a3.67,3.67,0,0,1-7.33,0Zm6.94,0v0a3.28,3.28,0,0,0-6.56,0v0a3.28,3.28,0,1,0,6.56,0Zm-4.76-2h1.71a1.6,1.6,0,0,1,1.17.41,1.1,1.1,0,0,1,.31.81v0a1.14,1.14,0,0,1-.89,1.15l1,1.45h-.87l-.93-1.33H363v1.33h-.73Zm1.68,1.87c.48,0,.76-.23.76-.59v0c0-.39-.28-.6-.76-.6H363v1.21Z'
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
