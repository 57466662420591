import { useEffect } from 'react';

import { Select } from 'antd';
import Flag from 'react-country-flag';
import { connect } from 'react-redux';

import { Country } from '../../@types/global';
import { RootDispatch, RootState } from '../../store';

const Option = Select.Option;

type CountryLookupType = {
  countryLookup: any;
  countries: ReadonlyArray<Country>;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  value?: number;
  onChange?: any;
  triggerUpdate?: Function;
};

const CountryLookup = ({
  countryLookup,
  countries,
  mode,
  onChange,
  disabled,
  value,
  triggerUpdate,
}: CountryLookupType) => {
  useEffect(() => {
    countryLookup();
  }, [countryLookup]);

  const triggerChange = (v: any) => {
    onChange?.(v);
    triggerUpdate?.(v);
  };

  return (
    <Select
      showSearch
      mode={mode}
      disabled={disabled}
      placeholder='Select a Country'
      value={value}
      onChange={(v) => triggerChange(v)}
    >
      {countries &&
        countries.map((country, key) => (
          <Option key={key} value={country.id}>
            <div className={'flex flex-start items-center'}>
              <Flag
                svg
                className={'mr-1'}
                countryCode={country.code ? country.code : 'US'}
                style={{ width: '1.25em' }}
              />
              <span>{country.value}</span>
            </div>
          </Option>
        ))}
    </Select>
  );
};

const mapProps = (state: RootState) => ({
  countries: state.lookup.countries,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  countryLookup: dispatch.lookup.lookupCountry,
});

export default connect(mapProps, mapDispatch)(CountryLookup);
