import { nanoid } from 'nanoid';

import WorkflowDocumentStep from '../components/Workflow/Editor/Form/Document';
import TemplateHeader from '../layout/TemplateHeader';
import TemplateSidebar from '../layout/TemplateSidebar';
import Clause from '../pages/Clause';
import ClauseView from '../pages/Clause/ClauseView';
import ClauseVariantPage from '../pages/Clause/Variant';
import Contract from '../pages/Contract';
import ContractView from '../pages/Contract/view';
import Course from '../pages/Course';
import CourseCreate from '../pages/Course/create';
import CourseEdit from '../pages/Course/edit';
import CourseEditV2 from '../pages/Course/EditV2';
import CoursePreview from '../pages/Course/preview';
import Dashboard from '../pages/Dashboard';
import Handbook from '../pages/Handbook';
import HandbookEdit from '../pages/Handbook/edit';
import HandbookPreview from '../pages/Handbook/preview';
import Letter from '../pages/Letter';
import LetterView from '../pages/Letter/view';
import Partners from '../pages/Partners';
import Clients from '../pages/Partners/clients';
import PartnerView from '../pages/Partners/view';
import Workflow from '../pages/Workflow';
import WorkflowPreview from '../pages/Workflow/preview';
import WorkflowView from '../pages/Workflow/view';

const routes = [
  {
    id: nanoid(),
    name: 'home',
    access: ['admin', 'payrolladmin', 'supervisor', 'executive'],
    layout: TemplateSidebar,
    page: Dashboard,
    path: '/',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'contract',
    access: [],
    layout: TemplateSidebar,
    page: Contract,
    path: '/contracts',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'contract.view',
    access: [],
    layout: TemplateHeader,
    page: ContractView,
    path: '/contract/:assetID/version/:versionNumber',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Contracts', route: '/contracts' },
    ],
  },
  {
    id: nanoid(),
    name: 'letter',
    access: [],
    layout: TemplateSidebar,
    page: Letter,
    path: '/letters',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'letter.view',
    access: [],
    layout: TemplateHeader,
    page: LetterView,
    path: '/letter/:assetID/version/:versionNumber',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Letters', route: '/letters' },
    ],
  },
  {
    id: nanoid(),
    name: 'course',
    access: [],
    layout: TemplateSidebar,
    page: Course,
    path: '/courses',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'course.create',
    access: [],
    layout: TemplateSidebar,
    page: CourseCreate,
    path: '/course/create',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'course.edit',
    access: [],
    layout: TemplateSidebar,
    page: CourseEditV2,
    path: '/course/edit/:id',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'course.view',
    access: [],
    layout: TemplateHeader,
    page: CourseEdit,
    path: '/editor/courses/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Courses', route: '/courses' },
    ],
  },
  {
    id: nanoid(),
    name: 'course.preview',
    access: [],
    layout: TemplateHeader,
    page: CoursePreview,
    path: '/course/preview/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Courses', route: '/courses' },
    ],
  },
  {
    id: nanoid(),
    name: 'handbook',
    access: [],
    layout: TemplateSidebar,
    page: Handbook,
    path: '/handbooks',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'handbook.view',
    access: [],
    layout: TemplateHeader,
    page: HandbookEdit,
    path: '/handbook/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Handbooks', route: '/handbooks' },
    ],
  },
  {
    id: nanoid(),
    name: 'handbook.preview',
    access: [],
    layout: TemplateHeader,
    page: HandbookPreview,
    path: '/handbook/preview/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Handbooks', route: '/handbooks' },
    ],
  },
  {
    id: nanoid(),
    name: 'clause',
    access: [],
    layout: TemplateSidebar,
    page: Clause,
    path: '/clause',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'clause.view',
    access: [],
    layout: TemplateHeader,
    page: ClauseView,
    path: '/clause/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Clauses', route: '/clause' },
    ],
  },
  {
    id: nanoid(),
    name: 'clause.variant.view',
    access: [],
    layout: TemplateHeader,
    page: ClauseVariantPage,
    path: '/clause/:id/version/:paragraphId/file/:fileId',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Clauses', route: '/clause' },
      {
        name: 'Variants',
        route: '/clause/<%clauseID%>',
      },
    ],
  },
  {
    id: nanoid(),
    name: 'workflow',
    access: [],
    layout: TemplateSidebar,
    page: Workflow,
    path: '/workflows',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'workflow.view',
    access: [],
    layout: TemplateHeader,
    page: WorkflowView,
    path: '/workflow/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Workflows', route: '/workflows' },
    ],
  },
  {
    id: nanoid(),
    name: 'workflow.preview',
    access: [],
    layout: TemplateHeader,
    page: WorkflowPreview,
    path: '/workflow/preview/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Workflows', route: '/workflows' },
    ],
  },
  {
    id: nanoid(),
    name: 'workflow.document.view',
    access: [],
    layout: TemplateHeader,
    page: WorkflowDocumentStep,
    path: '/workflow/:id/step/:stepId/document/file/:fileId',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Workflows', route: '/workflows' },
    ],
  },
  {
    id: nanoid(),
    name: 'partners',
    access: [],
    layout: TemplateSidebar,
    page: Partners,
    path: '/partners',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'partner.view',
    access: [],
    layout: TemplateSidebar,
    page: PartnerView,
    path: '/partners/:id',
    breadcrumb: [
      { name: 'Home', route: '/' },
      { name: 'Partners', route: '/partners' },
    ],
  },
  {
    id: nanoid(),
    name: 'mycompany',
    access: [],
    layout: TemplateSidebar,
    page: PartnerView,
    path: '/mycompany',
    breadcrumb: [],
  },
  {
    id: nanoid(),
    name: 'clients',
    access: [],
    layout: TemplateSidebar,
    page: Clients,
    path: '/clients',
    breadcrumb: [],
  },
];

export default routes;
