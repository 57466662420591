import React, { ReactNode } from 'react';

interface IBadge {
  type: string;
  className?: string;
  children: ReactNode;
}

const Badge = ({ type, children }: IBadge) => {
  let badgeColor = '';
  switch (type) {
    case 'primary':
      badgeColor = 'badge-primary';
      break;
    case 'success':
      badgeColor = 'badge-green';
      break;
    case 'warning':
      badgeColor = 'badge-warning';
      break;
    case 'danger':
      badgeColor = 'badge-danger';
      break;
    case 'brown':
      badgeColor = 'badge-brown';
      break;
    case 'purple':
      badgeColor = 'badge-purple';
      break;
    case 'orange':
      badgeColor = 'badge-orange';
      break;
    case 'teal':
      badgeColor = 'badge-teal';
      break;
    case 'indigo':
      badgeColor = 'badge-indigo';
      break;
    case 'light-primary':
      badgeColor = 'badge-light-primary';
      break;
    case 'light-warning':
      badgeColor = 'badge-light-warning';
      break;
    case 'light-green':
      badgeColor = 'badge-light-green';
      break;
    case 'light-danger':
      badgeColor = 'badge-light-danger';
      break;
    case 'light-teal':
      badgeColor = 'badge-light-teal';
      break;
    case 'light-brown':
      badgeColor = ' badge-light-brown';
      break;
    case 'light-purple':
      badgeColor = 'badge-light-purple';
      break;
    case 'light-orange':
      badgeColor = 'badge-light-orange';
      break;
    case 'light-indigo':
      badgeColor = 'badge-light-indigo';
      break;
    default:
      badgeColor = 'badge-default';
  }

  return <span className={`badge ${badgeColor}`}>{children}</span>;
};

export default Badge;
