import { useCallback, useState } from 'react';

import styled from 'styled-components';

import CourseForm from './CorseForm/CourseForm';
import FlowControls from './CorseForm/FlowControls';
import { COURSE_TYPE, ENTITY_MODE, STEP_ACTION } from '../../@types/course';
import { BuildYourOwnIcon, UploadIcon } from '../../common/assets';
import IframeHelper from '../../helpers/IframeHelper';
import history from '../../routes/history';

type CreateCourseStep = {
  indicator: number;
  title: string;
};

const Create = () => {
  const [courseType, setCourseType] = useState(COURSE_TYPE.BYO);
  const steps = [
    {
      indicator: 0,
      title: 'How do you want to create your new course?',
    },
    {
      indicator: 1,
      title:
        courseType === COURSE_TYPE.BYO
          ? 'Build your own course - Basic Details'
          : 'Upload a course - Basic Details',
    },
  ];

  const [currentStep, setCurrentStep] = useState<number>(0);

  const handleStepChange = useCallback(
    (stepAction: STEP_ACTION) => {
      if (stepAction === STEP_ACTION.BACKWARD && !!currentStep) setCurrentStep(currentStep - 1);
      if (stepAction === STEP_ACTION.FORWARD) setCurrentStep(currentStep + 1);
    },
    [currentStep],
  );

  const handleCancel = () => history.push(`/courses`);

  const stepContents = [
    <CourseTypeOptions
      key='CourseTypeOptions'
      steps={steps}
      currentStep={currentStep}
      onCourseTypeChange={setCourseType}
      onStepChange={handleStepChange}
      onCancel={handleCancel}
    />,
    <CourseForm
      key='CourseForm'
      mode={ENTITY_MODE.CREATE}
      courseType={courseType}
      flowControls={{ steps, currentStep }}
      onStepChange={handleStepChange}
      onCancel={handleCancel}
    />,
  ];

  const currentIndicator = steps[currentStep].indicator;
  const currentContent = stepContents[currentIndicator];

  return <>{currentContent}</>;
};

export const CourseTypeOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CourseTypeOption = styled.div`
  max-width: 340px;
  padding: 40px 20px;
`;

export const CourseTypeIconWrapper = styled.div`
  cursor: pointer;
  margin-top: 40px;
`;

export const CourseTypeTitle = styled.div`
  margin-top: 40px;
  font-size: 20px;
  color: #5d6b98;
`;

export const CourseTypeDescription = styled.div`
  margin-top: 30px;
  font-size: 14px;
  color: #777777;
  text-align: center;
`;

export const LearnMoreLink = styled.a`
  text-decoration: underline;
  color: #777777;
  hover: {
    color: blue;
  }
`;

type CourseTypeOptionsProps = {
  steps: CreateCourseStep[];
  currentStep: number;
  onCourseTypeChange: (courseType: COURSE_TYPE) => void;
  onStepChange: (stepAction: STEP_ACTION) => void;
  onCancel: () => void;
};

const CourseTypeOptions = (props: CourseTypeOptionsProps) => {
  const {
    steps,
    currentStep,
    onCourseTypeChange: handleCourseTypeChange,
    onStepChange: handleStepChange,
    onCancel: handleCancel,
  } = props;

  const step = steps[currentStep];
  const { indicator, title } = step;

  const handleClickBYO = () => {
    handleCourseTypeChange(COURSE_TYPE.BYO);
    handleStepChange(STEP_ACTION.FORWARD);
  };

  const handleClickUpload = () => {
    handleCourseTypeChange(COURSE_TYPE.UPLOAD);
    handleStepChange(STEP_ACTION.FORWARD);
  };

  const isIframeView = IframeHelper.isIframeScreen();

  return (
    <>
      {!isIframeView && (
        <FlowControls
          steps={steps}
          currentStep={currentStep}
          hideStepDetails={true}
          onStepChange={handleStepChange}
          onCancel={handleCancel}
        />
      )}

      <CourseTypeOptionsWrapper>
        <p className='text-xs text-center text-gray-700 mt-4'>{`${indicator + 1} / 2`}</p>
        <p className='text-xl text-center mt-4 font-bold'>{title}</p>
        <div className='grid grid-cols-12 gap-14 mt-12'>
          <div className='col-span-6 flex justify-end'>
            <CourseTypeOption className='bg-white rounded shadow-sm hover:shadow-lg transition-all ease-in-out'>
              <div className='flex flex-row justify-center'>
                <CourseTypeIconWrapper>
                  <BuildYourOwnIcon height='100px' width='100px' onClick={() => handleClickBYO()} />
                </CourseTypeIconWrapper>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <CourseTypeTitle>Build your own</CourseTypeTitle>
                <CourseTypeDescription>
                  Use the Course Builder to create your own course using videos, presentations and
                  other content.{' '}
                  <span>
                    <LearnMoreLink href='https://support.expedo.co/hc/en-au/articles/900005344403'>
                      Learn more
                    </LearnMoreLink>
                  </span>
                </CourseTypeDescription>
              </div>
            </CourseTypeOption>
          </div>
          <div className='col-span-6 flex justify-start'>
            <CourseTypeOption className='bg-white rounded shadow-sm hover:shadow-lg transition-all ease-in-out'>
              <div className='flex flex-row justify-center'>
                <CourseTypeIconWrapper>
                  <UploadIcon height='100px' width='100px' onClick={() => handleClickUpload()} />
                </CourseTypeIconWrapper>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <CourseTypeTitle>Upload</CourseTypeTitle>
                <CourseTypeDescription>
                  Upload your own SCORM course in .zip format.{' '}
                  <span>
                    <LearnMoreLink href='https://support.expedo.co/hc/en-au/articles/900005344403'>
                      Learn more
                    </LearnMoreLink>
                  </span>
                </CourseTypeDescription>
              </div>
            </CourseTypeOption>
          </div>
        </div>
      </CourseTypeOptionsWrapper>
    </>
  );
};

export default Create;
