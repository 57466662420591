import React from 'react';

const Task = () => {
  return (
    <svg width='20' height='15' viewBox='0 0 20 15' fill='none'>
      <rect x='4' width='16' height='2.4' rx='1.2' fill='black' />
      <rect x='4' y='6.40002' width='16' height='2.4' rx='1.2' fill='black' />
      <rect x='4' y='12' width='16' height='2.4' rx='1.2' fill='black' />
      <rect x='0.5' y='0.5' width='1.4' height='1.4' rx='0.5' stroke='black' />
      <rect x='0.5' y='6.90002' width='1.4' height='1.4' rx='0.5' stroke='black' />
      <rect x='0.5' y='12.5' width='1.4' height='1.4' rx='0.5' stroke='black' />
    </svg>
  );
};

const Message = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M11.9231 11.1539H1.15383C0.538487 11.1539 0 10.6154 0 10C0 9.38461 0.538487 8.84619 1.15383 8.84619H11.9231C12.5385 8.84619 13.0769 9.38468 13.0769 10C13.0769 10.6154 12.5385 11.1539 11.9231 11.1539Z'
        fill='#231F20'
      />
      <path
        d='M18.8462 4.23075H1.15383C0.538487 4.23075 0 3.69234 0 3.07692C0 2.46151 0.538487 1.9231 1.15383 1.9231H18.8461C19.4615 1.9231 19.9999 2.46158 19.9999 3.07692C19.9999 3.69227 19.4615 4.23075 18.8462 4.23075Z'
        fill='#231F20'
      />
      <path
        d='M18.8462 18.0769H1.15383C0.538487 18.0769 0 17.5385 0 16.9231C0 16.3077 0.538487 15.7693 1.15383 15.7693H18.8461C19.4615 15.7693 19.9999 16.3078 19.9999 16.9231C19.9999 17.5385 19.4615 18.0769 18.8462 18.0769Z'
        fill='#231F20'
      />
    </svg>
  );
};

const Questionaire = () => {
  return (
    <svg width='30' height='20' viewBox='0 0 20 12' fill='none'>
      <path
        d='M18.6304 8.75301L18.7514 11.4103C18.7624 11.652 18.5128 11.8191 18.2935 11.7166L15.8832 10.5906C13.946 11.1446 11.8172 10.571 10.4251 9.05344C10.3644 8.98742 10.3055 8.91984 10.2483 8.85023C10.2096 8.80422 10.1723 8.75742 10.1355 8.70984C10.0579 8.60984 9.98314 8.50664 9.91154 8.39984C8.58514 6.41625 8.74635 3.87262 10.1347 2.08621C10.1715 2.03902 10.2092 1.99223 10.2479 1.94621C10.3192 1.86019 10.394 1.77582 10.4715 1.69383C10.7444 1.40504 11.0535 1.14223 11.3979 0.911835C13.8763 -0.745782 17.2292 -0.0805874 18.8864 2.39742C20.2232 4.39699 20.0488 6.96461 18.6304 8.75301Z'
        fill='#818181'
      />
      <path
        d='M9.8861 8.40535C11.5494 5.91848 10.8736 2.55102 8.37399 0.899649C5.88734 -0.743242 2.5684 -0.0743359 0.911525 2.40305C-0.425468 4.40211 -0.250741 6.96996 1.16746 8.75828L1.04668 11.4159C1.0357 11.6577 1.28535 11.8247 1.50461 11.7222L3.91473 10.5957C6.10906 11.2235 8.54906 10.4044 9.8861 8.40535Z'
        fill='black'
      />
      <path
        d='M6.32568 6.70117H4.24072L3.77246 8H3.0957L4.99609 3.02344H5.57031L7.47412 8H6.80078L6.32568 6.70117ZM4.43896 6.16113H6.13086L5.2832 3.8335L4.43896 6.16113Z'
        fill='white'
      />
      <path
        d='M12.5776 8V3.02344H14.2046C14.7446 3.02344 15.1502 3.13509 15.4214 3.3584C15.6948 3.58171 15.8315 3.91211 15.8315 4.34961C15.8315 4.58203 15.7655 4.78825 15.6333 4.96826C15.5011 5.146 15.3211 5.28385 15.0933 5.38184C15.3621 5.45703 15.5741 5.60059 15.729 5.8125C15.8862 6.02214 15.9648 6.27279 15.9648 6.56445C15.9648 7.01107 15.8201 7.36198 15.5308 7.61719C15.2414 7.8724 14.8324 8 14.3037 8H12.5776ZM13.2339 5.67236V7.46338H14.3174C14.6227 7.46338 14.8631 7.38477 15.0386 7.22754C15.2163 7.06803 15.3052 6.84928 15.3052 6.57129C15.3052 5.97201 14.9793 5.67236 14.3276 5.67236H13.2339ZM13.2339 5.146H14.2251C14.5122 5.146 14.7412 5.07422 14.9121 4.93066C15.0853 4.78711 15.1719 4.59229 15.1719 4.34619C15.1719 4.07275 15.0921 3.87451 14.9326 3.75146C14.7731 3.62614 14.5304 3.56348 14.2046 3.56348H13.2339V5.146Z'
        fill='white'
      />
    </svg>
  );
};

const Evaluation = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M15.367 2.63301C11.856 -0.877671 6.14398 -0.877671 2.63297 2.63301C-0.877657 6.1437 -0.877657 11.856 2.63297 15.3669C4.38846 17.1222 6.69425 18 9 18C11.3058 18 13.6115 17.1222 15.367 15.3669C18.8777 11.8562 18.8777 6.14391 15.367 2.63301ZM13.1156 5.86733C13.1156 6.19179 12.8525 6.45469 12.5282 6.45469C12.204 6.45469 11.9409 6.19179 11.9409 5.86733V5.27578H6.71516L9.45464 8.62601C9.63169 8.84223 9.63169 9.15248 9.45464 9.36873L6.71516 12.7158H11.9409V12.1326C11.9409 11.8082 12.204 11.5453 12.5282 11.5453C12.8525 11.5453 13.1156 11.8082 13.1156 12.1326V13.3073C13.1156 13.6318 12.8484 13.8905 12.5241 13.8905H5.47584C5.24908 13.8905 5.04222 13.7619 4.94505 13.5569C4.84795 13.3517 4.87734 13.1099 5.02113 12.9344L8.24091 8.99945L5.02116 5.06453C4.87738 4.88902 4.84795 4.64409 4.94509 4.43891C5.04219 4.23395 5.24912 4.10105 5.47587 4.10105H12.5242C12.8484 4.10105 13.1156 4.36814 13.1156 4.6926V5.86733H13.1156Z'
        fill='black'
      />
    </svg>
  );
};

export { Task, Message, Questionaire, Evaluation };
