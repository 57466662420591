import React, { useEffect, useState } from 'react';

import {
  AuthenticationResultStatus,
  LogoutActions,
  QueryParameterNames,
} from '../../common/constants/auth';
import Loading from '../../common/Loading';
import { authService } from '../../services/AuthService';

type IMetaProps = {
  action: string;
};

const Logout = ({ action }: IMetaProps) => {
  const [message, setMessage] = useState('');

  const getReturnUrl = (state: any = {}) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
  };

  const navigateToReturnUrl = (url: string) => {
    window.location.replace(url);
  };

  useEffect(function () {
    switch (action) {
      case LogoutActions.Logout:
        if (window.history.state && !window.history.state.state) {
          logout(getReturnUrl());
        } else {
          setMessage('The logout was not initiated from within the page.');
        }
        break;
      case LogoutActions.LogoutCallback:
        processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        setMessage('You successfully logged out!');
        navigateToReturnUrl('/login');
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }, []);

  const logout = async (returnUrl: string) => {
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      const result: any = await authService.logout();
      switch (result && result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    }
  };

  const processLogoutCallback = async () => {
    const url = window.location.href;
    const result: any = await authService.logoutCallback(url);
    switch (result && result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        navigateToReturnUrl(getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        setMessage(result.message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  };

  return (
    <div>
      <Loading position={'center'} />
      <p className='text-center'>{message}</p>
    </div>
  );
};

export default Logout;
