import React from 'react';

import { IconState } from '../../../@types/global';
import { colors } from '../../constants/colors';

export const HomeIcon = (props: IconState) => {
  return (
    <svg width='24' height='24' fill='none' viewBox='0 0 24 24' {...props}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z'
      />
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M9 22V12h6v10'
      />
    </svg>
  );
};

export const ContractIcon = (props: IconState) => {
  return (
    <svg width='28' height='28' fill='none' {...props} viewBox='0 0 28 28'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M16.333 2.333H7a2.333 2.333 0 00-2.333 2.334v18.666A2.333 2.333 0 007 25.668h14a2.333 2.333 0 002.333-2.334v-14l-7-7z'
      />
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M16.333 2.333v7h7M18.667 15.166H9.333M18.667 19.834H9.333M11.667 10.5H9.333'
      />
    </svg>
  );
};

export const LetterIcon = (props: IconState) => {
  return (
    <svg width='28' height='28' fill='none' viewBox='0 0 28 28' {...props}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M4.667 4.667h18.666A2.34 2.34 0 0125.667 7v14a2.34 2.34 0 01-2.334 2.333H4.667A2.34 2.34 0 012.333 21V7a2.34 2.34 0 012.334-2.333z'
      />
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M25.667 7L14 15.167 2.333 7'
      />
    </svg>
  );
};

export const WorkflowIcon = (props: IconState) => {
  return (
    <svg width='24' height='28' fill='none' viewBox='0 0 24 28' {...props}>
      <path fill='#fff' stroke='#000' d='M10.833 2.5H21.033V7.1H10.833z' />
      <path
        fill='#fff'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M3.633 20.9H13.033000000000001V25.5H3.633z'
      />
      <path
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M14.17 23.273h4.082m-4.082 0V20.75c0-.414-.365-.75-.816-.75H3.15c-.451 0-.817.336-.817.75v4.5c0 .414.366.75.817.75h10.204c.45 0 .816-.336.816-.75v-1.977zm4.082 0c3.15 0 4.081-1.878 4.081-4.773m-4.081 4.773c2.25 0 4.081-.785 4.081-4.773m0 0c0-2.895-.93-4.5-4.081-4.5m4.081 4.5c0-3.784-1.83-4.5-4.081-4.5m0 0H7.639m0 0c-3.306 0-4.898-1.364-4.898-4.5M7.64 14c-3.306 0-4.898-1.83-4.898-4.5m0 0c0-3.136 3.464-4.773 5.715-4.773M2.742 9.5c0-2.895 3.183-4.773 5.714-4.773m0 0h1.632m0 0V7.25c0 .414.366.75.817.75H20.7c.45 0 .816-.336.816-.75v-4.5c0-.414-.366-.75-.816-.75h-9.796c-.451 0-.817.336-.817.75v1.977z'
      />
    </svg>
  );
};

export const CourseIcon = (props: IconState) => {
  return (
    <svg width='24' height='22' fill='none' viewBox='0 0 24 22' {...props}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M1 1h6.6A4.4 4.4 0 0112 5.4v15.4a3.3 3.3 0 00-3.3-3.3H1V1zM23 1h-6.6A4.4 4.4 0 0012 5.4v15.4a3.3 3.3 0 013.3-3.3H23V1z'
      />
    </svg>
  );
};

export const HandbookIcon = (props: IconState) => {
  return (
    <svg width='28' height='28' fill='none' {...props} viewBox='0 0 28 28'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M4.667 22.75a2.916 2.916 0 012.916-2.916h15.75'
      />
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M7.583 2.333h15.75v23.334H7.583a2.917 2.917 0 01-2.916-2.917V5.25a2.917 2.917 0 012.916-2.917v0z'
      />
    </svg>
  );
};

export const PartnerCompanyIcon = (props: IconState) => {
  return (
    <svg width='28' height='28' fill='none' viewBox='0 0 28 28' {...props}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M20.429 5H7.57C6.151 5 5 6.182 5 7.64v16.72C5 25.818 6.151 27 7.571 27H20.43C21.849 27 23 25.818 23 24.36V7.64C23 6.182 21.849 5 20.429 5z'
      />
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M11 26v-5h6v5M9 4V2h10v2M9 9H8M9 13H8M9 17H8M14 9h-1M14 13h-1M14 17h-1M19 9h-1M19 13h-1M19 17h-1'
      />
    </svg>
  );
};

export const ClientIcon = (props: IconState) => {
  return (
    <svg width='28' height='28' fill='none' {...props} viewBox='0 0 28 28'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M11.667 3.5H3.5v8.167h8.167V3.5zM24.5 3.5h-8.167v8.167H24.5V3.5zM24.5 16.334h-8.167V24.5H24.5v-8.166zM11.667 16.334H3.5V24.5h8.167v-8.166z'
      />
    </svg>
  );
};

export const SectionIcon = (props: IconState) => {
  return (
    <svg width='28' height='28' fill='none' viewBox='0 0 28 28' {...props}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        stroke={props && props.selected ? colors.primary : '#25282B'}
        d='M22.167 24.5L17.5 22l-4 2.5-3-2.5-4.667 2.5V5.833A2.333 2.333 0 018.167 3.5h11.666a2.333 2.333 0 012.334 2.333V24.5zM18 10h-8M18 15h-8'
      />
    </svg>
  );
};
