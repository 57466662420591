import { lazy, Suspense } from 'react';

import Loading from '../../common/Loading';

const CourseEditV2 = lazy(() => import('../../components/Course/CourseEditV2'));

const CourseEditView = () => {
  return (
    <Suspense fallback={<Loading />}>
      <CourseEditV2 />
    </Suspense>
  );
};

export default CourseEditView;
