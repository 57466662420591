import React, { FC, useEffect } from 'react';

import { Select } from 'antd';
import { connect } from 'react-redux';

import { CourseCategory } from '../../@types/course';
import { mustBeArray } from '../../helpers/util';
import { RootDispatch, RootState } from '../../store';

interface ICourseCategory {
  courseCategories: ReadonlyArray<CourseCategory>;
  lookupCourseCategory: Function;
  onChange: Function;
  value?: number;
}

const CourseCategoryLookup: FC<ICourseCategory> = ({
  lookupCourseCategory,
  value,
  courseCategories,
  onChange,
}) => {
  useEffect(() => {
    lookupCourseCategory();
  }, []);

  return (
    <>
      <Select showSearch style={{ width: '100%' }} value={value} onChange={(v) => onChange(v)}>
        <Select.Option key='dummy' value={null}>
          Select Category
        </Select.Option>
        {courseCategories &&
          mustBeArray(courseCategories).map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

const mapProps = (state: RootState) => ({
  courseCategories: state.lookup.courseCategories,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  lookupCourseCategory: dispatch.lookup.lookupCourseCategory,
});

export default connect(mapProps, mapDispatch)(CourseCategoryLookup);
