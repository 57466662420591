import { ActionHandler, QueryFilter } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';
import { delay } from '../helpers/util';

class ContractService {
  public listCourse(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public courseActionHandler = ({
    id,
    action,
    payLoad = {},
    options = {},
    queryOptions = {},
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.get(`${url}/${id}`, queryOptions);
      case 'list':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.get(url, queryOptions);
      case 'create':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.post(url, payLoad);

      case 'upload':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'COURSE_FILE_UPLOAD_URL',
          options,
          isMocked: false,
        });
        return http.postFile(url, payLoad);
      case 'uploadScorm':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SCORM_COURSE_FILE_UPLOAD_URL',
          options,
          isMocked: false,
        });
        return http.postFile(url, payLoad);
      case 'uploadScormNoId':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'COURSE_URL',
          isMocked: false,
        });
        return http.postFile(`${url}/scorm/externalfile`, payLoad);
      case 'update':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.put(`${url}/${id}`, payLoad);

      case 'duplicate':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.post(`${url}/${id}/duplicates`);

      case 'archive':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.patch(`${url}/${id}`, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.del(`${url}/${id}`);

      case 'getScormPreviewLink':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
        return http.get(`${url}/${id}/scorm/course-preview`);

      default:
        return delay(100);
    }
  };

  public sectionActionHandler = ({
    id,
    action,
    payLoad,
    queryOptions = {},
    options = {},
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'list':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'COURSE_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.get(url, queryOptions);

      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'COURSE_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.get(`${url}/${id}`);

      case 'create':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'COURSE_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.post(url, payLoad);

      case 'upload':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'FILE_UPLOAD',
          options: options,
          isMocked: false,
        });
        return http.postFile(url, payLoad);

      case 'update':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'COURSE_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.patch(`${url}/${id}`, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'COURSE_SECTION_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      default:
        return delay(100);
    }
  };

  public lectureActionHandler = ({
    id,
    options = {},
    queryOptions = {},
    payLoad = {},
    action,
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_ITEM_URL',
          options: options,
          isMocked: false,
        });
        return http.get(`${url}/${id}`, queryOptions);

      case 'post':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'LECTURE_URL',
          options: options,
          isMocked: false,
        });
        return http.post(url, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'LECTURE_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      case 'patch':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'LECTURE_URL',
          options: options,
          isMocked: false,
        });
        return http.patch(`${url}/${id}`, payLoad);

      case 'upload':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'ARTICLE_IMAGE_UPLOAD', options });
        return http.postFile(url, payLoad);

      case 'upload_file':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'LECTURE_FILE_URL',
          options: options,
          isMocked: false,
        });
        return http.postFile(url, payLoad);

      default:
        return delay(100);
    }
  };

  public lectureResourcesHandler = ({
    id,
    options = {},
    queryOptions = {},
    payLoad = {},
    action,
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'GET_LECTURE_URL',
          options: options,
          isMocked: false,
        });
        return http.get(`${url}/${id}/resources`, queryOptions);

      case 'post':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'GET_LECTURE_URL',
          options: options,
          isMocked: false,
        });
        return http.postFile(`${url}/${id}/resources`, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'GET_LECTURE_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/resources/${id}`);

      default:
        return delay(100);
    }
  };

  public assessmentActionHandler = ({
    id,
    options = {},
    queryOptions = {},
    action,
    payLoad = {},
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'GET_ASSESSMENT_URL',
          options: options,
          isMocked: false,
        });
        return http.get(`${url}/${id}`, queryOptions);

      case 'post':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'ASSESSMENT_URL',
          options: options,
          isMocked: false,
        });
        return http.post(url, payLoad);

      case 'patch':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'ASSESSMENT_URL',
          options: options,
          isMocked: false,
        });
        return http.patch(`${url}/${id}`, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'ASSESSMENT_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      default:
        return delay(100);
    }
  };

  public questionActionHandler = ({
    id,
    options = {},
    queryOptions = {},
    action,
    payLoad = {},
  }: ActionHandler) => {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'GET_ASSESSMENT_QUESTION_URL',
          options: options,
          isMocked: false,
        });
        return http.get(url, queryOptions);

      case 'post':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'ASSESSMENT_QUESTION_URL',
          options: options,
          isMocked: false,
        });
        return http.post(url, payLoad);

      case 'patch':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'ASSESSMENT_QUESTION_URL',
          options: options,
          isMocked: false,
        });
        return http.patch(`${url}/${id}`, payLoad);

      case 'upload':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'ASSESSMENT_QUESTION_IMAGE_UPLOAD',
          options,
        });
        return http.postFile(url, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'ASSESSMENT_QUESTION_URL',
          options: options,
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      default:
        return delay(100);
    }
  };

  public sectionItemsDrag({
    courseID,
    payLoad,
  }: {
    courseID?: number;
    payLoad: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'COURSE_URL', isMocked: false });
    return http.patch(`${url}/${courseID}`, payLoad);
  }
}

const instance = new ContractService();
Object.freeze(instance);

export default instance;
