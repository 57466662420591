import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import Loading from '../common/Loading';
import ProgressBar from '../common/pageProgress';
import IframeHelper from '../helpers/IframeHelper';
import { RootDispatch } from '../store';

type IMainProps = {
  children: ReactNode;
  getCurrentUser: Function;
};

const TemplateNothing = ({ children, getCurrentUser }: IMainProps) => {
  const [loading, setLoading] = useState(true);
  const isIframeView = IframeHelper.isIframeScreen();

  useEffect(() => {
    if (!isIframeView) {
      getCurrentUser().then(() => setLoading(false));
    }
  }, [getCurrentUser, isIframeView]);

  if (loading && !isIframeView) {
    return <Loading position={'center'} />;
  }

  return (
    <>
      <ProgressBar />
      <div className='h-screen bg-xemplo-gray-50 overflow-y-auto '>{children}</div>
    </>
  );
};

const mapPros = () => ({});

const mapDispatch = (dispatch: RootDispatch) => ({
  getCurrentUser: dispatch.users.loadCurrentUser,
});

export default connect(mapPros, mapDispatch)(TemplateNothing);
