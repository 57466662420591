import { Input } from 'antd';
import styled from 'styled-components';

import { DraggerInnerWrapperProps } from './CourseForm.types';

const { TextArea } = Input;

export const CourseSettingsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlowControlsStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlowControlStyled = styled.span`
  cursor: pointer;
`;

export const InputStyled = styled(Input)`
  height: 38px;
  border-radius: 1.5rem;
`;

export const TextAreaStyled = styled(TextArea)`
  border-radius: 1rem;
`;

export const FormItemDescriptionStyled = styled.span`
  display: block;
  font-size: 14px;
  color: #777777;
  margin-bottom: 0.3rem;
`;

export const DraggerInnerImageWrapperStyled = styled.div<DraggerInnerWrapperProps>`
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${(props) => `url(${props.courseImageUrlValue})`};
  border-radius: 0.5rem;
  margin-left: 16px;
  margin-right: 16px;
`;

export const UploadWrapperStyled = styled.div`
  .ant-upload-list-item-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
