import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { ClauseDetail, ClauseVariant } from '../../@types/clause';
import { ContractDetail } from '../../@types/contract';
import { CourseStatus } from '../../@types/course';
import { LetterDetail } from '../../@types/letter';
import { User } from '../../@types/user';
import { WorkflowDetail } from '../../@types/workflow';
import PageTitle from '../../components/Header/PageTitle';
import PageTitleV2 from '../../components/Header/PageTitleV2';
import { RootState } from '../../store';

interface IPageTitle {
  currentRoute?: any;
  current?: User;
}

const selectContractTitle = createSelector(
  (state: RootState) => state.contract.contract,
  (contract: ContractDetail) => {
    return (
      contract && {
        title: contract?.title,
        statusName: contract?.statusName,
        badgeClass:
          contract.statusId === 2 ? 'success' : contract.statusId === 3 ? 'danger' : 'default',
      }
    );
  },
);
const selectLetterTitle = createSelector(
  (state: RootState) => state.letter.letter,
  (letter: LetterDetail) => {
    return (
      letter && {
        title: letter?.title,
        statusName: letter?.statusName,
        badgeClass:
          letter.statusId === 2 ? 'success' : letter.statusId === 3 ? 'danger' : 'default',
      }
    );
  },
);

const selectClauseTitle = createSelector(
  (state: RootState) => state.clause.clause,
  (clause: ClauseDetail) => {
    return clause && { title: clause?.name };
  },
);

const selectWorkflowTitle = createSelector(
  (state: RootState) => state.workflow.workflow,
  (workflow: WorkflowDetail) => {
    return workflow && { title: workflow?.name };
  },
);

const selectHandbookTitle = createSelector(
  (state: RootState) => state.hbook.handbook,
  (handbook: any) => {
    return handbook && { title: handbook?.name };
  },
);

const selectClauseVariantTitle = createSelector(
  (state: RootState) => state.clause.variant,
  (variant: ClauseVariant) => {
    return variant && { title: variant?.title };
  },
);

const ContractTitle = () => {
  const contractData = useSelector(selectContractTitle);
  return contractData ? (
    <PageTitle
      pageTitle={contractData.title}
      badge={{
        class: contractData.badgeClass,
        statusName: contractData.statusName,
      }}
    />
  ) : (
    <></>
  );
};

const LetterTitle = () => {
  const letterData = useSelector(selectLetterTitle);
  return letterData ? (
    <PageTitle
      pageTitle={letterData.title}
      badge={{ class: letterData.badgeClass, statusName: letterData.statusName }}
    />
  ) : (
    <></>
  );
};

const CourseTitle = () => {
  const course = useSelector((state: RootState) => state.course.course);
  const { name, statusID, statusName } = course || {};

  const getStatusType = (statusID: CourseStatus) => {
    switch (statusID) {
      case CourseStatus.Draft:
      case CourseStatus['Draft Release']:
        return 'info';
      case CourseStatus.Published:
        return 'success';
      case CourseStatus.Retired:
        return 'danger';
      default:
        return 'default';
    }
  };

  if (!course) return null;

  return (
    <PageTitleV2
      pageTitle={name}
      badge={{
        statusName,
        statusType: getStatusType(statusID),
      }}
    />
  );
};

const WorkflowTitle = () => {
  const workflowData = useSelector(selectWorkflowTitle);
  return workflowData ? <PageTitle pageTitle={workflowData.title} /> : <></>;
};

const HandbookTitle = () => {
  const HandbookData = useSelector(selectHandbookTitle);
  return HandbookData ? <PageTitle pageTitle={HandbookData.title} /> : <></>;
};

const ClauseTitle = () => {
  const clauseData = useSelector(selectClauseTitle);
  return clauseData ? <PageTitle pageTitle={clauseData?.title} /> : <></>;
};

const ClauseVariantTitle = () => {
  const ClauseVariantData = useSelector(selectClauseVariantTitle);
  return ClauseVariantData ? (
    <PageTitle pageTitle={ClauseVariantData?.title || 'Untitled'} />
  ) : (
    <></>
  );
};

const Title = ({ currentRoute }: IPageTitle) => {
  switch (currentRoute.name) {
    case 'contract.view':
      return <ContractTitle />;
    case 'letter.view':
      return <LetterTitle />;
    case 'clause.view':
      return <ClauseTitle />;
    case 'course.view':
    case 'course.preview':
      return <CourseTitle />;
    case 'workflow.view':
    case 'workflow.preview':
    case 'workflow.document.view':
      return <WorkflowTitle />;
    case 'clause.variant.view':
      return <ClauseVariantTitle />;
    case 'handbook.view':
    case 'handbook.preview':
      return <HandbookTitle />;
    default:
      return <PageTitle pageTitle={currentRoute.name} />;
  }
};

export default Title;
