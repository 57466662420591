import { FC } from 'react';

import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';

import { FlowControlsStyled, FlowControlStyled } from './FlowControls.styles';
import { FlowControlsProps } from './FlowControls.types';
import { STEP_ACTION } from '../../../@types/course';
import IframeHelper from '../../../helpers/IframeHelper';
import history from '../../../routes/history';

export const FlowControls: FC<FlowControlsProps> = ({
  steps = [],
  currentStep = 0,
  hideStepDetails = false,
  onStepChange: handleStepChange,
}) => {
  const step = steps[currentStep];
  const { indicator, title } = step ?? {};

  const isIframeView = IframeHelper.isIframeScreen();

  return (
    <div className='flex flex-col justify-center'>
      <FlowControlsStyled>
        <FlowControlStyled
          onClick={() => handleStepChange && handleStepChange(STEP_ACTION.BACKWARD)}
        >
          <ArrowLeftOutlined className={`text-[20px] ${!indicator && 'hidden'}`} />
        </FlowControlStyled>
        {!hideStepDetails && (
          <p className='text-xs text-center text-gray-700 mt-4'>{`${
            !!indicator && indicator + 1
          } / ${steps.length}`}</p>
        )}
        {!isIframeView && (
          <FlowControlStyled onClick={() => history.push(`/courses`)}>
            <CloseOutlined className='text-[20px]' />
          </FlowControlStyled>
        )}
      </FlowControlsStyled>
      {!hideStepDetails && <p className='text-xl text-center mt-4 font-bold'>{title}</p>}
    </div>
  );
};

export default FlowControls;
