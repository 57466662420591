const LgEllipsis = () => {
  return (
    <svg width='20' height='4' viewBox='0 0 20 4'>
      <circle cx='2' cy='2' r='2'></circle>
      <circle cx='10' cy='2' r='2'></circle>
      <circle cx='18' cy='2' r='2'></circle>
    </svg>
  );
};

export { LgEllipsis };
