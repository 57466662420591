import { FC, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal } from 'antd';

import { User } from '../../@types/user';
import message from '../../common/message';
import { getPartnerCompanyId } from '../../helpers/util';
import history from '../../routes/history';

interface ICreate {
  visible: boolean;
  current: User;
  createClause: Function;
  createParagraph: Function;
  onCancel: Function;
}

const Create: FC<ICreate> = ({ visible, onCancel, createClause, createParagraph, current }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onFinish = (values: any) => {
    setLoading(true);
    createClause({
      payLoad: {
        name: values.name,
        isMandatory: true,
        isEditable: false,
        partnerCompanyId: getPartnerCompanyId(current),
        createdBy: (current || {}).id,
      },
    }).then(
      (response: any) => {
        const { id } = response.data.result;
        createParagraph({ options: { clauseID: id }, payLoad: { sectionID: id } }).then(
          (r: any) => {
            const { fileID, sectionParagraphID } = r.data.result.id;
            setLoading(false);
            message.success('Clause created successfully');
            history.push(`/clause/${id}/version/${sectionParagraphID}/file/${fileID}`);
          },
          (errors: Array<any>) => {
            message.error(errors);
            setLoading(false);
          },
        );
      },
      (errors: Array<any>) => {
        message.error(errors);
        setLoading(false);
      },
    );
  };
  return (
    <Modal visible={visible} title={null} footer={null} width={1000} onCancel={() => onCancel()}>
      <div className='grid grid-cols-2 gap-4 p-4'>
        <div className={'relative'}>
          <div className='px-10'>
            <p className={'font-bold font-lg'}>Clause</p>
            <p className='my-4'>
              This tool will provide you with the ability to create a reusable library of clauses
              for your contract templates. To start, please provide a name for your clause.
            </p>
          </div>
          <Divider className={'absolute -right-8 top-8'} type='vertical' style={{ height: 270 }} />
        </div>
        <div className={'px-12'}>
          <div className={'flex flex-start mb-8 items-center'}>
            <span className={'ml-1'}>Create a clause</span>
          </div>
          <div className='flex'>
            <Form
              name='clauseForm'
              className={'w-full'}
              layout='vertical'
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label='Clause title'
                name='name'
                rules={[{ required: true, message: 'Clause title is required' }]}
              >
                <Input className='form__input form__input--block' placeholder='eg. Clause title' />
              </Form.Item>

              <Form.Item>
                <div className='flex justify-between gap-4'>
                  <Button
                    className='btn btn--default'
                    block
                    size={'large'}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='btn btn--primary'
                    type='primary'
                    htmlType='submit'
                    block
                    size={'large'}
                    loading={loading}
                  >
                    Next
                    <ArrowRightOutlined className={'float-right mt-1'} />
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Create;
