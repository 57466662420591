import { RematchDispatch } from '@rematch/core';
import _ from 'lodash';

import { CourseParam, courseSection, courseSectionItem } from '../@types/course';
import { QueryFilter } from '../@types/global';
import { Handbook } from '../@types/hbook';
import { TEMP_CONTENT_ID } from '../common/constants/application';
import { platformViewTypes } from '../common/constants/entities';
import stateStorage from '../helpers/stateStorage';
import { move, mustBeArray } from '../helpers/util';
import HandbookService from '../services/HandbookService';

type State = {
  handbooks: ReadonlyArray<Handbook>;
  handbook: any;
  sections: Array<courseSection>;
  sectionItem: courseSectionItem;
  total: number;
};

const transformSections = (item: any) => ({ ...item, sectionItems: item.contents });
const model = {
  state: {} as State,
  reducers: {
    loadList: (
      state: State,
      payload: { rows: ReadonlyArray<Handbook>; total: number; page: number },
    ) => {
      const pageView =
        (stateStorage.getValue('HandbookPageView') || {}).view || platformViewTypes['Grid'];
      return pageView === platformViewTypes['Table'] || payload.page === 1
        ? {
            ...state,
            handbooks: payload.rows,
            total: payload.total,
          }
        : {
            ...state,
            handbooks: [...mustBeArray(state.handbooks), ...payload.rows],
            total: payload.total,
          };
    },
    loadSections: (
      state: State,
      payload: { rows: ReadonlyArray<courseSection>; total: number },
    ) => {
      return {
        ...state,
        sections: mustBeArray(payload.rows).map((item) => transformSections(item)),
      };
    },
    delete: (state: State, payload: number) => {
      return {
        ...state,
        handbooks: _.filter(state.handbooks, (handbook) => handbook.id !== payload),
      };
    },
    setSectionItem: (state: State, payload: any) => {
      return { ...state, sectionItem: payload };
    },
    setEmptySectionItem: (state: State) => {
      return { ...state, sectionItem: { title: '', id: TEMP_CONTENT_ID } };
    },
    detail: (state: State, payload: any) => {
      return { ...state, handbook: payload };
    },
    removeSection: (state: State, sectionID: number) => {
      return {
        ...state,
        sections: _.filter(state.sections, (section) => section.id !== sectionID),
      };
    },
    resortSectionItems: (
      state: State,
      payload: { sectionID: number; list: Array<courseSectionItem> },
    ) => {
      const newSection = _.cloneDeep(state.sections);
      _.each(newSection, (section) => {
        if (section.id === payload.sectionID) {
          section.sectionItems = payload.list;
        }
      });
      return { ...state, sections: newSection };
    },
    removeContentItem(state: State, payload: { sectionID: number; contentItemID: number }) {
      const newSection = _.cloneDeep(state.sections);
      _.each(newSection, (section) => {
        if (section.id === payload.sectionID) {
          section.sectionItems = _.filter(
            section.sectionItems,
            (item) => item.id !== payload.contentItemID,
          );
        }
      });
      return { ...state, sections: newSection };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getHandbooks(params: QueryFilter) {
      dispatch.ui.start();
      const response = await HandbookService.listHandbooks(params);
      const { result } = response.data;
      dispatch.ui.stop();
      dispatch.hbook.loadList({ ...result, page: params.page });
    },
    async getHandbook({ options }: any) {
      dispatch.ui.start();
      const response: any = await HandbookService.handbookActionHandler({
        action: 'get',
        id: options.id,
      });
      dispatch.ui.stop();
      return dispatch.hbook.detail(response?.data?.result);
    },
    async deleteHandbook({ options }: any) {
      await HandbookService.handbookActionHandler({ action: 'delete', id: options.id });
      return dispatch.hbook.delete(options.id);
    },
    async updateHandbook({ options, payLoad }: any) {
      return HandbookService.handbookActionHandler({ action: 'update', id: options.id, payLoad });
    },
    async uploadHandbookFiles({ options, payLoad }: any) {
      return HandbookService.handbookActionHandler({ action: 'upload', options, payLoad });
    },
    async listSections({ options }: any) {
      const response: any = await HandbookService.handbookSectionActionHandler({
        action: 'list',
        options,
      });
      dispatch.hbook.loadSections(response.data.result);
    },
    async createHandbook({ payLoad }: any) {
      return HandbookService.handbookActionHandler({ action: 'create', payLoad });
    },
    async createSection({ options, payLoad }: { options: any; payLoad: any }) {
      return HandbookService.handbookSectionActionHandler({ action: 'create', payLoad, options });
    },
    async updateSection({ options, payLoad }: { options: any; payLoad: any }) {
      return HandbookService.handbookSectionActionHandler({
        action: 'update',
        payLoad,
        options,
        id: options.sectionID,
      });
    },
    async deleteSection({ options }: { options: CourseParam }) {
      await HandbookService.handbookSectionActionHandler({
        id: options?.sectionID,
        action: 'delete',
        options,
      });
      dispatch.hbook.removeSection(options.sectionID);
    },
    async getContentItem({ options }: any) {
      const response: any = await HandbookService.contentActionHandler({
        action: 'get',
        options,
        id: options.id,
      });
      dispatch.hbook.setSectionItem(response.data.result);
      return response;
    },
    async saveContentItem({ options, payLoad }: any) {
      return HandbookService.contentActionHandler({ action: 'post', payLoad, options });
    },
    async updateContentItem({ options, payLoad }: any) {
      return HandbookService.contentActionHandler({
        action: 'patch',
        payLoad,
        options,
        id: options.sectionItemID,
      });
    },
    async deleteSectionItem({ options }: any) {
      await HandbookService.contentActionHandler({
        id: options.sectionItemID,
        action: 'delete',
        options,
      });
      dispatch.hbook.removeContentItem({
        sectionID: options.sectionID,
        contentItemID: options.sectionItemID,
      });
    },
    async deleteLectureResource({ options }: any) {
      return HandbookService.contentResourcesHandler({
        action: 'delete',
        id: options.resourceID,
        options,
      });
    },
    async dragSectionItems({
      options,
      payLoad,
      source,
      destination,
      list,
    }: {
      source: any;
      payLoad: any;
      destination: any;
      options: any;
      list: any;
    }) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      dispatch.hbook.resortSectionItems({ sectionID: options.sectionID, list: cloneList });
      payLoad = {
        ...payLoad,
        sections: [
          {
            id: options.sectionID,
            contents: _.map(cloneList, (sl) => sl.id),
          },
        ],
      };
      await HandbookService.sectionItemsDrag({ handbookID: options.id, payLoad });
    },
    async dragSections({
      options,
      payLoad,
      source,
      destination,
      list,
    }: {
      source: any;
      payLoad: any;
      destination: any;
      options: any;
      list: any;
    }) {
      const cloneList = move(_.clone(list), source.index, destination.index);
      payLoad = {
        ...payLoad,
        sections: _.map(cloneList, (l) => {
          return {
            id: l.id,
            contents: _.map(l.sectionItems, (sl) => sl.id),
          };
        }),
      };
      dispatch.hbook.loadSections({ rows: cloneList, total: cloneList.length });
      return HandbookService.sectionItemsDrag({ handbookID: options.id, payLoad });
    },
    async deleteContentResource({ options }: { options: any }) {
      return HandbookService.contentResourcesHandler({
        action: 'delete',
        id: options.resourceID,
        options,
      });
    },
  }),
};

export default model;
