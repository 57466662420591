import { useEffect, useState } from 'react';

import { message, Modal } from 'antd';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { connect } from 'react-redux';

import { ContractDetail, ContractType } from '../../../@types/contract';
import { Error } from '../../../@types/global';
import { User } from '../../../@types/user';
import ConfirmBox from '../../../common/ConfirmBox';
import Loading from '../../../common/Loading';
import DocumentHeader from '../../../components/Document/Header';
import DocumentHelper from '../../../helpers/DocumentHelper';
import stateStorage from '../../../helpers/stateStorage';
import { getPartnerCompanyId, isNullUndefined } from '../../../helpers/util';
import history from '../../../routes/history';
import { RootDispatch, RootState } from '../../../store';

interface IEditPageActions {
  contract: ContractDetail;
  contractTypes: ReadonlyArray<ContractType>;
  getContractVersion: Function;
  getContractVersionPreview: Function;
  uploadContractDocument: Function;
  updateContract: Function;
  publishContract: Function;
  deleteContract: Function;
  previewWithSections: Function;
  retireContract: Function;
  draftContract: Function;
  current: User;
}

interface IDocumentSettings {
  contractTypeID?: number;
  countryId?: number;
  description?: string;
  id?: string;
  imageLoading?: boolean;
  referenceNumber?: string;
  requireClientSignature?: boolean;
  requireEmployeeAcknowledgement?: boolean;
  requireEmployeeSignature?: boolean;
  releaseNotes?: string | null | undefined;
  title?: string;
  videoLoading?: boolean;
}

const EditAction = ({
  contract,
  contractTypes,
  getContractVersion,
  getContractVersionPreview,
  updateContract,
  uploadContractDocument,
  publishContract,
  deleteContract,
  previewWithSections,
  retireContract,
  draftContract,
  current,
}: IEditPageActions) => {
  const [isVersionOpen, setVersionOpen] = useState(false);
  const [documentSettingVisible, setDocumentSettingVisible] = useState(false);
  const [publishSettingVisible, updatePublishSettingVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [retireVisible, setRetireVisible] = useState(false);
  const [saving, updateSaving] = useState(false);
  const [settings, updateSettings] = useState<IDocumentSettings>({});
  const [previewData, updatePreviewData] = useState({});

  useEffect(() => {
    if (contract) {
      updateSettings({
        contractTypeID: contract.contractTypeID,
        countryId: contract.countryId,
        description: contract.description,
        referenceNumber: contract.referenceNumber,
        requireClientSignature: contract.requireClientSignature,
        requireEmployeeAcknowledgement: contract.requireEmployeeAcknowledgement,
        requireEmployeeSignature: contract.requireEmployeeSignature,
        releaseNotes: contract.releaseNotes,
        title: contract.title,
      });
    }
  }, [contract]);

  const isFieldValid = () => {
    const { title, countryId } = settings;

    if (_.isUndefined(title) || _.isEmpty(title.trim())) {
      return message.error('contract name is required');
    }
    if (title.length > 200) {
      return message.error('contract name exceed character limit');
    }
    if (!countryId) {
      return message.error('You need to select country');
    }
  };

  const saveDocumentSettings = () => {
    const {
      title,
      description,
      contractTypeID,
      referenceNumber,
      countryId,
      requireEmployeeSignature,
      requireClientSignature,
      requireEmployeeAcknowledgement,
    } = settings;

    const questions = stateStorage.getValue('contractQuestion', []);

    const payload = {
      title,
      description,
      contractTypeID,
      referenceNumber,
      countryId,
      requireEmployeeSignature,
      requireClientSignature,
      requireEmployeeAcknowledgement,
      questions: JSON.stringify(questions),
    };
    isFieldValid();

    if (contract.contractTypeID !== contractTypeID) {
      const selContract = _.find(contractTypes, (ct) => ct.id === contractTypeID) || {
        questions: '',
      };
      payload['questions'] = selContract.questions;
    }

    updateSaving(true);
    updateContract({ options: { assetID: contract.assetID }, payload }).then(
      () => {
        updateSaving(false);
        setDocumentSettingVisible(false);
      },
      (err: any) => {
        updateSaving(false);
      },
    );
  };

  const saveContract = () => {
    const {
      title,
      description,
      contractTypeID,
      referenceNumber,
      countryId,
      requireEmployeeSignature,
      requireClientSignature,
      requireEmployeeAcknowledgement,
    } = settings;

    isFieldValid();
    const questions = stateStorage.getValue('contractQuestion', []);

    const payload = {
      title,
      description,
      contractTypeID,
      referenceNumber,
      countryId,
      requireEmployeeSignature,
      requireClientSignature,
      requireEmployeeAcknowledgement,
      questions: JSON.stringify(questions),
    };

    if (contract.contractTypeID !== contractTypeID) {
      const selContract = _.find(contractTypes, (ct) => ct.id === contractTypeID) || {
        questions: '',
      };
      payload.questions = selContract.questions;
    }

    return updateContract({ options: { assetID: contract.assetID }, payload });
  };

  const saveDocument = (data: any) => {
    const questions = stateStorage.getValue('contractQuestion', []);
    return uploadContractDocument({
      options: { id: contract.fileID },
      payload: {
        file: data.file,
        questions,
        name: `${contract.title}.${data.type}`,
      },
    });
  };

  const onContractPublish = () => {
    const { releaseNotes, description } = settings;
    const { assetID, title } = contract;
    isFieldValid();
    if (_.isUndefined(description) || _.isEmpty(description.trim())) {
      return message.error('Contract description is required');
    }
    updateSaving(true);
    saveContract().then(() => {
      const documentEditor = DocumentHelper.get();
      documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
        saveDocument({ file: doc, type: 'Docx', title }).then(() => {
          publishContract({
            options: { assetID: contract.assetID },
            payload: {
              assetID,
              releaseNotes,
              modifiedBy: (current || {}).id,
              partnerCompanyId: getPartnerCompanyId(current),
            },
          }).then(
            () => {
              updateSaving(false);
              message.success('Contract published successfully');
              close();
            },
            () => message.error('Error'),
          );
        });
      });
    });
  };

  const saveAsDraft = () => {
    const { title } = contract;
    isFieldValid();
    updateSaving(true);
    saveContract().then(() => {
      const documentEditor = DocumentHelper.get();
      documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
        saveDocument({ file: doc, type: 'Docx', title }).then(() => {
          updateSaving(false);
          message.success('Contract saved successfully');
          close();
        });
      });
    });
  };

  const onDeleteContract = () => {
    ConfirmBox({
      title:
        'You are about to discard this draft of contract. This action cannot be undone and all changes will be lost permanently. Are you sure you want to proceed with',
      contentName: contract.title,
      onOk: () => {
        deleteContract({ options: { assetID: contract.assetID } }).then(
          () => {
            message.success('Contract deleted successfully');
            close();
          },
          () => message.error('Contract cannot be deleted.'),
        );
      },
    });
  };

  const printContract = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.print();
  };

  const downloadContract = () => {
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
      saveAs(doc, `${contract.title}.docx`);
    });
  };

  const close = () => {
    history.push('/contracts');
  };

  const previewContract = () => {
    const answers: any = {};
    const questions = stateStorage.getValue('contractQuestion', []);

    _.each(questions, (s) => {
      _.each(s.questions, (q) => {
        if (q.value) {
          if (q.name === 'ProbationaryPeriod') {
            answers['ContractHasProbation'] = q.notApplicable ? 'No' : 'Yes';
            answers['ContractProbationLength'] =
              answers['ContractHasProbation'] === 'No'
                ? ''
                : !_.isUndefined(q.period)
                ? q.value + ' ' + q.period
                : q.value;
          } else if (q.name === 'NoticePeriod') {
            answers['ContractEmployerNoticePeriod'] =
              (isNullUndefined(q.notice1value) ? '' : q.notice1value) +
              ' ' +
              (isNullUndefined(q.notice1Period) ? '' : q.notice1Period);
            answers['ContractEmployerNoticePeriod2'] =
              (isNullUndefined(q.notice2value) ? '' : q.notice2value) +
              ' ' +
              (isNullUndefined(q.notice2Period) ? '' : q.notice2Period);
            answers['ContractEmployerNoticePeriod3'] =
              (isNullUndefined(q.notice3value) ? '' : q.notice3value) +
              ' ' +
              (isNullUndefined(q.notice3Period) ? '' : q.notice3Period);
            answers['ContractEmployerNoticePeriod4'] =
              (isNullUndefined(q.notice4value) ? '' : q.notice4value) +
              ' ' +
              (isNullUndefined(q.notice4Period) ? '' : q.notice4Period);
          } else {
            answers[q.name] = q.values
              ? _.reduce(
                  q.values,
                  (a, b) => {
                    a += ` ${b.value} ${b.period} `;
                    return a;
                  },
                  '',
                )
              : q.value;
          }
        }
        if (q.type === 5 && _.isUndefined(q.value)) {
          answers[q.name] = false;
        }
      });
    });
    const documentEditor = DocumentHelper.get();
    documentEditor.documentEditor.saveAsBlob('Docx').then((doc: any) => {
      const payload = { file: doc, questions: answers };
      previewWithSections({
        options: {
          assetID: contract.assetID,
          versionNumber: isNullUndefined(contract.versionNumber) ? '' : contract.versionNumber,
        },
        payload,
      }).then(
        (response: any) => {
          if (response.status === 200) {
            updatePreviewData({ data: response.response });
            setPreviewVisible(true);
            updateSaving(false);
          } else {
            message.error(response.statusText);
          }
        },
        (errors: Array<Error>) => {
          message.error(errors);
        },
      );
    });
  };

  const onRetireContract = () => {
    const { releaseNotes } = settings;
    const { assetID, versionNumber } = contract;
    retireContract({
      options: { assetID: contract.assetID, versionNumber },
      payload: {
        assetID,
        retirementNotes: releaseNotes,
        modifiedBy: (current || {}).id,
        partnerCompanyId: getPartnerCompanyId(current),
      },
    }).then(
      () => {
        updateSaving(false);
        message.success('Contract retired successfully');
        close();
      },
      (errors: Array<Error>) => message.error(errors),
    );
  };

  const onNewDraftRelease = () => {
    Modal.confirm({
      title: 'New Draft',
      content: `You are about to create a new draft for this contract based on the current version: ${contract.versionNumber}`,
      okText: 'Create Draft',
      cancelText: 'Cancel',
      onOk: () => {
        const { assetID, versionNumber } = contract;
        draftContract({ options: { assetID, versionNumber } }).then(
          ({ data }: any) => {
            history.push(
              `/contract/${data?.result?.assetID}/version/${data?.result?.versionNumber}`,
            );
            window.location.reload();
          },
          () => message.error('Error placeholder'),
        );
      },
    });
  };

  const handleActionClick = (action: string) => {
    switch (action) {
      case 'Version History':
        setVersionOpen(true);
        break;
      case 'Document Setting':
        setDocumentSettingVisible(true);
        break;
      case 'Publish':
        updatePublishSettingVisible(true);
        break;
      case 'Save As Draft':
        saveAsDraft();
        break;
      case 'Delete':
        onDeleteContract();
        break;
      case 'Print':
        printContract();
        break;
      case 'Download':
        downloadContract();
        break;
      case 'Preview':
        previewContract();
        break;
      case 'Retire':
        setRetireVisible(true);
        break;
      case 'New Draft Version':
        onNewDraftRelease();
        break;
      default:
        close();
    }
  };

  const updateContractSetting = (changes: any) => {
    updateSettings({ ...settings, ...changes });
  };

  if (isNullUndefined(contract)) return <Loading />;

  const publishConfig: Array<any> = [
    {
      label: 'Contract title',
      type: 'textbox',
      placeholder: 'Contract title',
      confirmEdit: contract.statusId === 4,
      key: 'title',
      display: true,
    },
    {
      label: 'Contract type',
      type: 'contractTypeDD',
      data: 'contentTypes',
      placeholder: 'Select Type',
      disabled: contract.statusId !== 1,
      key: 'contractTypeID',
      display: true,
    },
    {
      label: 'Country',
      placeholder: 'Select country',
      disabled: contract.statusId !== 1,
      type: 'countryDD',
      key: 'countryId',
      display: true,
    },
    {
      label: 'Contract reference',
      type: 'textbox',
      placeholder: 'Reference No',
      key: 'referenceNumber',
      display: true,
    },
    {
      label: 'Write a description or purpose',
      type: 'textarea',
      placeholder: 'description',
      key: 'description',
      display: true,
    },
    {
      label: 'Require employee signature',
      type: 'switch',
      placeholder: '',
      display: true,
      inverse: 'requireEmployeeAcknowledgement',
      key: 'requireEmployeeSignature',
    },
    {
      label: 'Require client signature',
      type: 'switch',
      placeholder: '',
      display: true,
      key: 'requireClientSignature',
    },
    {
      label: 'Require employee acknowledgement',
      type: 'switch',
      placeholder: '',
      display: true,
      inverse: 'requireEmployeeSignature',
      key: 'requireEmployeeAcknowledgement',
    },
  ];

  return (
    <DocumentHeader
      content={contract}
      publishConfig={publishConfig}
      handleActionClick={handleActionClick}
      isVersionOpen={isVersionOpen}
      current={current}
      setVersionOpen={setVersionOpen}
      getContractVersion={getContractVersion}
      getContractVersionPreview={getContractVersionPreview}
      documentSettingVisible={documentSettingVisible}
      setDocumentSettingVisible={setDocumentSettingVisible}
      saving={saving}
      saveDocumentSettings={saveDocumentSettings}
      settings={settings}
      updateContractSetting={updateContractSetting}
      publishSettingVisible={publishSettingVisible}
      updatePublishSettingVisible={updatePublishSettingVisible}
      onContractPublish={onContractPublish}
      retireVisible={retireVisible}
      setRetireVisible={setRetireVisible}
      onRetireContract={onRetireContract}
      previewVisible={previewVisible}
      setPreviewVisible={setPreviewVisible}
      previewData={previewData}
    />
  );
};

const mapProps = (state: RootState) => ({
  current: state.users.current,
  contractTypes: state.contract.contractTypes,
  contract: state.contract.contract,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  retireContract: dispatch.contract.retireContract,
  uploadContractDocument: dispatch.contract.uploadContractDocument,
  deleteContract: dispatch.contract.deleteContract,
  draftContract: dispatch.contract.draftContract,
  getContractVersion: dispatch.contract.getContractVersion,
  getContractVersionPreview: dispatch.contract.getContractVersionPreview,
  updateContract: dispatch.contract.updateContract,
  publishContract: dispatch.contract.publishContract,
  previewWithSections: dispatch.contract.previewWithSections,
});

export default connect(mapProps, mapDispatch)(EditAction);
