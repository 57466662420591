import React, { FC, useEffect } from 'react';

import { Select } from 'antd';
import { connect } from 'react-redux';

import { CourseLevel } from '../../@types/course';
import { mustBeArray } from '../../helpers/util';
import { RootDispatch, RootState } from '../../store';

interface ICourseLevel {
  courseLevel: ReadonlyArray<CourseLevel>;
  lookupCourseLevel: Function;
  onChange: Function;
  value?: number;
}

const CourseLevelLookup: FC<ICourseLevel> = ({
  lookupCourseLevel,
  onChange,
  value,
  courseLevel,
}) => {
  useEffect(() => {
    lookupCourseLevel();
  }, []);
  return (
    <>
      <Select showSearch style={{ width: '100%' }} value={value} onChange={(v) => onChange(v)}>
        <Select.Option key='dummy' value={null}>
          Select Level
        </Select.Option>
        {courseLevel &&
          mustBeArray(courseLevel).map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

const mapProps = (state: RootState) => ({
  courseLevel: state.lookup.courseLevel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  lookupCourseLevel: dispatch.lookup.lookupCourseLevel,
});

export default connect(mapProps, mapDispatch)(CourseLevelLookup);
