import styled from 'styled-components';

export const CourseSettingsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlowControlsStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlowControlStyled = styled.span`
  cursor: pointer;
`;
