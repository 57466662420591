import { Editor } from '@tinymce/tinymce-react';

import { TINYMCE_APIKEY } from './constants/config';
import Loading from './Loading';

interface IRTEditorProps {
  value: string | null | undefined;
  visible: boolean;
  loading?: boolean;
  image?: boolean;
  attachment?: boolean;
  height?: number;
  placeholder?: string;
  actionUploadImage?: Function;
  actionUploadAttachment?: Function;
  onChange?: (value: string) => void;
  handleEditorChange?: (value: string) => void;
}

interface IConfiguration {
  plugins: string;
  height: number;
  menubar: string;
  toolbar: string;
  preview_styles: string;
  relative_urls: boolean;
  remove_script_host: boolean;
  convert_urls: boolean;
  paste_data_images: boolean;
  fontsize_formats: string;
  file_browser_callback_types?: string;
  file_picker_callback?: any;
  selector?: undefined;
  target?: undefined;
}

const RTEditor = ({
  value,
  loading,
  image,
  attachment,
  height,
  actionUploadImage,
  actionUploadAttachment,
  onChange,
  handleEditorChange,
}: IRTEditorProps) => {
  if (loading) return <Loading />;
  let toolBar =
    ' bold italic | alignleft aligncenter alignright | bullist numlist | sizeselect | fontselect fontsizeselect | fontsize forecolor backcolor | link';
  if (image) toolBar += ' image';
  if (attachment) toolBar += ' file';

  const initialConfiguration: IConfiguration = {
    plugins: 'lists image link wordcount',
    height: height || 450,
    menubar: '',
    toolbar: toolBar,
    preview_styles: 'font-size color',
    relative_urls: false,
    remove_script_host: false,
    convert_urls: true,
    paste_data_images: true,
    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
    selector: undefined,
    target: undefined,
  };

  if (image) {
    initialConfiguration.file_browser_callback_types = 'image';
    initialConfiguration.file_picker_callback = actionUploadImage;
  }
  if (attachment) {
    initialConfiguration.file_browser_callback_types = 'file';
    initialConfiguration.file_picker_callback = actionUploadAttachment;
  }

  const handleChange = (value: string) => {
    handleEditorChange?.(value);
    onChange?.(value);
  };

  return (
    <>
      <input id='my-file' type='file' name='my-file' style={{ display: 'none' }} />
      <Editor
        value={value || ' '}
        onEditorChange={handleChange}
        apiKey={TINYMCE_APIKEY}
        init={initialConfiguration}
      />
    </>
  );
};

export default RTEditor;
