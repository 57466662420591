import React, { useEffect } from 'react';

import { Select } from 'antd';
import { connect } from 'react-redux';

import { LetterCategory } from '../../@types/letter';
import { mustBeArray } from '../../helpers/util';
import { RootDispatch, RootState } from '../../store';

type LetterCategoryProps = {
  listLetterCategory: Function;
  letterCategories: ReadonlyArray<LetterCategory>;
  countryId?: number;
  disabled?: boolean;
  value?: number;
  placeholder?: string;
  onChange?: any;
};

const LetterCategoryLookup = ({
  listLetterCategory,
  letterCategories,
  onChange,
  disabled,
  value,
  countryId,
}: LetterCategoryProps) => {
  useEffect(() => {
    listLetterCategory({ countryId });
  }, [countryId]);

  const triggerChange = (v: any) => {
    onChange?.(v);
  };

  return (
    <>
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder={'Letter Category'}
        disabled={disabled}
        value={value}
        onChange={(v) => triggerChange(v)}
      >
        {letterCategories &&
          mustBeArray(letterCategories).map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.value}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

const mapProps = (state: RootState) => ({
  letterCategories: state.letter.letterCategory,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  listLetterCategory: dispatch.letter.listLetterCategory,
});

export default connect(mapProps, mapDispatch)(LetterCategoryLookup);
