import React from 'react';

import { IconState } from '../../../@types/global';

export const CreateIcon = (props: IconState) => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'
        fill='#0B78FF'
        stroke='#0B78FF'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 8v8M8 12h8'
        stroke='#fff'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export const ChatIcon = (props: IconState) => {
  return (
    <svg width={18} height={18} viewBox='0 0 18 18' fill='none' {...props}>
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M17.25 11.75a1.833 1.833 0 01-1.833 1.833h-11L.75 17.25V2.583A1.833 1.833 0 012.583.75h12.834a1.833 1.833 0 011.833 1.833v9.167z'
          stroke='#000'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h18v18H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
export const NotificationIcon = (props: IconState) => {
  return (
    <svg width={18} height={20} viewBox='0 0 18 20' fill='none' {...props}>
      <g
        clipPath='url(#prefix__clip0)'
        stroke='#555'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M14.252 6.15a5.4 5.4 0 10-10.802 0c0 6.302-2.7 8.102-2.7 8.102h16.203s-2.7-1.8-2.7-8.101v0zM10.408 17.853a1.8 1.8 0 01-3.115 0' />
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h18v19.5H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
