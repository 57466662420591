import { Log, User, UserManager, WebStorageStateStore } from 'oidc-client';

import { ApplicationName, AuthenticationResultStatus } from '../common/constants/auth';
import { Constants } from '../helpers/Auth';
import history from '../routes/history';

export class AuthService {
  private userManager: UserManager | undefined;
  private _callbacks: any[] = [];
  private _nextSubscriptionId = 0;
  private _user: User | null | undefined = null;
  private _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  private _popUpDisabled = true;

  constructor() {
    const settings = this.getSettings();
    this.userManager = new UserManager({
      ...settings,
      automaticSilentRenew: true,
      userStore: new WebStorageStateStore({
        prefix: ApplicationName,
      }),
    });

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public async getUser(): Promise<any> {
    if (this._user && this._user.profile) {
      return this._user.profile;
    }

    await this.ensureUserManagerInitialized();
    const user = await this.userManager?.getUser();
    return user && user.profile;
  }

  public async getAccessToken(): Promise<any> {
    await this.ensureUserManagerInitialized();
    const user = await this.userManager?.getUser();
    return user && user.access_token;
  }

  public async getUserManagerUser(): Promise<any> {
    await this.ensureUserManagerInitialized();
    return this.userManager?.getUser();
  }

  public async login(state?: any): Promise<any> {
    await this.ensureUserManagerInitialized();
    try {
      const silentUser = await this.userManager?.signinSilent(this.createArguments());
      this.updateState(silentUser);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log('Silent authentication error: ', silentError);

      try {
        if (this._popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
          );
        }

        const popUpUser = await this.userManager?.signinPopup(this.createArguments());
        this.updateState(popUpUser);
        return this.success(state);
      } catch (popUpError) {
        if (popUpError instanceof Error) {
          if (popUpError.message === 'Popup window closed') {
            // The user explicitly cancelled the login action by closing an opened popup.
            return this.error('The user closed the window.');
          } else if (!this._popUpDisabled) {
            console.log('Popup authentication error: ', popUpError);
          }
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.userManager?.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          if (typeof redirectError === 'string') {
            console.log('Redirect authentication error: ', redirectError);
            return this.error(redirectError);
          }
        }
      }
    }
  }

  public async loginCallback(url: string): Promise<any> {
    try {
      await this.ensureUserManagerInitialized();
      const user = await this.userManager?.signinCallback(url);
      this.updateState(user);
      return this.success(user && user.state);
    } catch (error) {
      if (typeof error === 'string') {
        console.log('There was an error signing in: ', error);
        return this.error('There was an error signing in.');
      }
    }
  }

  public async logoutCallback(url: string): Promise<any> {
    await this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager?.signoutCallback(url);
      this.updateState(null);
      return this.success(response && (response as any).data);
    } catch (error) {
      if (typeof error === 'string') {
        console.log(`There was an error trying to log out '${error}'.`);
        return this.error(error);
      }
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    const user = await this.getUser();
    return !!user;
  }

  public async renewToken(): Promise<any> {
    await this.ensureUserManagerInitialized();
    try {
      const silentUser = await this.userManager?.signinSilent(this.createArguments());
      this.updateState(silentUser);
      return this.success(silentUser);
    } catch (silentError) {
      console.log('Silent authentication error: ', silentError);
    }
  }

  public async logout(state?: any): Promise<any> {
    await this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
        );
      }

      await this.userManager?.signoutPopup(this.createArguments());
      this.updateState(null);
      return this.success(state);
    } catch (popupSignOutError) {
      console.log('Popup signout error: ', popupSignOutError);
      try {
        // We check if the user is sign out on the server.
        const user = await this.userManager?.getUser();
        if (user) {
          await this.userManager?.signoutRedirect(this.createArguments(state));
          return this.redirect();
        }
        return this.redirect();
      } catch (redirectSignOutError) {
        if (typeof redirectSignOutError === 'string') {
          console.log('Redirect signout error: ', redirectSignOutError);
          return this.error(redirectSignOutError);
        }
      }
    }
  }

  private getSettings = () => {
    return {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
      redirect_uri: `${Constants.clientRedirectUrl}`,
      silent_redirect_uri: `${Constants.clientRedirectUrl}`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: `${Constants.clientCallbackUrl}`,
      response_type: 'code',
      scope: Constants.clientScope,
    };
  };

  private createArguments = (state?: any) => {
    return { useReplaceToNavigate: true, data: state };
  };

  private notifySubscribers = () => {
    for (const item of this._callbacks) {
      const callback = item.callback;
      callback();
    }
  };

  private updateState = (user: User | null | undefined) => {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  };

  subscribe(callback: any) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: any) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId ? { found: true, index } : { found: false },
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index!, 1);
  }

  private error = (message: string) => {
    return { status: AuthenticationResultStatus.Fail, message };
  };

  private success = (state: any) => {
    return { status: AuthenticationResultStatus.Success, state };
  };

  private redirect = () => {
    return { status: AuthenticationResultStatus.Redirect };
  };

  private async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return;
    }

    const settings = this.getSettings();

    this.userManager = new UserManager({
      ...settings,
      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: true,
      userStore: new WebStorageStateStore({
        prefix: ApplicationName,
      }),
    });

    await this.userManager.clearStaleState();

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager!.removeUser();
      history.push('/');
    });
  }
}

export const authService = new AuthService();
