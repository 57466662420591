import ReactDOM from 'react-dom';

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import queryString from 'query-string';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import IframeHelper from './helpers/IframeHelper';
import Routes from './routes';
import history from './routes/history';
import IframeRoutes from './routes/iframe.routes';
import store from './store';

import './styles/App.less';
import './styles/index.css';
import 'simplebar/dist/simplebar.min.css';

pdfjs.GlobalWorkerOptions.workerSrc =
  '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js';

const rootElement = document.getElementById('root');
const qObj = queryString.parse(window.location.search);
if (qObj.access_token) {
  IframeHelper.setConfig(qObj);
}

if (process.env.REACT_APP_INSIGHT_KEY) {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_INSIGHT_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });
  appInsights.loadAppInsights();

  if (process.env.REACT_APP_INSIGHT_ROLE_NAME) {
    appInsights.addTelemetryInitializer((envelope) => {
      envelope.tags = envelope.tags || [];
      envelope.tags.push({ 'ai.cloud.role': process.env.REACT_APP_INSIGHT_ROLE_NAME });
    });
  }
  appInsights.trackPageView();
}

const Root = () => (
  <Provider store={store}>
    <Router history={history}>
      {IframeHelper.isIframeScreen() ? <IframeRoutes /> : <Routes />}
    </Router>
  </Provider>
);

ReactDOM.render(<Root />, rootElement);
