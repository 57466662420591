import React, { useState } from 'react';

import { Button, Modal } from 'antd';
import _ from 'lodash';
import { Document, Page } from 'react-pdf';

import PublishSettings from './PublishSetting';
import DocumentSettings from './Setting';
import VersionHistory from './VersionHistory';
import VersionSettings from './VersionSetting';
import { User } from '../../@types/user';
import { contentStatus } from '../../common/constants/entities';
import HeaderActions from '../../common/headerActions';
import { FullWidthWrapper } from '../Content/Styles/FullWidth';

interface IDocumentHeader {
  content: any;
  publishConfig: Array<any>;
  handleActionClick: Function;
  isVersionOpen: boolean;
  current: User;
  setVersionOpen: Function;
  getContractVersion: Function;
  getContractVersionPreview: Function;
  documentSettingVisible: boolean;
  setDocumentSettingVisible: Function;
  saving: boolean;
  saveDocumentSettings: Function;
  settings: any;
  updateContractSetting: Function;
  publishSettingVisible: boolean;
  updatePublishSettingVisible: Function;
  onContractPublish: Function;
  retireVisible: boolean;
  setRetireVisible: Function;
  onRetireContract: Function;
  previewVisible: boolean;
  setPreviewVisible: Function;
  previewData: any;
}

const Header = ({
  content,
  publishConfig,
  handleActionClick,
  isVersionOpen,
  current,
  setVersionOpen,
  getContractVersion,
  getContractVersionPreview,
  documentSettingVisible,
  setDocumentSettingVisible,
  saving,
  saveDocumentSettings,
  settings,
  updateContractSetting,
  publishSettingVisible,
  updatePublishSettingVisible,
  onContractPublish,
  retireVisible,
  setRetireVisible,
  onRetireContract,
  previewVisible,
  setPreviewVisible,
  previewData,
}: IDocumentHeader) => {
  const [numPages, setPageNumbers] = useState(0);
  const actions = [
    { name: 'Version History', style: 'primary', visible: true },
    {
      name: 'Document Setting',
      style: 'default',
      visible: [contentStatus.Draft, contentStatus.DraftReleased].includes(content?.statusId),
    },
    {
      name: 'Publish',
      style: 'default',
      visible: [contentStatus.Draft, contentStatus.DraftReleased].includes(content?.statusId),
    },
    {
      name: 'Save As Draft',
      style: 'default',
      visible: [contentStatus.Draft, contentStatus.DraftReleased].includes(content?.statusId),
    },
    {
      name: 'Retire',
      style: 'danger',
      visible: [contentStatus.Published].includes(content?.statusId),
    },
    {
      name: 'New Draft Version',
      style: 'default',
      visible: [contentStatus.Published, contentStatus.Retired].includes(content?.statusId),
    },
    {
      name: 'Delete',
      style: 'danger',
      visible: [contentStatus.Draft, contentStatus.DraftReleased].includes(content?.statusId),
    },
    { name: 'Print', style: 'default', visible: true },
    { name: 'Preview', style: 'default', visible: true },
    { name: 'Download', style: 'default', visible: true },
    { name: 'Close', style: 'default', visible: true },
  ];

  const validActions = _.filter(actions, (a) => a.visible);
  const onDocumentLoadSuccess = (doc: any) => {
    setPageNumbers(doc.numPages);
  };
  return (
    <>
      <HeaderActions actions={validActions} handleActionClick={handleActionClick} />
      <Modal
        visible={isVersionOpen}
        centered={true}
        width={'60vw'}
        title={'Version Preview'}
        footer={[]}
        onCancel={() => setVersionOpen(false)}
      >
        <VersionHistory
          content={content}
          listVersions={getContractVersion}
          visible={isVersionOpen}
          previewVersionDocument={getContractVersionPreview}
        />
      </Modal>
      <Modal
        visible={documentSettingVisible}
        centered={true}
        title={'Document Settings'}
        width={'60vw'}
        okText={'Save'}
        onCancel={() => setDocumentSettingVisible(false)}
        footer={[
          <Button key='back' onClick={() => setDocumentSettingVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={saving}
            onClick={() => saveDocumentSettings()}
          >
            Save
          </Button>,
        ]}
      >
        <DocumentSettings
          config={publishConfig}
          id={content.assetID}
          visible={documentSettingVisible}
          initialValue={settings}
          updateSettings={updateContractSetting}
          content={content}
        />
      </Modal>
      <Modal
        visible={publishSettingVisible}
        centered={true}
        title={'Publishing'}
        width={'60vw'}
        okText={'Publish'}
        onCancel={() => updatePublishSettingVisible(false)}
        footer={[
          <Button key='back' onClick={() => updatePublishSettingVisible(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={saving} onClick={() => onContractPublish()}>
            Publish
          </Button>,
        ]}
      >
        <PublishSettings
          config={publishConfig}
          visible={publishSettingVisible}
          id={content.id}
          updateSettings={updateContractSetting}
          publisher={current && `${current.givenName} ${current.lastName}`}
          settings={settings}
          type={'contract'}
          content={content}
        />
      </Modal>
      <Modal
        visible={retireVisible}
        centered={true}
        width={'60vw'}
        title={'Retire Contract'}
        onCancel={() => setRetireVisible(false)}
        onOk={() => onRetireContract()}
        okText={'Retire published contract'}
      >
        <VersionSettings
          updateSettings={updateContractSetting}
          visible={retireVisible}
          releaseNotes={settings.releaseNotes}
          publisher={`${current.givenName} ${current.lastName}`}
          mode={'retire'}
          note={true}
          type={'contract'}
        />
      </Modal>
      <Modal
        visible={previewVisible}
        centered={true}
        width={'100vw'}
        footer={null}
        className='ant-modal-document'
        style={{
          position: 'fixed',
          left: 0,
          borderRadius: 0,
          padding: 0,
        }}
        onCancel={() => setPreviewVisible(false)}
      >
        {previewVisible && previewData ? (
          <FullWidthWrapper>
            <div className={'w-full'}>
              <p className={'text-lg'}>{content.title}</p>

              <div style={{ height: 'calc(-70px + 100vh)' }} className={'overflow-y-auto'}>
                <Document file={previewData} onLoadSuccess={onDocumentLoadSuccess}>
                  {[...Array(numPages)].map((e, i) => (
                    <Page
                      key={i}
                      pageNumber={i + 1}
                      width={1100}
                      className='flex flex-center text-center'
                    />
                  ))}
                </Document>
              </div>
            </div>
          </FullWidthWrapper>
        ) : (
          <React.Fragment />
        )}
      </Modal>
    </>
  );
};

export default Header;
