import { useState } from 'react';

import { Dropdown, Menu, Modal } from 'antd';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { IRole } from '../../@types/user';
import { CreateIcon } from '../../common/assets';
import { TEMP_CONTENT_ID } from '../../common/constants/application';
import CreateClause from '../../components/Clause/Create';
import CreateContract from '../../components/Contract/Create';
import CreateHandbook from '../../components/Handbook/Create';
import CreateLetter from '../../components/Letter/Create';
import CreateWorkflow from '../../components/Workflow/Create';
import history from '../../routes/history';
import { RootDispatch, RootState } from '../../store';
import ClientForm from '../Partners/ClientForm';
import PartnerForm from '../Partners/PartnerForm';

const CreateEntity = () => {
  const currentUser = useSelector((state: RootState) => state.users.current);
  const contractTypes = useSelector((state: RootState) => state.contract.contractTypes);
  const dispatch = useDispatch<RootDispatch>();
  const {
    contract: { createContract },
    letter: { createLetter },
    clause: { createClause },
    clause: { createParagraph },
    hbook: { createHandbook },
    workflow: { createWorkflow },
  } = dispatch;

  const [clientVisible, setClientVisible] = useState(false);
  const [partnerVisible, setPartnerVisible] = useState(false);
  const [contractVisible, setContractVisible] = useState(false);
  const [letterVisible, setLetterVisible] = useState(false);
  const [clauseVisible, setClauseVisible] = useState(false);
  const [handbookVisible, setHandbookVisible] = useState(false);
  const [workflowVisible, setWorkflowVisible] = useState(false);

  const initClient = { id: TEMP_CONTENT_ID, name: '', isActive: true };
  const initPartner = { id: TEMP_CONTENT_ID };
  const currentRole: IRole = _.first(currentUser.roles) || { roleName: '', roleCode: '' };

  const menu = (
    <Menu>
      {currentRole.roleCode !== 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => setClientVisible(true)}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Register client
        </Menu.Item>
      )}
      {currentRole.roleCode !== 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => setPartnerVisible(true)}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Register partner
        </Menu.Item>
      )}
      {currentRole.roleCode === 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => setContractVisible(true)}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Contract
        </Menu.Item>
      )}
      {currentRole.roleCode === 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => setClauseVisible(true)}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Clause
        </Menu.Item>
      )}
      {currentRole.roleCode === 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => setLetterVisible(true)}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Letter
        </Menu.Item>
      )}
      {currentRole.roleCode === 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => history.push('/course/create')}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Course
        </Menu.Item>
      )}
      {currentRole.roleCode === 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => setHandbookVisible(true)}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Handbook
        </Menu.Item>
      )}
      {currentRole.roleCode === 'PPSU' && (
        <Menu.Item
          key={nanoid()}
          onClick={() => setWorkflowVisible(true)}
          className={'text-gray-900 dark:text-gray-700 pl-4 pr-12 py-2 text-sm flex items-center'}
        >
          Workflow
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      <Dropdown trigger={['click']} overlay={menu}>
        <CreateIcon />
      </Dropdown>
      <Modal
        visible={clientVisible}
        centered={true}
        width={'60vw'}
        title={'New Client'}
        footer={[]}
        onCancel={() => setClientVisible(false)}
      >
        <ClientForm client={initClient} onCancel={() => setClientVisible(false)} />
      </Modal>
      <Modal
        visible={partnerVisible}
        centered={true}
        width={'60vw'}
        title={'Create Partner'}
        footer={[]}
        onCancel={() => setPartnerVisible(false)}
      >
        <PartnerForm partner={initPartner} onCancel={() => setPartnerVisible(false)} />
      </Modal>
      <CreateContract
        visible={contractVisible}
        contractTypes={contractTypes}
        current={currentUser}
        createContract={createContract}
        onCancel={() => setContractVisible(false)}
      />
      <CreateLetter
        visible={letterVisible}
        createLetter={createLetter}
        current={currentUser}
        onCancel={() => setLetterVisible(false)}
      />
      <CreateClause
        visible={clauseVisible}
        createParagraph={createParagraph}
        createClause={createClause}
        current={currentUser}
        onCancel={() => setClauseVisible(false)}
      />
      <CreateHandbook
        visible={handbookVisible}
        createHandbook={createHandbook}
        current={currentUser}
        onCancel={() => setHandbookVisible(false)}
      />
      <CreateWorkflow
        visible={workflowVisible}
        createWorkflow={createWorkflow}
        current={currentUser}
        onCancel={() => setWorkflowVisible(false)}
      />
    </>
  );
};

export default CreateEntity;
