import _ from 'lodash';

import { ActionHandler, QueryFilter } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';
import { delay } from '../helpers/util';

class ClauseService {
  public listClause(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public sectionHandler({
    id,
    queryOptions = {},
    payLoad = {},
    action,
  }: ActionHandler): Promise<any> {
    let url = '';
    switch (action) {
      case 'list':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL', isMocked: false });
        return http.get(url, queryOptions);
      case 'get':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL', isMocked: false });
        return http.get(`${url}/${id}`);
      case 'create':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL', isMocked: false });
        return http.post(url, payLoad);

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL', isMocked: false });
        return http.del(`${url}/${id}`);

      case 'update':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL', isMocked: false });
        return http.patch(`${url}/${id}`, payLoad);

      case 'preview':
        url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL', isMocked: false });
        return http.postFilePreview(`${url}/${id}/preview`, payLoad);

      default:
        return delay(100);
    }
  }

  public sectionParagraphHandler({
    id,
    options = {},
    queryOptions = {},
    payLoad = {},
    action,
  }: ActionHandler): Promise<any> {
    let url = '';
    switch (action) {
      case 'list':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_PARAGRAPH_URL',
          options: { ...options },
          isMocked: false,
        });
        return http.get(url, queryOptions);

      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_PARAGRAPH_URL',
          options: { ...options },
          isMocked: false,
        });
        return http.get(`${url}/${id}`);

      case 'create':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_PARAGRAPH_URL',
          options: { ...options },
          isMocked: false,
        });
        return http.post(url, payLoad);

      case 'duplicate':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_PARAGRAPH_CLONE_URL',
          options: { ...options },
          isMocked: false,
        });
        return http.post(url, {});

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_PARAGRAPH_URL',
          options: { ...options },
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      case 'update':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_PARAGRAPH_URL',
          options: { ...options },
          isMocked: false,
        });
        return http.patch(`${url}/${id}`, payLoad);

      default:
        return delay(100);
    }
  }

  public getVariantRelatedContracts(clauseID: number) {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'SECTION_URL' });
    return http.get(`${url}/${clauseID}/related-contracts`).catch((error) => {
      throw error;
    });
  }

  public dragSectionParagraph({
    clauseID,
    list,
  }: {
    clauseID: number;
    list: Array<any>;
  }): Promise<any> {
    const payLoad = { paragraphs: _.map(list, (item) => item.id) };
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'SECTION_URL',
      options: { clauseID },
      isMocked: false,
    });
    return http.patch(`${url}/${clauseID}`, payLoad);
  }
}

const instance = new ClauseService();
Object.freeze(instance);

export default instance;
