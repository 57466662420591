export const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <div className='alert alert--error' role='alert'>
      <div className='alert__body'>
        <div className='alert__icon'>
          <p>Something went wrong:</p>
        </div>
        <div className='alert__content'>
          <div className='font-medium text-red-700'>{error.message}</div>
        </div>
      </div>
    </div>
  );
};
