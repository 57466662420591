import React, { Fragment } from 'react';

import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Breadcrumb } from 'antd';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { Link, RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import PageTitle from '../../containers/Header/PageTitle';
import { mustBeArray, templateEngine } from '../../helpers/util';
import routes from '../../routes/routes';

const BreadcrumbNav = withRouter(({ match }: RouteComponentProps) => {
  const currentRoute = _.find(routes, (route) => route.path === match.path) || { breadcrumb: [] };
  const { id } = useParams<any>();
  return (
    <Fragment>
      <div className='flex'>
        <ChevronLeftIcon className='h-5 w-5 mt-0.5' />
        <Breadcrumb>
          {_.map(mustBeArray(currentRoute.breadcrumb), (breadcrumb) => (
            <Breadcrumb.Item key={nanoid()}>
              <Link
                to={
                  breadcrumb.route.includes('<%')
                    ? templateEngine(breadcrumb.route, { clauseID: id })
                    : breadcrumb.route
                }
              >
                {breadcrumb.name}
              </Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
      <PageTitle currentRoute={currentRoute} />
    </Fragment>
  );
});

export default BreadcrumbNav;
