import { RematchDispatch } from '@rematch/core';

import { User } from '../@types/user';
import UserService from '../services/UserService';

type State = {
  current: User;
  users: ReadonlyArray<User>;
};

const model = {
  state: {} as State,
  reducers: {
    loaded: (state: State, payload: ReadonlyArray<User>) => {
      return { ...state, current: payload };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async loadCurrentUser() {
      const response = await UserService.getCurrentUser();
      dispatch.users.loaded(response.data);
    },
  }),
};

export default model;
