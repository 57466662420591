import IframeHandler from './IframeHelper';

class StateStorage {
  private static instance: StateStorage;
  private _storage?: any;

  constructor() {
    if (!StateStorage.instance) {
      StateStorage.instance = this;
    }
    this._storage = {};
    return StateStorage.instance;
  }

  setValue(key: string, value: any) {
    if (IframeHandler.isIframeScreen()) {
      this._storage[key] = JSON.stringify(value);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  clearValue(key: string) {
    sessionStorage.setItem(key, '');
  }

  getValue(key: string, defaultValue?: any) {
    if (IframeHandler.isIframeScreen()) {
      if (this._storage[key]) {
        return JSON.parse(this._storage[key]);
      } else {
        this._storage[key] = defaultValue ? JSON.stringify(defaultValue) : '';
        return defaultValue || '';
      }
    } else {
      if (sessionStorage.getItem(key)) {
        return JSON.parse(sessionStorage.getItem(key) || '{}');
      } else {
        sessionStorage.setItem(key, defaultValue ? JSON.stringify(defaultValue) : '');
        return defaultValue || '';
      }
    }
  }
}

const instance = new StateStorage();
Object.freeze(instance);

export default instance;
