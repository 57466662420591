import { uploadedFile } from './global';

export type CourseLevel = {
  id: number;
  name: string;
};

export type Resource = {
  fileId: number;
  id?: number;
  fileName: string;
  filePath?: string;
  mimeType?: string;
  duration: number;
  createdBy?: string;
  createdOn?: string;
};

export type CourseParam = {
  id?: number;
  sectionID?: number;
  contentType?: string;
  assessmentID?: number;
  sectionItemID?: number;
  questionID?: number;
  type?: string;
  resourceID?: number;
};

export type DeleteCourseAssessmentQuestionParams = {
  id: number;
  assessmentID: number;
  sectionID: number;
  questionID: number;
};

export type CourseCategory = {
  id: number;
  name: string;
};

export type Course = {
  authorName: string;
  categoryID: number;
  categoryName: string;
  statusName: string;
  countryId: Array<number>;
  courseImage: Resource;
  createdBy: string;
  createdDate: string;
  description?: string;
  id: number;
  levelID: number;
  levelName: string;
  modifiedBy: string;
  modifiedByName: string;
  modifiedDate: string;
  name: string;
  partnerCompanyID: string;
  partnerCompanyName: string;
  promotionalVideo: Resource;
  statusID: number;
  isExternalCourse: boolean;
};

export type lectureResource = {
  id: number;
  lectureID?: number;
  fileID?: number;
  fileid?: number;
  filename: string;
  fileName?: string;
  filepath: string;
  filePath?: string;
};

export type AssessmentQuestion = {
  id: number;
  assessmentID: number;
  questionNumber: number;
  title: string;
  guide: string;
  type: string;
  answer: number;
  options?: string;
};

export type courseSectionItem = {
  filePath?: string | null;
  duration?: number;
  description?: string | null;
  id: number;
  courseSectionID?: number;
  title: string;
  contentType?: string;
  lectureType?: string;
  lecturetype?: string;
  hasArticleText?: boolean;
  articleText?: string;
  isLink?: boolean;
  url?: string;
  uri?: string | null;
  fileid?: number;
  fileId?: number;
  file?: string;
  passmark?: number | null;
  questions?: Array<Question>;
  resources?: Array<Resource>;
};

export type courseSection = {
  id: number;
  title: string;
  description: string;
  courseID?: number;
  courseName?: string;
  sectionItems: Array<courseSectionItem>;
};

export enum CourseStatus {
  Draft = 1,
  Published,
  Retired,
  'Draft Release',
}

export type CourseDetail = {
  id: number;
  name: string;
  statusID: CourseStatus;
  statusName: string;
  categoryID?: number;
  categoryName?: string | null;
  levelID?: number;
  levelName?: string | null;
  description?: string;
  partnerCompanyID: string;
  partnerCompanyName?: string;
  createdBy?: string;
  createdByName?: string | null;
  createdDate?: string;
  modifiedBy?: string;
  modifiedByName?: string;
  modifiedDate?: string;
  countryId: Array<number>;
  additionalAttempts?: number;
  completionDays?: number;
  courseImage: uploadedFile;
  externalCourseFile: uploadedFile;
  promotionalVideo: uploadedFile;
  sections: Array<courseSection>;
  canBeUsedForOnboarding?: boolean;
  isGlobalCourse?: boolean;
  isExternalCourse?: boolean;
};

export type Question = {
  id: number | string;
  title: string;
  guide?: string;
  type?: string;
  answer?: number;
  options: Array<string>;
};

export enum COURSE_TYPE {
  BYO = 'BYO',
  UPLOAD = 'UPLOAD',
}

export enum ENTITY_MODE {
  VIEW = 'VIEW',
  PREVIEW = 'PREVIEW',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  REVIEW = 'REVIEW',
}

export enum PUBSUB_COURSE_ACTION {
  INIT_UPDATE = 'INIT_UPDATE',
  END_UPDATE = 'END_UPDATE',
  NO_ACTION = 'NO_ACTION',
}

export enum HR_COURSE_ACTION {
  INIT_UPDATE = 'init:course:publish',
}

export enum STEP_ACTION {
  BACKWARD = 'BACKWARD',
  FORWARD = 'FORWARD',
}

export type PubSubMessage = {
  type: PUBSUB_COURSE_ACTION;
  data?: string;
};

export interface IGetScormPreviewLinkResDto {
  previewLinkURL: string;
}
