import _ from 'lodash';

import { ContractParam } from '../@types/contract';
import { ActionHandler, QueryFilter } from '../@types/global';
import ApiUrlHelper from '../helpers/ApiUrlHelper';
import { http } from '../helpers/http';
import { delay, isNullUndefined } from '../helpers/util';

class ContractService {
  public listContract(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CONTRACT_LIST' });
    return http.get(url, params).catch((error) => {
      throw error;
    });
  }

  public getContract({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'CONTRACT_DETAIL',
      options: {
        ...options,
        versionNumber: isNullUndefined(options.versionNumber) ? '' : options.versionNumber,
      },
    });
    return http.get(url);
  }

  public getVersionHistory({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CONTRACT_VERSION', options });
    return http.get(url);
  }

  public previewVersion({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'CONTRACT_PREVIEW_VERSION_URL',
      options: {
        ...options,
        versionNumber: isNullUndefined(options.versionNumber) ? '' : options.versionNumber,
      },
    });
    return http.getPreviewFIle(url, {});
  }

  public previewWithSections({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'CONTRACT_PREVIEW',
      options: {
        ...options,
        versionNumber: isNullUndefined(options.versionNumber) ? '' : options.versionNumber,
      },
    });
    return http.postPreviewFIle(url, payload);
  }

  public listContractType(params: QueryFilter): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CONTRACT_TYPE' });
    return http.get(url, params);
  }

  public createContract({ payload }: { payload: any }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CREATE_CONTRACT' });
    return http.post(url, payload);
  }

  public updateContract({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'UPDATE_CONTRACT', options });
    return http.patch(url, payload);
  }

  public deleteContract({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'UPDATE_CONTRACT', options });
    return http.del(url);
  }

  public publishContract({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CONTRACT_PUBLISH', options });
    return http.put(url, payload);
  }

  public retireContract({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CONTRACT_RETIRE', options });
    return http.put(url, payload);
  }

  public draftContract({ options }: { options: ContractParam }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'CONTRACT_DRAFT', options });
    return http.post(url, {});
  }

  public uploadContractDocument({
    options,
    payload,
  }: {
    options: ContractParam;
    payload: any;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({ endpoint: 'UPLOAD_CONTRACT_FILE', options });
    return http.postFile(url, payload);
  }

  public sectionContractHandler({
    id,
    options = {},
    queryOptions = {},
    payLoad = {},
    action,
  }: ActionHandler): Promise<any> {
    let url = '';
    switch (action) {
      case 'get':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_CONTRACT',
          options: { ...options },
          isMocked: false,
        });
        return http.get(url);

      case 'post':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_CONTRACT',
          options: { ...options },
          isMocked: false,
        });
        return http.post(`${url}/${id}`, {});

      case 'delete':
        url = ApiUrlHelper.constructEndpoint({
          endpoint: 'SECTION_CONTRACT',
          options: { ...options },
          isMocked: false,
        });
        return http.del(`${url}/${id}`);

      default:
        return delay(100);
    }
  }

  public contractSectionsItemsDrag({
    contractID,
    list,
  }: {
    contractID: number;
    list: Array<any>;
  }): Promise<any> {
    const url = ApiUrlHelper.constructEndpoint({
      endpoint: 'COURSE_SECTION_SORT',
      options: { id: contractID },
      isMocked: false,
    });
    return http.patch(
      url,
      _.map(list, (item) => item.id),
    );
  }
}

const instance = new ContractService();
Object.freeze(instance);

export default instance;
