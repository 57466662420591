import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import Loading from '../common/Loading';
import ProgressBar from '../common/pageProgress';
import Header from '../containers/Header';
import { RootDispatch } from '../store';

type IMainProps = {
  children: ReactNode;
  getCurrentUser: any;
};

const TemplateSidebar = ({ children, getCurrentUser }: IMainProps) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCurrentUser().then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading position={'center'} />;
  }
  return (
    <Fragment>
      <ProgressBar />
      <div className='h-screen bg-white overflow-hidden'>
        <Header template={'full'} />
        {children}
      </div>
    </Fragment>
  );
};

const mapPros = () => ({});

const mapDispatch = (dispatch: RootDispatch) => ({
  getCurrentUser: dispatch.users.loadCurrentUser,
});

export default connect(mapPros, mapDispatch)(TemplateSidebar);
